import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Button, Modal, ButtonGroup, Form, Alert } from 'react-bootstrap'
import api from '../../api'

const INIT_STATE = {
  modalVisible: false,
  feedback_text: '',
  feedback_type: 'Idea',
  email: '',
  activeButton: 0,
  error: '',
  processing: false,
  validEmail: true,
}

const Error = styled.div`
  color: red;
`

class Feedback extends Component {
  constructor(props) {
    super(props)
    this.state = INIT_STATE
  }

  onSend = () => {
    // send feedback
    this.setState({
      error: '',
      processing: true,
    })
    api.feedback
      .submit({
        feedback_type: this.state.feedback_type,
        feedback_text: this.state.feedback_text,
        email: this.state.email,
      })
      .then((data) => {
        this.setState(INIT_STATE)
      })
      .catch((error) => {
        let data = error.response
        let message = ''
        if (data.status && data.status !== 400) {
          message += 'Error ' + data.status + ': '
        }
        if (data.message) {
          for (let m in data.message) {
            if (data.message.hasOwnProperty(m)) {
              message += data.message[m]
            }
          }
        } else {
          message = 'Error: unable to connect to Carta'
        }
        this.setState({
          error: message.substr(0, 50) + (message.length > 50 ? '...' : ''),
          processing: false,
        })
      })
  }

  toggleModal = () => {
    this.setState({
      modalVisible: !this.state.modalVisible,
    })
  }

  setActive = (i, name) => {
    this.setState({
      activeButton: i,
      feedback_type: name,
    })
  }

  onChangeFeedbackText = (event) => {
    this.setState({
      feedback_text: event.target.value,
    })
  }

  onChangeEmail = (event) => {
    this.setState({
      email: event.target.value,
      error: '',
      validEmail: event.target.checkValidity(),
    })
  }

  onEmailBlur = (event) => {
    if (!event.target.checkValidity()) {
      this.setState({
        error: 'Enter a valid email address.',
        validEmail: false,
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <Button
          variant="danger"
          target="_blank"
          rel="noopener noreferrer"
          data-toggle="modal"
          data-target="#feedbackModal"
          style={{
            borderRadius: '4px 4px 0 0',
            position: 'fixed',
            bottom: '50px',
            transform: 'rotate(270deg)',
            right: '-27px',
            margin: 0,
            zIndex: 2000,
          }}
          onClick={this.toggleModal}
        >
          Feedback
        </Button>
        <Modal
          show={this.state.modalVisible}
          onHide={this.toggleModal}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Feedback</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="d-flex flex-column">
              <ButtonGroup aria-label="Feedback type">
                {['Idea', 'Issue', 'Question', 'Praise', 'Other'].map(
                  (name, i) => (
                    <Button
                      variant="danger"
                      className={this.state.activeButton === i ? 'active' : ''}
                      onClick={() => this.setActive(i, name)}
                      key={i}
                    >
                      {name}
                    </Button>
                  )
                )}
              </ButtonGroup>
            </div>
            <br />
            <Alert variant="primary">
              The Carta V2 team has been asked by the Stanford Administration to
              remove the grade distribution feature from the Carta platform. The
              V2 team is currently appealing this decision and re-requesting
              access to the data through the Faculty Senate.{' '}
            </Alert>
            <div className="d-flex flex-column">
              <textarea
                className="form-control"
                rows="8"
                onChange={this.onChangeFeedbackText}
                value={this.state.feedback_text}
              />
            </div>
            <br />
            <Form.Group controlId="formBasicEmail">
              <Form.Label>
                If you'd like to hear back from us, please leave your email
                below.
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="Email address (optional)"
                onChange={this.onChangeEmail}
                onBlur={this.onEmailBlur}
                value={this.state.email}
              />
            </Form.Group>
            <Error>{this.state.error}</Error>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="danger"
              onClick={this.onSend}
              disabled={
                this.state.feedback_text.length === 0 ||
                this.state.processing ||
                !this.state.validEmail
              }
            >
              {this.state.processing ? 'Sending' : 'Send'}
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    )
  }
}

Feedback.propTypes = {
  modalVisible: PropTypes.bool,
}

export default Feedback
