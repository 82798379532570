import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  BarChart,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  Cell,
} from 'recharts'
import { NAVBAR_BKGRND_COLOR } from '../assets/constant_colors'

class BarGraph extends Component {
  render() {
    const {
      barColor,
      layout,
      xType,
      yType,
      domainX,
      ticksX,
      domainY,
      ticksY,
      data,
      xDataKey,
      yDataKey,
      barKey,
      hLine,
      vLine,
      heightX,
      offsetXY,
      tickXStyle,
      widthY,
      offsetXX,
    } = this.props

    // TODO: parse inputted data

    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          layout={layout}
          margin={{ top: 10, right: 10, left: 10, bottom: 20 }}
        >
          <CartesianGrid horizontal={hLine} vertical={vLine} />
          <XAxis
            height={heightX}
            type={xType}
            dataKey={xDataKey}
            domain={domainX}
            interval={0}
            ticks={ticksX}
          />
          <YAxis
            width={widthY}
            type={yType}
            dataKey={yDataKey}
            domain={domainY}
            interval={0}
            ticks={ticksY}
          />
          <Tooltip cursor={false} />
          <Bar type="monotone" dataKey={barKey} barSize={30}>
            {data.map((entry, index) => (
              <Cell
                key={index}
                fill={
                  xDataKey === 'quarter' && yDataKey === 'hrs' && entry.current
                    ? NAVBAR_BKGRND_COLOR
                    : barColor
                }
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    )
  }
}

BarGraph.propTypes = {
  data: PropTypes.array,
  xType: PropTypes.string,
  yType: PropTypes.string,
  xDataKey: PropTypes.string,
  yDataKey: PropTypes.string,
  barColor: PropTypes.string,
  layout: PropTypes.string,
}

export default BarGraph
