const ABOUT_PROFILES = [
  {
    name: 'Jared Wasserman',
    occupation: 'Undergraduate Student',
    image: '/team_images/jaredwasserman.jpeg',
  },
  {
    name: 'Alix Cui',
    occupation: 'Undergraduate Student',
    image: '/team_images/alixcui.jpg',
  },
  // Not available
  {
    name: 'Jordan',
    occupation: 'Undergraduate Student',
    image: '',
  },
  {
    name: 'Kristina Inouye',
    occupation: 'Undergraduate Student',
    image: '/team_images/kristinainouye.jpg',
  },
  {
    name: 'Luis Ulloa',
    occupation: 'Undergraduate Student',
    image: '/team_images/luisulloa.jpg',
  },
  {
    name: 'Meg Reinstra',
    occupation: 'Undergraduate Student',
    image: '/team_images/megreinstra.jpeg',
  },
  {
    name: 'Oscar Dumlao',
    occupation: 'Undergraduate Student',
    image: '',
  },
  {
    name: 'Roy Nehoran',
    occupation: 'Undergraduate Student',
    image: '',
  },
  {
    name: 'Tassica Lim',
    occupation: 'Undergraduate Student',
    image: '/team_images/tlim98.jpg',
  },
  // Not available
  {
    name: 'Thomas',
    occupation: 'Undergraduate Student',
    image: '',
  },
]

export default ABOUT_PROFILES
