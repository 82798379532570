//For reviews card
export const REVIEWS_INTRO_LINE =
  'What would you like to say about this course to a student who is considering taking it in the future?'
export const REVIEWS_FILTER_PROMPT = 'Enter keywords to filter: '
export const CARD_TYPES = {
  GLANCE: 'AT_A_GLANCE',
  SEQUENCING: 'SEQUENCING',
  EVALUATIONS: 'EVALUATIONS',
  INTENSITY: 'INTENSITY',
  PERFORMANCE: 'PERFORMANCE',
  COURSE_REVIEWS: 'COURSE_REVIEWS',
  COURSES_LIST: 'COURSES_LIST',
  SEASONAL_COURSES: 'SEASONAL_COURSES',
  HTML: 'HTML',
}
export const ADVISING_URL =
  'https://undergrad.stanford.edu/advising/make-appointment'
