import React, { Component } from 'react'
import styled from 'styled-components'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import NavBar from '../NavBar/NavBar'
import MainPanel from '../Pages/MainPanel'
import WaitList from '../Pages/WaitList'
import Loading from '../Pages/Loading'
import api from '../../api'
import { connect } from 'react-redux'
import Cookies from 'universal-cookie'
import LandingPage from '../Pages/LandingPage/LandingPage'
import LogoutPage from '../Pages/LogoutPage/LogoutPage'
import ErrorPage from '../Pages/ErrorPage/ErrorPage'
import { fetchSunetId } from '../../assets/actions/sunetidActions'
import {
  fetchWaitlistCount,
  fetchWaitlistStatus,
} from '../../assets/actions/waitlistActions'
import { fetchPinnedCourses } from '../../assets/actions/pinningActions'
import {
  fetchCurrentYearTerms,
  fetchTerm,
} from '../../assets/actions/termActions'
import { fetchSearchIndex } from '../../assets/actions/searchActions'
import { fetchTimeCommitment } from '../../assets/actions/commitmentActions'

const AppWrapper = styled.div``

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      windowWidth: 0,
      windowHeight: 0,
      showSidePanel: true,
      SUNetID: '',
    }
    this.cookies = new Cookies()
    if (this.cookies.get('authToken')) {
      // There is an auth token
      api.user.get().then((data) => {
        // User is logged in
        this.setState({ SUNetID: data.sunet })
      })

      // Dispatch the props
      this.props.onFetchSunetid()
      this.props.onFetchwaitlistCount()
      this.props.onFetchwaitlistStatus()
      this.props.onFetchPinnedCourses()
      this.props.onFetchTerm()
      this.props.onFetchCurrentYearTerms()
      this.props.onFetchTimeCommitment()
      this.props.onFetchSearchIndex()
    }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this.removeSidePanel = this.removeSidePanel.bind(this)
    this.addSidePanel = this.addSidePanel.bind(this)
    this.logout = this.logout.bind(this)
  }

  removeSidePanel() {
    this.setState({
      showSidePanel: false,
    })
  }

  addSidePanel() {
    this.setState({
      showSidePanel: true,
    })
  }

  logout() {
    this.cookies.remove('authToken', { path: '/' })
    document.location.assign('/logout')
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    })
  }

  renderApp() {
    const { windowWidth, showSidePanel } = this.state
    const { sunetId, waitlist } = this.props

    // If there is no auth token (logged in user), render landing or logout if path is logout
    // or load 404 error page
    if (!this.cookies.get('authToken')) {
      return (
        <Router>
          <Switch>
            <Route exact path="/logout">
              <LogoutPage />
            </Route>
            <Route exact path="/">
              <LandingPage />
            </Route>
            <Route>
              <ErrorPage isLoggedIn={false} />
            </Route>
          </Switch>
        </Router>
      )
    }

    if (sunetId.loading || waitlist.loading) {
      return <Loading />
    }

    if (!sunetId.SUNetID.has_beta_access) {
      return <WaitList />
    }

    return (
      <AppWrapper>
        <NavBar
          loginID={sunetId.SUNetID.sunet}
          windowWidth={windowWidth}
          logout={this.logout}
        />
        <MainPanel
          windowWidth={windowWidth}
          showSidePanel={showSidePanel}
          removeSidePanel={this.removeSidePanel}
          addSidePanel={this.addSidePanel}
        />
      </AppWrapper>
    )
  }

  render() {
    return <Router>{this.renderApp()}</Router>
  }
}

function mapStateToProps(state) {
  return {
    sunetId: state.sunetId,
    waitlist: state.waitlist,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchSunetid: () => dispatch(fetchSunetId()),
    onFetchwaitlistCount: () => dispatch(fetchWaitlistCount()),
    onFetchwaitlistStatus: () => dispatch(fetchWaitlistStatus()),
    onFetchPinnedCourses: () => dispatch(fetchPinnedCourses()),
    onFetchTerm: () => dispatch(fetchTerm()),
    onFetchCurrentYearTerms: () => dispatch(fetchCurrentYearTerms()),
    onFetchTimeCommitment: () => dispatch(fetchTimeCommitment()),
    onFetchSearchIndex: () => dispatch(fetchSearchIndex()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
