import {
  FETCH_WAITLISTCOUNT_BEGIN,
  FETCH_WAITLISTCOUNT_SUCCESS,
  FETCH_WAITLISTCOUNT_FAILURE,
  FETCH_WAITLIST_STATUS_BEGIN,
  FETCH_WAITLIST_STATUS_SUCCESS,
  FETCH_WAITLIST_STATUS_FAILURE,
  JOIN_WAITLIST_BEGIN,
  JOIN_WAITLIST_SUCCESS,
  JOIN_WAITLIST_FAILURE,
} from '../actions/waitlistActions'

const initialState = {
  waitlist: {},
  joinedSuccess: false,
  joining: false,
  loading: false,
  error: null,
  status: {},
}

export default function waitlistReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_WAITLISTCOUNT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case FETCH_WAITLISTCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        waitlist: action.payload.data,
      }

    case FETCH_WAITLISTCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        waitlist: {},
      }

    case FETCH_WAITLIST_STATUS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case FETCH_WAITLIST_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload.data,
      }

    case FETCH_WAITLIST_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        status: {},
      }

    case JOIN_WAITLIST_BEGIN:
      return {
        ...state,
        joining: true,
        joinedSuccess: false,
      }

    case JOIN_WAITLIST_SUCCESS:
      return {
        ...state,
        joining: false,
        joinedSuccess: true,
      }

    case JOIN_WAITLIST_FAILURE:
      return {
        ...state,
        joining: false,
        error: action.payload.error,
        joinedSuccess: false,
      }

    default:
      return state
  }
}
