import api from '../../api'

export const FETCH_GRAPHS_BEGIN = 'FETCH_GRAPHS_BEGIN'
export const FETCH_GRAPHS_SUCCESS = 'FETCH_GRAPHS_SUCCESS'
export const FETCH_GRAPHS_FAILURE = 'FETCH_GRAPHS_FAILURE'

export const fetchGraphBegin = () => ({
  type: FETCH_GRAPHS_BEGIN,
})

export const fetchGraphSuccess = data => ({
  type: FETCH_GRAPHS_SUCCESS,
  payload: { data },
})

export const fetchGraphFailure = error => ({
  type: FETCH_GRAPHS_FAILURE,
  payload: { error },
})

export function fetchGraph(courseId) {
  return dispatch => {
    dispatch(fetchGraphBegin())

    api.course.getGraphs(courseId).then(graphData => {
      dispatch(fetchGraphSuccess(graphData))
    })
  }
}
