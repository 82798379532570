import React, { Component } from 'react'
import styled from 'styled-components'
import { SIDE_PANEL_WIDTH, THRESHOLD } from '../../assets/constant_sizes.js'
import { NAVBAR_BKGRND_COLOR } from '../../assets/constant_colors'
import { Container, Col } from 'react-bootstrap'

const FAQWrapper = styled.div`
  margin-left: ${(props) =>
    props.threshold > THRESHOLD ? SIDE_PANEL_WIDTH : '300px'};
  padding: 40px 20px 20px 40px;
  width: auto;
  background-color: #f9f6ef;
  min-height: calc(100vh - 60px);
  border-left: 3px solid ${NAVBAR_BKGRND_COLOR};
`

class FAQPage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <FAQWrapper>
        <Container>
          <Col>
            <h2>Frequently Asked Questions</h2>
            <h5 style={{ fontStyle: 'italic', paddingTop: '10px' }}>
              {' '}
              What will change for me?
            </h5>
            <p>
              Your experience as a user will change very little. You can still
              search courses, view evaluations and statistics, and access all
              the information Carta makes available. You should find that V2 has
              faster loading times than V1. You will definitely find that we are
              now Cardinal red!
              <br />
              One caveat: You will need to transfer any courses you have pinned
              in V1 to V2 on your own.
            </p>
            <h5 style={{ fontStyle: 'italic' }}>
              Will I still be able to use V1?
            </h5>
            <p>
              Yes, but we hope that you will use V2 whenever possible so that we
              can test and improve our platform. If you are having trouble
              parting with an old friend (we understand!), find V1 at
              carta-legacy.stanford.edu.
              <br />
              But: Practice your goodbyes. V1 will shut down permanently in late
              June (date to be specified).
            </p>
            <h5 style={{ fontStyle: 'italic' }}>
              Where will the url carta-beta.stanford.edu go?
            </h5>
            <p>
              carta.stanford.edu and carta-beta.stanford.edu will both direct to
              V2. carta-legacy.stanford.edu will direct to V1 until it shuts
              down permanently in the summer.
            </p>
            <h5 style={{ fontStyle: 'italic' }}>
              I don’t see my pinned classes on V2!
            </h5>
            <p>
              Sorry about that—pins from V1 will not be transferred to V2. If
              you want to keep your pins, you will have to transfer them over
              manually. This also means that any changes you make on V1 will not
              be reflected on V2.
            </p>
            <h5 style={{ fontStyle: 'italic' }}>V2 is missing features?</h5>
            <p>
              While we’re all grown up, we are not on our own. Stanford
              administrators provide all the data vital to making Carta go. The
              student team is negotiating our new arrangement with the
              university, and we are still working to complete data agreements
              to the support following features:
            </p>
            <ul>
              <li>sequencing information</li>
              <li>enrollment outcomes</li>
              <li>courses commonly taken before, concurrently, and after</li>
            </ul>
            <p>
              If V2 is missing any data that you found helpful, please let us
              know using the feedback form!
            </p>
            <h5 style={{ fontStyle: 'italic' }}>
              When will I lose complete access to V1?
            </h5>
            <p>
              We are planning to fully sunset V1 early 2022. We will announce
              the exact date once it is finalized.
            </p>
            <br />
            <h6 style={{ fontStyle: 'italic' }}>
              More questions? Please ask using our feedback form or email
              stanfordcarta@gmail.com.
            </h6>
          </Col>
        </Container>
      </FAQWrapper>
    )
  }
}

export default FAQPage
