import React from 'react'
import styled from 'styled-components'
// import LandingLogo from '../LandingNavbar/LandingLogo/LandingLogo'
// import StanfordLogo from '../../../../assets/stanford_logo.png'
// import CartaLogo from '../../../../assets/carta_logo_white.png'
import Logo from '../../../../assets/logo.svg'

const FooterWrapper = styled.div`
  background-color: #8c1515;
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  bottom: 0;
`
// position: absolute;
// bottom: 0;
// left: 0;
// width: 100%

const FooterRow = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-left: 20px;
  margin-bottom: 60px;

  a {
    margin-right: 32px;
    color: #ffffff !important;
    cursor: pointer;
    letter-spacing: 0.02em;
    font-weight: 600;
    font-variant: small-caps;
    margin-bottom: 10px;
  }
`

export default function Footer() {
  let d = new Date()
  return (
    <FooterWrapper>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        {/* <LandingLogo stanford={StanfordLogo} carta={CartaLogo} /> */}
        <img src={Logo} alt="Carta logo" />
        <FooterRow>
          <a
            href="http://pathwayslab.stanford.edu/"
            target="_blank"
            rel="noopener noreferrer"
          >
            PATHWAYSLAB
          </a>
          <a href="/about">ABOUT US</a>
          <a href="/apply">JOIN OUR TEAM</a>
          <a href="mailto:carta-project@lists.stanford.edu">CONTACT</a>
        </FooterRow>
      </div>
      <div>
        <p style={{ fontSize: '18px', color: '#EF9A9A' }}>
          © {d.getFullYear()} Stanford University, Stanford, California 94305
        </p>
      </div>
    </FooterWrapper>
  )
}
