import React, { Component } from 'react'
import styled from 'styled-components'
import { SIDE_PANEL_WIDTH, THRESHOLD } from '../../assets/constant_sizes.js'
import { NAVBAR_BKGRND_COLOR } from '../../assets/constant_colors'
import { Container, Row, Col } from 'react-bootstrap'
import GeneralCard from '../Cards/GeneralCard.js'
import { CARD_TYPES } from '../../assets/constant_strings'

const AboutWrapper = styled.div`
  margin-left: ${props =>
    props.threshold > THRESHOLD ? SIDE_PANEL_WIDTH : '0px'};
  padding: 40px 20px 20px 40px;
  width: auto;
  background-color: #f9f6ef;
  min-height: calc(100vh - 60px);
  border-left: 3px solid ${NAVBAR_BKGRND_COLOR};

  .accordion .card:only-of-type {
    border-radius: 0.25rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
  }

  & .col,
  .col-12 {
    margin-bottom: 20px;
  }
`

class DataDefinitionPage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { threshold } = this.props

    return (
      <AboutWrapper threshold={threshold}>
        <Container>
          <Row>
            <Col>
              <GeneralCard
                cardType={CARD_TYPES.HTML}
                cardTitle={'Carta Data Definitions and Sources'}
              >
                <p>
                  The data that underlie Carta course pages are from official
                  university sources. They are not directly self-reported to
                  Carta by its users.
                </p>

                <p>
                  This page provides information about Carta’s data exhibits and
                  sources. Additional information about Carta’s research program
                  is available at{' '}
                  <a target="blank" href="https://cartalab.stanford.edu">
                    cartalab.stanford.edu
                  </a>
                  . Information features that you find on the Carta page are
                  listed below.
                </p>
              </GeneralCard>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6}>
              <GeneralCard cardType={CARD_TYPES.HTML} cardTitle={'At a Glance'}>
                This section contains logistical information based on
                ExploreCourses.
              </GeneralCard>
            </Col>
            <Col xs={12} lg={6}>
              <GeneralCard cardType={CARD_TYPES.HTML} cardTitle={'Evaluations'}>
                These charts display students' quarterly average rating of
                particular professors for the selected class (the rating itself
                is computed by averaging among responses to the two questions:
                "Overall, how would you describe the quality of the instruction
                in this course?" and "How much did you learn from this
                course?"). The data shown represent the past two years of
                student evaluations. These data come from Stanford’s official
                student course evaluation program.
              </GeneralCard>
            </Col>
          </Row>
          <Row>
            <Col>
              <GeneralCard cardType={CARD_TYPES.HTML} cardTitle={'Sequencing'}>
                We're working on bringing the sequencing card back as soon as
                possible!
              </GeneralCard>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6}>
              <GeneralCard cardType={CARD_TYPES.HTML} cardTitle={'Intensity'}>
                <p>
                  The upper chart displays an aggregated distribution of the
                  reported amount of time per week enrolled students spent
                  working on the selected class. These data come from Stanford's
                  official student course evaluation program.
                </p>
                <p>
                  The lower chart displays the outcomes of students who were
                  enrolled in the selected course. Either a student completed
                  the course, dropped the course (usually before the add/drop
                  deadline), or withdrew from the course (usually after add/drop
                  deadline). These data come from the Stanford Registrar.
                </p>
              </GeneralCard>
            </Col>
            <Col xs={12} lg={6}>
              <GeneralCard cardType={CARD_TYPES.HTML} cardTitle={'Performance'}>
                <p>
                  <strong>
                    Note: In light of recent changes to grading policy related
                    to the COVID-19 pandemic, Stanford leadership has asked the
                    Carta team to disable our Performance feature.
                  </strong>
                </p>
                <p>
                  The upper chart displays an aggregated distribution of the
                  letter grades conferred in the selected course. The chart
                  reports data from the past three years. These data come from
                  the Stanford Registrar.
                </p>
                <p>
                  Displays of grades for PWR courses aggregated earned grades in
                  all PWR courses during the last three years.
                </p>
                <p>
                  In order to protect student privacy, we do not display these
                  charts when there are fewer than fifteen grades to plot.
                </p>
                <p>
                  The lower chart displays the percentage of students who took
                  the course for credit (CR/S) or no credit (NC). These data are
                  from the Stanford Registrar. In order to protect student
                  privacy, we do not display these charts when there are fewer
                  than fifteen grades to plot.
                </p>
              </GeneralCard>
            </Col>
          </Row>
          <Row>
            <Col>
              <GeneralCard
                cardType={CARD_TYPES.HTML}
                cardTitle={'Course Reviews'}
              >
                This panel displays responses to the question, "What would you
                like to say about this course to a student who is considering
                taking it in the future?" You can filter course reviews by
                entering keywords, such as a professor's name, into the keyword
                box. These data come from Stanford’s official student course
                evaluation program.
              </GeneralCard>
            </Col>
          </Row>
        </Container>
      </AboutWrapper>
    )
  }
}

export default DataDefinitionPage
