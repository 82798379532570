import React, { Component } from 'react'
import styled from 'styled-components'
import { SIDE_PANEL_WIDTH, THRESHOLD } from '../../../assets/constant_sizes.js'
import { NAVBAR_BKGRND_COLOR } from '../../../assets/constant_colors'
import { Container, Row, Col } from 'react-bootstrap'
import GeneralCard from '../../Cards/GeneralCard.js'
import { CARD_TYPES } from '../../../assets/constant_strings'
import { Link } from 'react-router-dom'
import ABOUT_PROFILES from '../../../assets/about_profiles'
import AboutProfile from './AboutProfile.js'

const AboutWrapper = styled.div`
  margin-left: ${(props) =>
    props.threshold > THRESHOLD ? SIDE_PANEL_WIDTH : '0px'};
  padding: 40px 20px 20px 40px;
  width: auto;
  background-color: #f9f6ef;
  min-height: calc(100vh - 60px);
  border-left: 3px solid ${NAVBAR_BKGRND_COLOR};

  .accordion .card:only-of-type {
    border-radius: 0.25rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
  }

  & .col-12 {
    margin-bottom: 20px;
  }
`

class AboutPage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const profiles = ABOUT_PROFILES.map((elem, id) => {
      return (
        <AboutProfile
          key={id}
          name={elem.name}
          image={elem.image}
          occupation={elem.occupation}
        />
      )
    })

    const { threshold } = this.props

    return (
      <AboutWrapper threshold={threshold}>
        <Container>
          <Row>
            <Col xs={12} xl={6}>
              <GeneralCard
                cardType={CARD_TYPES.HTML}
                cardTitle={'Carta is a Research Project'}
              >
                <p>
                  We are building Carta to learn about college pathways. The
                  information we gather from your engagement with Carta enables
                  our research team to understand how students consider, choose,
                  and sequence courses, and how these actions are related to
                  other aspects of students' lives before, during, and after
                  their time at Stanford. The knowledge we gain through Carta is
                  available to inform students' decisions, teaching and
                  administration at Stanford, and the accumulation of scientific
                  knowledge about choice and decision generally.
                </p>

                <p>
                  Your contributions: By using Carta, you are volunteering for
                  research. As you log in to Carta, a hash identifier is
                  assigned to track your usage. We track, collect and aggregate
                  information regarding your interactions with Carta including,
                  among other things, the pages of the site you visit, the order
                  and timing of your activities on Carta, search terms, pinning
                  and course planning activity, and the hyperlinks you "click."
                  We also may ask you to answer some simple survey questions. In
                  the interest of research you may be exposed to some variation
                  in the presentation of information available to you on the
                  Carta portal. You may stop participating in this research at
                  any time by no longer using Carta. Neither your academic
                  progress nor program eligibility is contingent on your
                  participation or non-participation in Carta.
                </p>

                <p>
                  We study the data we gather through Carta by linking it with
                  other information about Stanford students collected through
                  other University systems. The data will be coded so that your
                  name will be removed and replaced with a unique identifying
                  code. We will neither identify you by name in any discussions
                  or publications, nor describe data in such a way that you can
                  be identified.
                </p>

                <p>
                  While we cannot guarantee that you will receive any benefits
                  from using Carta, the potential benefits include giving you
                  more information about course sequences and college pathways.
                  There is minimal risk associated with participating in this
                  research.
                </p>

                <p>
                  Our commitments: We use our research findings to continually
                  improve Carta and contribute to public discussion of
                  educational improvement at Stanford and worldwide. In doing
                  our work we consider and analyze data and report research
                  findings at the aggregate level only.
                </p>

                <p>
                  You can read this message again any time from a link at the
                  bottom of the landing page. If you have questions about this
                  research please contact Carta team member Mitchell Stevens at
                  carta-project@lists.stanford.edu. If you have concerns about
                  this research and would like to speak with someone independent
                  of the research team, you may contact the Stanford IRB at
                  irb2-manager@lists.stanford.edu.
                </p>
              </GeneralCard>
            </Col>
            <Col xs={12} xl={6}>
              <GeneralCard
                cardType={CARD_TYPES.HTML}
                cardTitle={'Carta Data Definitions And Sources'}
              >
                Click <Link to="data_definition">here</Link> to learn more about
                the data we show.
              </GeneralCard>
              <div style={{ height: '30px' }}></div>
              <GeneralCard cardType={CARD_TYPES.HTML} cardTitle={'Carta Team'}>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                  }}
                >
                  {profiles}
                </div>
              </GeneralCard>
            </Col>
          </Row>
        </Container>
      </AboutWrapper>
    )
  }
}

export default AboutPage
