import api from '../../api'

export const FETCH_TERM_BEGIN = 'FETCH_TERM_BEGIN'
export const FETCH_TERM_SUCCESS = 'FETCH_TERM_SUCCESS'
export const FETCH_TERM_FAILURE = 'FETCH_TERM_FAILURE'

export const FETCH_CURRENT_YEAR_TERMS_BEGIN = 'FETCH_CURRENT_YEAR_TERMS_BEGIN'
export const FETCH_CURRENT_YEAR_TERMS_SUCCESS =
  'FETCH_CURRENT_YEAR_TERMS_SUCCESS'
export const FETCH_CURRENT_YEAR_TERMS_FAILURE =
  'FETCH_CURRENT_YEAR_TERMS_FAILURE'

export const fetchTermBegin = () => ({
  type: FETCH_TERM_BEGIN,
})

export const fetchTermSuccess = data => ({
  type: FETCH_TERM_SUCCESS,
  payload: { data },
})

export const fetchTermFailure = error => ({
  type: FETCH_TERM_FAILURE,
  payload: { error },
})

export const fetchCurrentYearTermsBegin = () => ({
  type: FETCH_CURRENT_YEAR_TERMS_BEGIN,
})

export const fetchCurrentYearTermsSuccess = data => ({
  type: FETCH_CURRENT_YEAR_TERMS_SUCCESS,
  payload: { data },
})

export const fetchCurrentYearTermsFailure = error => ({
  type: FETCH_CURRENT_YEAR_TERMS_FAILURE,
  payload: { error },
})

export function fetchTerm(id) {
  return dispatch => {
    dispatch(fetchTermBegin())

    api.term.getByShortId(id).then(term => {
      dispatch(fetchTermSuccess(term))
    })
  }
}

export function fetchCurrentYearTerms() {
  return dispatch => {
    dispatch(fetchCurrentYearTermsBegin())
    api.term
      .getDefault()
      .then(current => {
        let endYear = current.end_year
        let filteredTerms = api.term.getAll().then(terms => {
          return terms.filter(term => term.end_year === endYear)
        })
        return filteredTerms
      })
      .then(terms => {
        let currentYearTerms = new Map(
          terms.map(term => {
            return [term.season.toLowerCase(), term]
          })
        )
        dispatch(fetchCurrentYearTermsSuccess(currentYearTerms))
      })
  }
}
