import React, { Component } from 'react'
import styled from 'styled-components'
import logo from '../../assets/carta_logo_red.png'
import flame from '../../assets/flame.svg'
import screenshot from '../../assets/screenshot.png'
import { Button } from 'react-bootstrap'
import { joinWaitlist } from '../../assets/actions/waitlistActions'
import { connect } from 'react-redux'

const WaitListWrapper = styled.div`
  padding: 60px 50px;

  .contentContainer {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }

  .header1 {
    margin-top: 50px;

    font-family: Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 38.832px;
    line-height: 29px;
    /* identical to box height, or 75% */

    color: #000000;
  }

  .description {
    margin-top: 16px;
    max-width: 530px;

    font-family: Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 39px;
    /* or 162% */

    color: #000000;
  }

  .footer {
    margin-top: 50px;
    max-width: 610px;

    font-family: Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 39px;
    /* or 162% */

    color: #888888;
  }

  .joinedSuccess {
    margin-top: 33px;
    height: 60px;

    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    /* identical to box height, or 129% */

    color: #8c1515;
  }

  .waiting {
    margin-left: 7px;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    /* identical to box height, or 129% */

    display: flex;
    align-items: center;

    color: #222222;
  }

  .waitingContainer {
    margin-top: 10px;
    display: flex;
  }

  a {
    color: #8c1515;
  }

  a:hover {
    color: #6b0f0f;
  }

  .joinButton {
    margin-top: 33px;
    width: 314px;
    height: 60px;

    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 26px;
  }
`

const Logo = styled.img`
  width: 333px;
  height: 91px;
`

const Screenshot = styled.img`
  position: absolute;
  width: 958px;
  height: 531px;
  left: 900px;
  top: 50px;

  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.5);
`

class WaitList extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  joinWaitlistClick(e) {
    this.props.joinWaitlist()
  }

  render() {
    const { onWaitlist, count, joining, joinedSuccess } = this.props

    return (
      <WaitListWrapper>
        <div className="contentContainer">
          <Logo src={logo} alt="Carta logo" />
          <p className="header1">The new Carta is coming soon.</p>
          <div className="description">
            We're launching a new beta version of Carta. Enjoy the fastest
            course browsing experience we’ve ever offered.
          </div>
          {onWaitlist || joinedSuccess ? (
            <div className="joinedSuccess">Thanks for joining.</div>
          ) : (
            <Button
              className="joinButton"
              variant="danger"
              disabled={joining}
              onClick={e => this.joinWaitlistClick(e)}
            >
              Join the waitlist
            </Button>
          )}
          <div className="waitingContainer">
            <img src={flame} alt="" />
            <div className="waiting">{count} of your peers and counting</div>
          </div>
          <div className="footer">
            Carta is a research project in the Computer Science department.
            Learn more about our lab{' '}
            <a target="blank" href="https://cartalab.stanford.edu/about">
              here
            </a>
            .
          </div>
        </div>
        <Screenshot src={screenshot} alt="" />
      </WaitListWrapper>
    )
  }
}

function mapStateToProps(state) {
  return {
    onWaitlist: state.waitlist.status.is_on_waitlist,
    count: state.waitlist.waitlist.waitlist_count,
    joining: state.waitlist.joining,
    joinedSuccess: state.waitlist.joinedSuccess,
  }
}

const mapDispatchToProps = {
  joinWaitlist,
}

export default connect(mapStateToProps, mapDispatchToProps)(WaitList)
