import React, { Component } from 'react'
import styled from 'styled-components'
import Collaborate from './Collaborate/Collaborate'
import Diagrams from './Diagrams/Diagrams'
import Hero from './Hero/Hero'
import LandingNavbar from './LandingNavbar/LandingNavbar'
import LandingTools from './LandingTools/LandingTools'
import Reviews from './Reviews/Reviews'
import Footer from './Footer/Footer'

const LandingPageWrapper = styled.div`
  img {
    max-width: 100%;
  }
`

/**
 * LandingPage Formal
 */
export default class LandingPage extends Component {
  render() {
    return (
      <LandingPageWrapper>
        <LandingNavbar />
        <Hero />
        <LandingTools />
        <Reviews />
        <Diagrams />
        <Collaborate />
        <Footer />
      </LandingPageWrapper>
    )
  }
}
