import React, { Component } from 'react'
import styled from 'styled-components'
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import GeneralCard from '../Cards/GeneralCard.js'
import { Z_INDEX } from '../../assets/constant_sizes'
import { CARD_TYPES } from '../../assets/constant_strings'
import { SIDE_PANEL_WIDTH, THRESHOLD } from '../../assets/constant_sizes.js'
import { connect } from 'react-redux'
import { pinCourse, unpinCourse } from '../../assets/actions/pinningActions'
import { fetchCourse } from '../../assets/actions/courseActions'
import { createPinOptions } from '../../Tools/utils.js'
import BackdropSpinner from '../Miscellaneous/BackdropSpinner'
import Loading from './Loading'

const CourseInfoWrapper = styled.div`
  margin-left: ${(props) =>
    props.threshold > THRESHOLD ? SIDE_PANEL_WIDTH : '0px'};

  & .btn.btn-primary {
    margin-left: 10px;
  }

  & .col-lg-6.col-md-12.col-xs-12 {
    padding-left: 0;
  }

  & .col-lg-12.col-md-12.col-xs-12 {
    padding-left: 0;
  }

  #course_title {
    background: rgba(255, 255, 255, 0.92);
    width: 100%;
    margin: 15px;
  }

  @media only screen and (min-width: 768px) {
    #course_title {
      position: sticky;
      z-index: ${Z_INDEX};
      top: 60px;
    }
  }

  #performance,
  #intensity,
  #sequencing,
  #evaluations {
    display: block;
    position: relative;
    top: -200px;
    visibility: hidden;
  }

  h1 {
    margin-bottom: 20px;
  }

  .boldMe {
    font-weight: bold;
  }

  & .row {
    margin: 0px;
  }

  && .container {
    margin: 0px;
    height: 100%;
    max-width: none;
  }

  .accordion .card:only-of-type {
    border-radius: 0.25rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
  }
`

const CardWrapper = styled.div`
  padding-top: 5px;
`

const CourseTitleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

class CourseInfoPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      courseTitle: '',
      currCourseCode: -1,
      data: {},
      isPinned: {},
    }

    this.pinCourse = this.pinCourse.bind(this)
  }

  componentDidMount() {
    this.props.fetchCourse(formatCourseCode(this.props.match.params.courseCode))
    this.props.addSidePanel()
  }

  componentDidUpdate() {
    const { course } = this.props
    const routedCourse = formatCourseCode(this.props.match.params.courseCode)
    if (Object.keys(course).length === 0) {
      return
    }
    if (course.course.course_codes.every((x) => x !== routedCourse)) {
      this.props.fetchCourse(
        formatCourseCode(this.props.match.params.courseCode)
      )
    }
  }

  pinCourseClick(term) {
    const { course } = this.props
    const data = {
      course_id: course.course.id,
      term_id: term.id,
      // add course unit as a prop
      type: 'pin',
    }

    this.props.pinCourse(data)

    this.setState((state) => ({
      ...state.isPinned,
      [state.isPinned[[term.id]]]: true,
    }))
  }

  pinCourse() {
    const { term } = this.props
    this.pinCourseClick(term)
  }

  unpinCourseClick(term) {
    const { pinnedCourses, course } = this.props
    let course_idx = -1
    for (let i = 0; i < pinnedCourses.length; ++i) {
      if (
        pinnedCourses[i].course.id === course.course.id &&
        pinnedCourses[i].term.id === term.id
      ) {
        course_idx = i
        break
      }
    }

    if (course_idx !== -1) {
      let term_id = pinnedCourses[course_idx].term.id
      this.props.unpinCourse(course_idx)

      this.setState((state) => ({
        ...state.isPinned,
        [state.isPinned[[term_id]]]: false,
      }))
    }
  }

  unpinCourse() {
    const { term } = this.props
    this.unpinCourseClick(term)
  }

  checkPin() {
    const { isPinned } = this.state
    const { pinnedCourses, course } = this.props

    if (course !== undefined) {
      pinnedCourses.map((pinnedCourse) => {
        let term_id = pinnedCourse.term.id
        if (course.id === pinnedCourse.course.id && !isPinned[term_id]) {
          this.setState((state) => ({
            ...state.isPinned,
            [state.isPinned[term_id]]: true,
          }))
        }
        return null
      })
    }
  }

  renderCourseTitle() {
    const { pinnedCourses, course, term, currentYearTerms } = this.props
    if (!course || course === undefined || Object.keys(course).length === 0) {
      return null
    }

    let availableSeasons = course.course.seasons_offered
    return (
      <CourseTitleWrapper>
        <h1 style={{ marginBottom: 0, marginRight: '20px' }}>
          {course.course_title}{' '}
        </h1>
        {createPinOptions(
          this,
          availableSeasons,
          currentYearTerms,
          pinnedCourses,
          term,
          course.course
        )}
      </CourseTitleWrapper>
    )
  }

  render() {
    //TODO: when course title is too long, goes off the right of the page
    const { courseTitle, isPinned, course, ...props } = this.state
    const url = 'https://explorecourses.stanford.edu/'
    const fullCourseInfo = this.state.data

    const { threshold } = this.props
    if (this.props.loading)
      return (
        <CourseInfoWrapper
          threshold={threshold}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          {/* <BackdropSpinner /> */}
          <div>
            <Loading />
          </div>
        </CourseInfoWrapper>
      )
    else
      return (
        <CourseInfoWrapper threshold={threshold}>
          <Container>
            <Row id="course_title">{this.renderCourseTitle()}</Row>
            <CardWrapper>
              <Row>
                <Col xs={12} md={12} lg={6} style={{ paddingBottom: '15px' }}>
                  <GeneralCard
                    showCollapsibleAbility={true}
                    cardType={CARD_TYPES.GLANCE}
                    cardTitle="AT A GLANCE"
                    course_url={url}
                  />
                </Col>
                <Col xs={12} md={12} lg={6}>
                  <GeneralCard
                    showCollapsibleAbility={true}
                    cardType={CARD_TYPES.EVALUATIONS}
                    cardTitle="EVALUATIONS"
                  />
                </Col>
              </Row>

              <Row style={{ padding: '15px' }}>Performance Coming Soon!</Row>

              <Row>
                <Col xs={12} md={12} lg={6}>
                  <p id="intensity"></p>
                  <GeneralCard
                    showCollapsibleAbility={true}
                    cardType={CARD_TYPES.INTENSITY}
                    cardTitle="INTENSITY"
                  />
                </Col>
                <Col xs={12} md={12} lg={6}>
                  <p id="sequencing"></p>
                  <GeneralCard
                    showCollapsibleAbility={true}
                    cardType={CARD_TYPES.SEQUENCING}
                    cardTitle="SEQUENCING"
                  />
                </Col>
              </Row>

              <Row style={{ paddingTop: '15px' }}>
                <Col xs={12} md={12} lg={12}>
                  {this.props.loadingReviews ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Spinner
                        animation="border"
                        role="status"
                        variant="danger"
                      >
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                      <div style={{ marginLeft: '10px', fontSize: '20px' }}>
                        Loading Reviews...
                      </div>
                    </div>
                  ) : (
                    <GeneralCard
                      showCollapsibleAbility={true}
                      cardType={CARD_TYPES.COURSE_REVIEWS}
                      cardTitle="COURSE REVIEWS"
                      {...props}
                      fullCourseInfo={fullCourseInfo}
                    />
                  )}
                </Col>
              </Row>
            </CardWrapper>
            {/* Uncomment This Section Once the Data Page has been built. */}
            {/* <Row>
            <Col>
              <div>
                Click <a href="google.com">here</a> to learn more about the data
                we show.
              </div>
            </Col>
          </Row> */}
          </Container>
        </CourseInfoWrapper>
      )
  }
}

function mapStateToProps(state) {
  return {
    pinnedCourses: state.pinnedCourses.pinnedCourses,
    course: state.course.currentCourse,
    loading: state.course.loading,
    loadingReviews: state.course.loadingReviews,
    term: state.term.term,
    currentYearTerms: state.term.currentYearTerms,
  }
}

function formatCourseCode(courseCode) {
  if (/\s/.test(courseCode)) {
    return courseCode
  }
  const department = courseCode.match(/^[^0-9]+/)[0]
  const number = courseCode.slice(department.length)
  return `${department} ${number}`
}

const mapDispatchToProps = {
  pinCourse,
  fetchCourse,
  unpinCourse,
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseInfoPage)
