import React, { Component } from 'react'
import { Tabs, Tab, Table } from 'react-bootstrap'
import styled from 'styled-components'
import { parseTimeToHHMM } from '../Tools/utils'

const ScheduleWrapper = styled.div`
  & .tab-content {
    padding: 10px;
    padding-bottom: 50px;
    border: 1px solid transparent;
    border-color: #fff #dee2e6 #dee2e6 #dee2e6;
  }
  & .time {
    font-size: 20px;
    margin-bottom: 10px;
  }
  & .lecture {
    font-size: 18px;
  }
  & .info {
    padding-top: 10px;
  }
  & .info-all-content {
    padding: 5px 0px 5px 10px;
  }
  & .info-content {
    font-size: 14px;
    margin-bottom: 8px;
  }
  & .schedule-pageinate {
    float: right;
  }
  & .schedule-pageinate-button-active {
    padding: 0.5em;
    border: 1px solid #dc3545;
  }
  & .schedule-pageinate-button {
    background: none;
    border: none;
    padding: 0.5em;
  }
  & .schedule-pageinate-button:hover {
    background-color: #dc3545;
  }
`

class GlanceSched extends Component {
  constructor(props) {
    super(props)

    // need to set page and section trackers for each term
    this.state = {
      // currentSectionStart is the first record being displayed in the sections table
      currentSectionStart: { Autumn: 0, Winter: 0, Spring: 0, Summer: 0 },
      currentPage: { Autumn: 1, Winter: 1, Spring: 1, Summer: 1 },
      // sectionDisplayValue is the number of discussionSection records being displayed at a time
      sectionDisplayValue: '10',
    }
  }

  // updates the number of discussion section records the user wants
  // to display at one time in the sections table
  handleSectionDisplayValue(e, season) {
    // reset section start
    let newSectionStart = { ...this.state.currentSectionStart }
    newSectionStart[season] = 0
    // reset page start
    let newPage = { ...this.state.currentPage }
    newPage[season] = 1
    // set page, section and section display state
    this.setState({
      currentSectionStart: newSectionStart,
      currentPage: newPage,
      sectionDisplayValue: e.target.value,
    })
  }

  // takes the user to the previous page of discussion section records
  handlePreviousPage(e, season) {
    e.preventDefault()
    // update section start
    let newSectionStartValue =
      parseInt(this.state.currentSectionStart[season]) -
      parseInt(this.state.sectionDisplayValue)
    let newSectionStart = { ...this.state.currentSectionStart }
    newSectionStart[season] = newSectionStartValue
    // update page
    let currentPageValue = parseInt(this.state.currentPage[season])
    let previousPage = { ...this.state.currentPage }
    previousPage[season] = currentPageValue - 1
    // set page and section state
    this.setState({
      currentSectionStart: newSectionStart,
      currentPage: previousPage,
    })
  }

  // takes the user to the next page of discussion section records
  handleNextPage(e, season) {
    e.preventDefault()
    // update section start
    let newSectionStartValue =
      parseInt(this.state.currentSectionStart[season]) +
      parseInt(this.state.sectionDisplayValue)
    let newSectionStart = { ...this.state.currentSectionStart }
    newSectionStart[season] = newSectionStartValue
    // update current page
    let currentPage = parseInt(this.state.currentPage[season])
    let nextPage = { ...this.state.currentPage }
    nextPage[season] = currentPage + 1
    // set state for page and section
    this.setState({
      currentSectionStart: newSectionStart,
      currentPage: nextPage,
    })
  }

  // takes the user to the page number they clicked on for the
  // discussion section records
  handleNumberedPage(e, season) {
    e.preventDefault()
    // update page number
    let pageNumValue = parseInt(e.target.innerHTML)
    let newPage = { ...this.state.currentPage }
    newPage[season] = pageNumValue
    // update section start
    let newSectionStartValue =
      (pageNumValue - 1) * parseInt(this.state.sectionDisplayValue)
    let newSectionStart = { ...this.state.currentSectionStart }
    newSectionStart[season] = newSectionStartValue
    // set page and section start state
    this.setState({
      currentSectionStart: newSectionStart,
      currentPage: newPage,
    })
  }

  renderTermSchedules(schedules, note, instruction_mode) {
    // schedules is an array of schedules, must have at least one element inside
    let all_schedule_listing = schedules.map((schedule, index) => {
      if (schedule.instructors !== undefined) {
        let instructor_names = schedule.instructors.map((instructor) => {
          return instructor.name
        })
        let timeOfClass, location
        // Time and location are not promised to exist
        if (schedule.days && schedule.days.length > 0) {
          let days = schedule.days[0]
          for (let i = 1; i < schedule.days.length; i++) {
            days = days + ', ' + schedule.days[i]
          }
          // Note start_time and end_time are not promised to exist
          let classTime =
            schedule.start_time && schedule.end_time
              ? ' from ' +
                parseTimeToHHMM(schedule.start_time) +
                ' to ' +
                parseTimeToHHMM(schedule.end_time)
              : null
          timeOfClass = (
            <div className="info-content">
              <b>Time of Class: </b>
              {days + ' ' + classTime}
            </div>
          )
        }
        if (schedule.location) {
          location = (
            <div className="info-content">
              <b>Location: </b>
              {schedule.location}
            </div>
          )
        }

        return (
          <div key={index} className="info">
            <div className="info-all-content">
              {timeOfClass}
              {location}
              <div className="info-content">
                <b>Instructor(s): </b> {instructor_names.join(', ')}
              </div>
              <div className="info-content">
                {note && <b>Notes: </b>}
                {note}
              </div>
              {instruction_mode && (
                <div className="info-content">
                  <b>Instruction mode: </b>
                  {instruction_mode}
                </div>
              )}
              <hr></hr>
            </div>
          </div>
        )
      } else {
        return null
      }
    })

    return <div>{all_schedule_listing}</div>
  }

  renderSection(sections, season) {
    if (sections.length === 0) {
      return <div>No Section Information for {season} Quarter</div>
    }

    let sectionRows = []
    // create table rows for each dicsussion section
    for (let j = 0; j < sections.length; j++) {
      let sectionNumber = 'Not Available'
      let sectionDays = 'Not Available'
      let sectionTime = 'Not Available'
      let sectionInstructors = 'Not Available'
      sectionNumber = sections[j].section_number
      if (
        sections[j].schedules !== undefined &&
        sections[j].schedules.length > 0
      ) {
        sectionDays = sections[j].schedules[0].days.toString()
        if (sections[j].schedules[0].instructors.length > 0) {
          let arrayOfNames = sections[j].schedules[0].instructors.map(
            (instructor) => instructor.name
          )
          sectionInstructors = arrayOfNames.join(', ')
        }
        if (
          sections[j].schedules[0].start_time !== undefined &&
          sections[j].schedules[0].end_time !== undefined &&
          sections[j].schedules[0].start_time !== null &&
          sections[j].schedules[0].end_time !== null
        ) {
          sectionTime =
            sections[j].schedules[0].start_time.toString() +
            ' to ' +
            sections[j].schedules[0].end_time.toString()
        }
      }
      sectionRows.push(
        <tr key={j}>
          <td>{sectionNumber}</td>
          <td>{sectionDays}</td>
          <td>{sectionTime}</td>
          <td>{sectionInstructors}</td>
        </tr>
      )
    }

    return (
      <div>
        <h4>Sections:</h4>
        <p>
          <b>
            Show &nbsp;
            <select
              value={this.state.sectionDisplayValue}
              onChange={(e) => {
                this.handleSectionDisplayValue(e, season)
              }}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            &nbsp; entries
          </b>
        </p>
        <Table striped hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Days</th>
              <th>Times</th>
              <th>Instructors</th>
            </tr>
          </thead>
          <tbody>
            {(() => {
              // only display the rows for the current page
              let rowsToDisplay = sectionRows.slice()
              rowsToDisplay = rowsToDisplay.splice(
                parseInt(this.state.currentSectionStart[season]),
                parseInt(this.state.sectionDisplayValue)
              )
              return rowsToDisplay
            })()}
          </tbody>
        </Table>
        {(() => {
          let showingStart =
            parseInt(this.state.currentSectionStart[season]) + 1
          let showingEnd =
            parseInt(this.state.currentSectionStart[season]) +
            parseInt(this.state.sectionDisplayValue)
          let totalEntries = sectionRows.length
          if (showingEnd > totalEntries) {
            showingEnd = totalEntries
          }
          return (
            <>
              <p>
                Showing {showingStart} to {showingEnd} of {totalEntries} entries
              </p>
              <span className="schedule-pageinate">
                {(() => {
                  // only display previous Button if not viewing first page
                  if (this.state.currentPage[season] !== 1) {
                    return (
                      <button
                        className="schedule-pageinate-button"
                        onClick={(e) => {
                          this.handlePreviousPage(e, season)
                        }}
                      >
                        Previous
                      </button>
                    )
                  }
                })()}

                {(() => {
                  let numPages = Math.ceil(
                    totalEntries / parseInt(this.state.sectionDisplayValue)
                  )
                  let pages = []
                  for (let j = 1; j <= numPages; j++) {
                    if (j === this.state.currentPage[season]) {
                      pages.push(
                        <button
                          key={j}
                          className="schedule-pageinate-button-active"
                          onClick={(e) => {
                            this.handleNumberedPage(e, season)
                          }}
                        >
                          {j}
                        </button>
                      )
                    } else {
                      pages.push(
                        <button
                          className="schedule-pageinate-button"
                          onClick={(e) => {
                            this.handleNumberedPage(e, season)
                          }}
                        >
                          {j}
                        </button>
                      )
                    }
                  }
                  return pages
                })()}

                {(() => {
                  // only display next Button if not viewing last page
                  let pageCompare = this.state.currentPage[season]
                  let totalPagesRequired = Math.ceil(
                    totalEntries / parseInt(this.state.sectionDisplayValue)
                  )
                  if (pageCompare !== totalPagesRequired) {
                    return (
                      <button
                        className="schedule-pageinate-button"
                        onClick={(e) => {
                          this.handleNextPage(e, season)
                        }}
                      >
                        Next
                      </button>
                    )
                  }
                })()}
              </span>
            </>
          )
        })()}
      </div>
    )
  }

  render() {
    // TODO: check for discussion sections, extract the scheuldes and pass to this.renderSection
    // The following only deals with non-DIS sections
    let seasons_offered = this.props.course.seasons_offered
    let offerings = this.props.course.offerings

    if (
      !seasons_offered ||
      seasons_offered.length === 0 ||
      !offerings ||
      offerings.length === 0
    ) {
      return <div>No Information Available</div>
    }
    let seasonToTermSchedule = {
      Autumn: [],
      Winter: [],
      Spring: [],
      Summer: [],
    }
    let combinedTerms = []
    let notes = []
    let instruction_modes = []
    let mapTermIdtoIndexLectures = {}
    let countTermsLectures = 0
    let discussionSectionOfferings = []
    let discussionSections = {
      Autumn: [],
      Winter: [],
      Spring: [],
      Summer: [],
    }

    // combines multiple offerrings that are in the same term
    // might not be needed if each offerring has a unique term
    for (let i = 0; i < offerings.length; i++) {
      let curScheduleTerm = offerings[i].term.id
      // collect both lecture and discussion section data now in separate arrays
      if (offerings[i].component !== 'DIS') {
        if (curScheduleTerm in mapTermIdtoIndexLectures) {
          // extend existing term schedules
          let index = mapTermIdtoIndexLectures[curScheduleTerm]
          combinedTerms[index]['schedules'].push(offerings[i])
        } else {
          mapTermIdtoIndexLectures[curScheduleTerm] = countTermsLectures
          countTermsLectures += 1
          let offering = {
            schedules: offerings[i].schedules,
            term: offerings[i].term,
          }
          combinedTerms.push(offering)
        }
      } else {
        discussionSectionOfferings.push(offerings[i])
      }
      instruction_modes.push(offerings[i].instruction_mode)
      notes.push(offerings[i].notes)
    }

    combinedTerms.forEach((offering, index) => {
      let term = offering.term
      // Start date end date are tied to schedules instead of terms
      // The following assumes all schedules in the same term
      // have the same start/end date
      let schedules = offering.schedules
      if (!term || !term.season || !schedules || !schedules[0]) {
        return
      }
      // The schedules in the same term should have the same start date and end date,
      // so just use the first schedule
      let season = term.season
      let dateInfo = null
      if (schedules[0].start_date && schedules[0].end_date) {
        // only rendering this term's timing
        // when both start_date and end_date exists
        dateInfo = (
          <div className="time">
            {schedules[0].start_date + ' to ' + schedules[0].end_date}
          </div>
        )
      }
      let termSchedule = (
        <div key={index}>
          {dateInfo}
          <div className="lecture">Lectures:</div>
          {this.renderTermSchedules(
            schedules,
            notes[index],
            instruction_modes[index]
          )}
        </div>
      )
      seasonToTermSchedule[season].push(termSchedule)
    })

    let all_season = ['Autumn', 'Winter', 'Spring', 'Summer']
    let defaultKey
    for (let key = 0; key < all_season.length; key++) {
      if (seasonToTermSchedule[all_season[key]].length > 0) {
        // This sets which tab is displayed by default
        defaultKey = all_season[key]
        break
      }
    }

    for (let i = 0; i < discussionSectionOfferings.length; i++) {
      let currentSeason = discussionSectionOfferings[i].term.season
      // do not include sections that have been cancelled
      if (
        discussionSectionOfferings[i].notes !==
        'This class section has been cancelled!'
      ) {
        discussionSections[currentSeason].push(discussionSectionOfferings[i])
      }
    }

    return (
      <ScheduleWrapper>
        <Tabs
          defaultActiveKey={defaultKey}
          id="uncontrolled-tab-example"
          style={{ margin: 0, borderBottom: '1px solid #dee2e6' }}
        >
          {all_season.map((season) => {
            return seasonToTermSchedule[season].length > 0 ? (
              <Tab key={season} eventKey={season} title={season}>
                {seasonToTermSchedule[season]}
                {(() => {
                  if (discussionSectionOfferings.length > 0)
                    return this.renderSection(
                      discussionSections[season],
                      season
                    )
                })()}
              </Tab>
            ) : null
          })}
        </Tabs>
      </ScheduleWrapper>
    )
  }
}

GlanceSched.propTypes = {}

export default GlanceSched
