import React from 'react'
import styled from 'styled-components'
import DiagramBlock from './DiagramBlock'
import LandingExplore from '../../../../assets/landing_explore.png'
import CompareExplore from '../../../../assets/landing_compare.png'
import PlanExplore from '../../../../assets/landing_plan.svg'


const DiagramsWrapper = styled.div`
  padding: 120px 0;
  display: flex;
  flex-flow: column;
  align-items: center;
`

export default function Diagrams() {
  return (
    <DiagramsWrapper>
      <DiagramBlock
        supertitle="Explore"
        title="Find a class that fits your needs."
        text="Carta augments search with course recommendations and a departmental catalog to help you build out your best class schedule — whether you want to find specific requirements or try new things."
        image={LandingExplore}
        reverse={true}
      ></DiagramBlock>
      <DiagramBlock
        supertitle="Compare"
        title="Make the final choice with confidence."
        text="We display Stanford-provided data such as enrollment statistics, time commitment estimates, reviews, and more to make finalizing your quarter more certain."
        image={CompareExplore}
      ></DiagramBlock>
      <DiagramBlock
        supertitle="Plan - coming soon"
        title="Build a roadmap for your education that evolves with you."
        text="We’re building a course planning tool tailored to the undergradute experience because as students grow, their tools should grow with them."
        image={PlanExplore}
        reverse={true}
      ></DiagramBlock>
    </DiagramsWrapper>
  )
}
