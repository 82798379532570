import {
  FETCH_COURSE_BEGIN,
  FETCH_COURSE_SUCCESS,
  FETCH_COURSE_FAILURE,
  FETCH_REVIEWS_BEGIN,
  FETCH_REVIEWS_SUCCESS,
  FETCH_REVIEWS_FAILURE,
} from '../actions/courseActions'

const initialState = {
  currentCourse: {},
  courseReviews: [],
  loading: false,
  loadingReviews: false,
  error: null,
  reviewsError: null,
}

export default function courseReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_COURSE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        currentCourse: [],
      }

    case FETCH_REVIEWS_BEGIN:
      return {
        ...state,
        loadingReviews: true,
        reviewsError: null,
        courseReviews: [],
      }

    case FETCH_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        currentCourse: action.payload.data,
      }

    case FETCH_REVIEWS_SUCCESS:
      return {
        ...state,
        loadingReviews: false,
        courseReviews: action.payload.data,
      }

    case FETCH_COURSE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        currentCourse: {},
      }

    case FETCH_REVIEWS_FAILURE:
      return {
        ...state,
        loadingReviews: false,
        reviewsError: action.payload.error,
      }

    default:
      return state
  }
}
