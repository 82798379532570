import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

export default function DiagramBlock(props) {
  return (
    <Container>
      <Row
        style={{
          flexDirection: props.reverse ? 'row-reverse' : 'row',
          margin: '120px 0',
        }}
      >
        <Col md={true}>
          <p>{props.supertitle}</p>
          <h2
            style={{
              marginBottom: '24px',
              fontWeight: 'bold',
              lineHeight: '120%',
            }}
          >
            {props.title}
          </h2>
          <p>{props.text}</p>
        </Col>
        <Col md={true}>
          <img src={props.image} alt="diagram-img" />
        </Col>
      </Row>
    </Container>
  )
}
