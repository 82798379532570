import React, { Component } from 'react'
import Calendar from 'react-big-calendar'
import moment from 'moment'
import styled from 'styled-components'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { CALENDAR_COLORS } from '../assets/constant_colors'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

const localizer = Calendar.momentLocalizer(moment)

const CalendarWrapper = styled.div`
  .rbc-allday-cell {
    display: none;
  }

  .rbc-time-view .rbc-header {
    border-bottom: none;
  }

  .rbc-time-column {
    min-height: auto;
  }

  .rbc-calendar {
    height: auto;
  }

  .rbc-day-slot .rbc-events-container {
    margin-right: 0px;
  }

  .rbc-current-time-indicator {
    background-color: transparent;
  }

  .rbc-day-slot .rbc-events-container {
    margin-right: 0px;
  }

  .rbc-event-label {
    display: none;
  }

  .rbc-day-slot .rbc-event-content {
    word-wrap: unset;
  }

  .rbc-today {
    background-color: transparent;
  }

  width: 100%;
`

class ClassCalendar extends Component {
  isOfferingSelected(pin, offering) {
    return pin.offering_ids.indexOf(offering.id) !== -1
  }

  render() {
    const { pinnedCourses, term } = this.props
    const min = new Date()
    min.setHours(8)
    min.setMinutes(0)
    min.setSeconds(0)
    const max = new Date()
    max.setHours(16)
    max.setMinutes(0)
    max.setSeconds(0)

    let events = []

    let filteredCourses = pinnedCourses.filter(
      (pinnedCourse) => pinnedCourse.term.id === term.id
    )

    filteredCourses.forEach((pinnedCourse, courseId) => {
      var course = pinnedCourse.course

      if (!course.offerings || course.offerings.length === 0) {
        return
      }

      if (!course?.course_codes?.[0]) {
        return
      }
      course.offerings
        .filter((offering) => offering.component !== 'DIS')
        .forEach((offering, sectionId) => {
          if (
            offering.term_id === term.id &&
            this.isOfferingSelected(pinnedCourse, offering) &&
            offering.schedules &&
            offering.schedules[0]
          ) {
            if (
              offering.schedules[0].start_time &&
              offering.schedules[0].end_time
            ) {
              if (
                parseTimeStringHours(offering.schedules[0].start_time) <
                min.getHours()
              ) {
                min.setHours(
                  parseTimeStringHours(offering.schedules[0].start_time)
                )
              }
              if (
                parseTimeStringHours(offering.schedules[0].end_time) >
                max.getHours()
              ) {
                max.setHours(
                  parseTimeStringHours(offering.schedules[0].end_time)
                )
              }
              events.push(
                ...createCourseEvents(
                  course.course_codes.join(' / '),
                  offering.schedules[0].days,
                  offering.schedules[0].start_time,
                  offering.schedules[0].end_time,
                  sectionId % 2
                    ? CALENDAR_COLORS[courseId % 6].light
                    : CALENDAR_COLORS[courseId % 6].dark
                )
              )
            }
          }
        })
    })

    let formats = {
      dayFormat: 'dd',
      timeGutterFormat: 'h a',
    }

    return (
      <CalendarWrapper>
        <Calendar
          localizer={localizer}
          defaultDate={new Date()}
          events={events}
          defaultView={'work_week'}
          views={['work_week', 'month']}
          toolbar={false}
          showMultiDayTimes={false}
          min={min}
          max={max}
          formats={formats}
          eventPropGetter={(event, start, end, isSelected) => {
            let newStyle = {
              backgroundColor: 'blue',
              color: 'white',
              borderRadius: '0px',
              border: 'none',
              fontSize: '10px',
            }

            if (event.color) {
              newStyle.backgroundColor = event.color
            }

            return {
              className: '',
              style: newStyle,
            }
          }}
          onSelectEvent={(e) =>
            this.props.history.push(
              `/course/${e.title.split('/')[0].trim()}/${
                this.props.term.stanford_term_id
              }`
            )
          }
        />
      </CalendarWrapper>
    )
  }
}

function createCourseEvents(name, days, start, end, color) {
  return days.map((day) => {
    const event = {
      id: 14,
      title: name,
      start: createDate(start, day),
      end: createDate(end, day),
      color: color,
    }
    return event
  })
}

function createDate(timeString, dayString) {
  let date = new Date()
  let offsets = {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
  }
  let dateData = {
    year: date.getFullYear(),
    month: date.getMonth(),
    date: date.getDate() - date.getDay(),
  }
  return new Date(
    dateData.year,
    dateData.month,
    dateData.date + offsets[dayString],
    parseTimeStringHours(timeString),
    parseTimeStringMinutes(timeString),
    0,
    0
  )
}

function parseTimeStringHours(timeString) {
  let hours = parseInt(timeString.split(':')[0])
  if (hours !== 12 && timeString.slice(-2) === 'PM') {
    hours += 12
  }
  return hours
}

function parseTimeStringMinutes(timeString) {
  return parseInt(timeString.split(':')[1])
}

function mapStateToProps(state) {
  return {
    pinnedCourses: state.pinnedCourses.pinnedCourses,
    term: state.term.term,
  }
}

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ClassCalendar))
