import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'react-bootstrap'
import { Card } from 'react-bootstrap'
import { REVIEW_DATE_COLOR } from '../../assets/constant_colors.js'
import {
  DEFAULT_INITAL_NUM_REVIEWS_SHOWN,
  DEFAULT_NUM_REVIEWS_INCREMENT,
} from '../../assets/constant_sizes.js'
import {
  REVIEWS_INTRO_LINE,
  REVIEWS_FILTER_PROMPT,
} from '../../assets/constant_strings.js'
import { getSeason, getStartYear, getEndYear } from '../../Tools/utils.js'
import { connect } from 'react-redux'

const ReviewsCardWrapper = styled.div`
  && .container {
    padding: 0;
    margin: 0;
  }

  & .card {
    margin: 2px;
    font-size: 14px;
  }

  & .quarter-taken {
    color: ${REVIEW_DATE_COLOR};
    font-family: 'Roboto', sans-serif;
  }

  & .col-lg-6 {
    padding-left: 2px;
    padding-bottom: 10px;
  }

  & .col-lg-6 {
    padding-left: 2px;
  }
  & #review-filter-input {
    font-weight: normal;
  }
  & #pseudo_link {
    color: #428bca;
  }
  & #pseudo_link:hover {
    color: #3d65a5;
    text-decoration-line: underline;
    cursor: pointer;
  }
`

class ReviewsCard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: '',
      currReviews: props.courseReviews,
      numReviewsToShow: DEFAULT_INITAL_NUM_REVIEWS_SHOWN,
    }

    this.handleChange = this.handleChange.bind(this)
    this.showMore = this.showMore.bind(this)
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.courseReviews !== this.props.courseReviews) {
      this.setState({ currReviews: nextProps.courseReviews })
    }
    return true
  }

  //handles filtering functionality
  handleChange(event) {
    const { courseReviews } = this.props
    const { numReviewsToShow } = this.state

    var inputValue = event.target.value
    this.setState({ value: inputValue })
    var newCurrReviews = courseReviews.filter(
      item => item.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
    )
    this.setState({ currReviews: newCurrReviews })

    var nextNumReviewsToShow = Math.min(newCurrReviews.length, numReviewsToShow)
    if (nextNumReviewsToShow < DEFAULT_INITAL_NUM_REVIEWS_SHOWN)
      nextNumReviewsToShow = DEFAULT_INITAL_NUM_REVIEWS_SHOWN
    this.setState({ numReviewsToShow: nextNumReviewsToShow })
  }

  showMore() {
    const { currReviews, numReviewsToShow } = this.state

    var numReviewsLeft = currReviews.length - numReviewsToShow
    var increment =
      numReviewsLeft >= DEFAULT_NUM_REVIEWS_INCREMENT
        ? DEFAULT_NUM_REVIEWS_INCREMENT
        : numReviewsLeft
    var incremented = numReviewsToShow + increment
    if (increment > 0) this.setState({ numReviewsToShow: incremented })
  }

  filterForm() {
    const { courseReviews } = this.props
    const { value, currReviews } = this.state

    return (
      <form>
        <label>
          <b>{REVIEWS_FILTER_PROMPT}</b>
          <input
            id="review-filter-input"
            type="text"
            value={value}
            onChange={this.handleChange}
          />
        </label>
        <span>
          {' '}
          (Filtered {currReviews.length} out of {courseReviews.length} reviews)
        </span>
      </form>
    )
  }

  reviewPanels() {
    const { currReviews, numReviewsToShow } = this.state

    const panelItems1 = currReviews
      .slice(0, numReviewsToShow / 2 + 1)
      .map((review, index) => (
        <Card key={index}>
          <Card.Body>
            <span>{review.value}</span>
            <br />
            <span className="quarter-taken">
              {'(' +
                getStartYear(review.term.stanford_term_id) +
                '/' +
                getEndYear(review.term.stanford_term_id) +
                ', ' +
                getSeason(review.term.stanford_term_id) +
                ')'}
            </span>
          </Card.Body>
        </Card>
      ))

    const panelItems2 = currReviews
      .slice(numReviewsToShow / 2 + 1, numReviewsToShow)
      .map((review, index) => (
        <Card key={index}>
          <Card.Body key={index}>
            <span>{review.value}</span>
            <br />
            <span className="quarter-taken">
              {'(' +
                getStartYear(review.term.stanford_term_id) +
                '/' +
                getEndYear(review.term.stanford_term_id) +
                ', ' +
                getSeason(review.term.stanford_term_id)+
                ')'}
            </span>
          </Card.Body>
        </Card>
      ))

    return (
      <Row>
        <Col xs={12} md={12} lg={6}>
          {panelItems1}
        </Col>
        <Col xs={12} md={12} lg={6}>
          {panelItems2}
          {currReviews.length > numReviewsToShow ? (
            <span id="pseudo_link" onClick={this.showMore}>
              Show More
            </span>
          ) : (
            <div />
          )}
          {/* ^ did it this way with span and not just a tag w/o href because throws warning. */}
        </Col>
      </Row>
    )
  }

  render() {
    return (
      <ReviewsCardWrapper>
        <Container>
          <Row>
            <Col>
              <div>{REVIEWS_INTRO_LINE}</div>
            </Col>
          </Row>
          <Row>
            <Col>{this.filterForm()}</Col>
          </Row>
          {this.reviewPanels()}
        </Container>
      </ReviewsCardWrapper>
    )
  }
}

ReviewsCard.propTypes = {
  numReviews: PropTypes.number,
}

function mapStateToProps(state) {
  return {
    courseReviews: state.course.courseReviews || [],
  }
}

export default connect(mapStateToProps)(ReviewsCard)
