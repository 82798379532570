import React, { Component } from 'react'
import styled from 'styled-components'
import Logo from '../../../../assets/logo.svg'

const LogoutNavbarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`

export default class LogoutNavbar extends Component {
  render() {
    return (
      <LogoutNavbarWrapper>
        <img src={Logo} alt="stanford-carta-logo" />
      </LogoutNavbarWrapper>
    )
  }
}
