import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Container, Row, Col, Tooltip, OverlayTrigger } from 'react-bootstrap'
import BarGraph from '../../Tools/BarGraph'
import { INTENSITY_COLOR } from '../../assets/constant_colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'

const IntensityWrapper = styled.div`
  && .container {
    padding: 0;
    margin: 0;
  }

  & .col-lg-12,
  .col-lg-6,
  .col-lg-3 {
    padding-left: 0;
    padding-bottom: 10px;
  }
`

const GraphWrapper = styled.div`
  height: 350px;
  padding-bottom: 10px;
`

class IntensityCard extends Component {
  renderIntensity() {
    const renderTooltip = (props) => {
      const { pid, ...rest } = props
      return (
        <Tooltip id="button-tooltip" {...rest}>
          Includes time spent in class meetings. Reported by students via course
          evaluations over all offerings, rounded to the nearest 10
        </Tooltip>
      )
    }

    const { introLine, intensity } = this.props

    const data = []
    if (intensity && intensity.values) {
      const valueTotal = intensity.values.reduce((a, b) => a + b, 0)

      for (let i = 0; i < intensity.columns.length; i++) {
        data.push({
          hours: intensity.columns[i],
          percent: Math.round((intensity.values[i] / valueTotal) * 100),
        })
      }
      return (
        <GraphWrapper>
          <p>
            <b>
              {introLine} ({valueTotal} students)
            </b>{' '}
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <span>
                <FontAwesomeIcon icon={faQuestionCircle} color="#aaa" />
              </span>
            </OverlayTrigger>
          </p>
          <BarGraph
            data={data}
            barColor={INTENSITY_COLOR}
            layout="vertical"
            xType="number"
            yType="category"
            xDataKey={'percent'}
            yDataKey={'hours'}
            barKey={'percent'}
            hLine={false}
            widthY={80}
          />
        </GraphWrapper>
      )
    }
    return (
      <p>
        <b>{introLine}:</b> Not available for this course
      </p>
    )
  }

  renderEnrollment() {
    const renderTooltip = (props) => {
      const { pid, ...rest } = props
      return (
        <Tooltip id="button-tooltip" {...rest}>
          Since the 2003-2004 academic year, rounded to the nearest 10
        </Tooltip>
      )
    }
    const { secondTitleLine, enrollment } = this.props

    const enrollmentOptions = {
      ENRL: 'Completed',
      EWAT: 'Completed (was waitlisted)',
      DROP: 'Dropped (was enrolled)',
      DRWL: 'Dropped (was waitlisted)',
      WDRW: 'Withdrawn from class',
      FULL: 'Section is full',
      RCMP: 'Related component',
      TIME: 'Time conflict exists',
    }

    const data = []
    if (enrollment && enrollment.values) {
      const valueTotal = enrollment.values.reduce((a, b) => a + b, 0)
      Object.keys(enrollmentOptions).forEach(function (item, index) {
        const i = enrollment.columns.indexOf(item)
        if (enrollment.values[i] === 0) return
        data.push({
          enroll: enrollmentOptions[item],
          percent: Math.round((enrollment.values[i] / valueTotal) * 100),
        })
      })
      return (
        <GraphWrapper style={{ height: '250px' }}>
          <p>
            <b>
              {secondTitleLine} ({valueTotal} students)
            </b>{' '}
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <span>
                <FontAwesomeIcon icon={faQuestionCircle} color="#aaa" />
              </span>
            </OverlayTrigger>
          </p>
          <BarGraph
            data={data}
            barColor={INTENSITY_COLOR}
            layout="vertical"
            xType="number"
            yType="category"
            xDataKey={'percent'}
            yDataKey={'enroll'}
            barKey={'percent'}
            hLine={false}
            widthY={80}
          />
        </GraphWrapper>
      )
    }
    return (
      <p>
        <b>{secondTitleLine}:</b> Not available for this course
      </p>
    )
  }

  render() {
    return (
      <IntensityWrapper>
        <Container>
          <Row>
            <Col lg={12}>{this.renderIntensity()}</Col>
          </Row>
          <hr></hr>
          <Row>
            <Col lg={12}>{this.renderEnrollment()}</Col>
          </Row>
        </Container>
      </IntensityWrapper>
    )
  }
}

IntensityCard.propTypes = {
  numStudents: PropTypes.number,
  introLine: PropTypes.string,
}

function mapStateToProps(state) {
  return {
    intensity: state.graphs.currentGraphs.intensity,
    enrollment: state.graphs.currentGraphs.outcomes,
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(IntensityCard)
