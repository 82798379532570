// NavBar.js
export const FONT_WEIGHT = 400
export const NAVBAR_HEIGHT = '45px'
export const SIDE_PANEL_WIDTH = '350px'
export const EVAL_HEIGHT = '125px'
export const THRESHOLD = 830
export const TABLET_THRESHOLD = 1000
export const MOBILE_THRESHOLD = 576
export const Z_INDEX = 999
export const SEQ_HEIGHT = '200px'
export const MIN_NUM_SEARCH_LETTERS = 2
export const MAX_NUM_SEARCH_SUGGESTIONS = 10
export const SEARCH_LIMIT = 15
export const INTENSITY_HEIGHT = SEQ_HEIGHT
export const PERFORMANCE_HEIGHT = SEQ_HEIGHT
export const DEFAULT_INITAL_NUM_REVIEWS_SHOWN = 8
export const DEFAULT_INITAL_NUM_RATINGS_SHOWN = 5
export const DEFAULT_NUM_REVIEWS_INCREMENT = 6
export const DEFAULT_NUM_RATINGS_INCREMENT = 5
export const PERFORMANCE_WIDTH = '450px'
export const DEBOUNCE_IN_MILIS = 613
export const PLANNER_START_YEAR = 2017
