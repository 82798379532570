import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'
import ErrorPageArt from '../../../../assets/404_art.svg'
import CartaLogo from '../../../../assets/stanford_carta_logo_red.svg'
import Feedback from '../../Feedback'

const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
`
const ErrorHeader1 = styled.p`
  font-weight: 600;
  line-height: 76px;
  font-size: 64px;
  margin-bottom: 32px;
`
const ErrorHeader2 = styled.div`
  display: 'flex';
  font-weight: 400;
  line-height: 42px;
  font-size: 32px;
`
const ErrorText = styled.div`
  font-weight: 400;
  line-height: 27px;
  font-size: 18px;
`

const Link = styled.a`
  color: #181817;
  text-decoration: underline;
  &:hover {
    color: #90050d;
  }
`

const FeedbackLink = styled.span`
  color: #181817;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: #90050d;
  }
`


export default class ErrorMessage extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
  }

  onClickWriteFeedback = () => {
    this.child.current.toggleModal();
  }

  render() {
    return (
      <>
        <ErrorWrapper>
          <Container
            style={{
              marginLeft: 'auto',
              marginTop: '8%',
              paddingLeft: '4%',
              paddingRight: '4%',
            }}
          >
            <Row style={{ alignItems: 'flex-start' }}>
              <Col>
                {!this.props.isLoggedIn && (
                  <img
                    style={{
                      position: 'relative',
                      zIndex: 100,
                      maxWidth: '100%',
                      paddingBottom: '64px',
                    }}
                    src={CartaLogo}
                    alt="Carta Logo"
                  />
                )}
                <ErrorHeader1>Oops!</ErrorHeader1>
                <ErrorHeader2>
                  We can't see to find the page you're looking for. Try searching
                  again, or try the pages below.
              </ErrorHeader2>
                <ErrorText style={{ paddingTop: 24 }}>
                  <Link href="/">Home</Link>
                </ErrorText>
                {/* if user is logged in display these two pages */}
                {this.props.isLoggedIn && (
                  <ErrorText>
                    <Link href="planner">Planner</Link>
                  </ErrorText>
                )}
                <ErrorText style={{ paddingTop: 48 }}>
                  Got questions or concerns?{" "}
                  <FeedbackLink onClick={this.onClickWriteFeedback}>Write us feedback.</FeedbackLink>
                </ErrorText>
                <Feedback ref={this.child}></Feedback>
              </Col>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '50%',
                }}
              >
                <img
                  style={{
                    position: 'relative',
                    zIndex: 100,
                    maxWidth: '100%',
                    paddingTop: '20px',
                  }}
                  src={ErrorPageArt}
                  alt="404-art"
                />
              </div>
            </Row>
          </Container>
        </ErrorWrapper>
      </>
    )
  }
}
