import {
  FETCH_TERM_BEGIN,
  FETCH_TERM_SUCCESS,
  FETCH_TERM_FAILURE,
  FETCH_CURRENT_YEAR_TERMS_BEGIN,
  FETCH_CURRENT_YEAR_TERMS_SUCCESS,
} from '../actions/termActions'

const initialState = {
  term: {},
  currentYearTerms: new Map(),
  loading: false,
  error: null,
}

export default function termReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TERM_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case FETCH_TERM_SUCCESS:
      return {
        ...state,
        loading: false,
        term: action.payload.data,
      }

    case FETCH_TERM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        term: {},
      }

    case FETCH_CURRENT_YEAR_TERMS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case FETCH_CURRENT_YEAR_TERMS_SUCCESS:
      return {
        ...state,
        currentYearTerms: action.payload.data,
        loading: false,
      }

    default:
      return state
  }
}
