import axios from 'axios'
import Cookies from 'universal-cookie'

const base_url = process.env.BASE_URL

const urls = {
  authentication: base_url + `/authentication/login/`,
  course: {
    courseReview(courseId) {
      return base_url + `/reviews/?course_id=${courseId}`
    },
    courseData(courseCode) {
      return base_url + `/courses/codes/${courseCode}/`
    },
    courseInfo(courseId) {
      return base_url + `/courses/${courseId}/`
    },
    courseGraphs(courseId) {
      return base_url + `/courses/${courseId}/graphs/`
    },
  },
  instructor: {
    instructorDetails(instructorId) {
      return base_url + `/instructors/${instructorId}`
    },
    instructorCourses(instructorId) {
      return base_url + `/instructors/${instructorId}/courses/`
    },
  },
  search: {
    search(query, limit = 10, offset = 0) {
      var search_url = `/search?query=${encodeURIComponent(
        query
      )}&limit=${limit}&offset=${offset}`
      return base_url + search_url
    },
    index: base_url + `/search/index`,
  },
  term: {
    default() {
      return base_url + `/term/default/`
    },
    list: base_url + '/term/',
    term(id) {
      return base_url + `/term/${id}`
    },
    termId(id) {
      return base_url + `/term/term_id?short_term_id=${id}`
    },
  },
  pins: base_url + `/pins/`,
  waitlist: base_url + `/waitlist/`,
  catalog: base_url + `/catalog/`,
  user: base_url + `/authentication/user/`,
  feedback: base_url + `/feedback/`,
  commitment: base_url + `/time-commitment/`,
}

export const cookies = new Cookies()
function getHeaders() {
  return {
    headers: {
      Authorization: `Token ${cookies.get('authToken')}`,
    },
  }
}

function getURL(url) {
  return axios
    .get(url, getHeaders())
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.log(error)
      if (error.response && error.response.status === 401) {
        api.authentication.redirect(window.location.pathname)
      } else if (error.response && error.response.status === 403) {
        // probably should handle this somehow?
      } else {
        throw error
      }
    })
}

function postURL(url, data) {
  return axios.post(url, data, getHeaders()).then(response => {
    return response.data
  })
}

function putURL(url, data) {
  return axios.put(url, data, getHeaders()).then(response => {
    return response.data
  })
}

function deleteURL(url) {
  return axios.delete(url, getHeaders())
}

let api = {
  authentication: {
    redirect(target) {
      const cookies = new Cookies()
      cookies.remove('authToken')
      let location = urls.authentication
      if (location) {
        location += `?redirect=${encodeURIComponent(target)}`
      }
      window.location.href = location
    },
  },
  catalog: {
    get() {
      return getURL(urls.catalog)
    },
  },
  course: {
    get(courseId) {
      return getURL(urls.course.courseInfo(courseId))
    },
    getByCode(courseCode) {
      return getURL(urls.course.courseData(courseCode))
    },
    getReviews(courseId) {
      return getURL(urls.course.courseReview(courseId))
    },
    getGraphs(courseId) {
      return getURL(urls.course.courseGraphs(courseId))
    },
  },
  instructor: {
    get(id) {
      return getURL(urls.instructor.instructorDetails(id))
    },
    getCourses(id) {
      return getURL(urls.instructor.instructorCourses(id))
    },
  },
  pins: {
    get() {
      return getURL(urls.pins).then(data => {
        return data
      })
    },
    update(pin) {
      let info = {
        id: pin.id,
        course_id: pin.course.id,
        term_id: pin.term.id,
        type: pin.type,
      }
      if (pin.offering_ids) {
        info.offering_ids = pin.offering_ids
      }
      return putURL(urls.pins + `${info.id}/`, info)
    },
    create(info) {
      return postURL(urls.pins, info)
    },
    destroy(id) {
      return deleteURL(urls.pins + `${id}/`)
    },
  },
  search: {
    search(query, limit = 10, offset = 0) {
      return getURL(urls.search.search(query, limit, offset))
    },
    index() {
      return getURL(urls.search.index)
    },
  },
  term: {
    getAll() {
      return getURL(urls.term.list)
    },
    getDefault() {
      return getURL(urls.term.default())
    },
    get(id) {
      if (id === undefined) return api.term.getDefault()
      return getURL(urls.term.term(id))
    },
    getByShortId(id) {
      if (id === undefined) return api.term.getDefault()
      return getURL(urls.term.termId(id)).then(data => data.term)
    },
  },
  waitlist: {
    get() {
      return getURL(urls.waitlist)
    },
    count() {
      return getURL(urls.waitlist + '/count/')
    },
    join() {
      return postURL(urls.waitlist)
    },
  },
  user: {
    get() {
      return getURL(urls.user)
    },
  },
  feedback: {
    submit(info) {
      return postURL(urls.feedback, info)
    },
  },
  commitment: {
    get() {
      return getURL(urls.commitment)
    },
  },
}

export default api
