import api from '../../api'

export const FETCH_TIME_COMMITMENT_BEGIN = 'FETCH_TIME_COMMITMENT_BEGIN'
export const FETCH_TIME_COMMITMENT_SUCCESS = 'FETCH_TIME_COMMITMENT_SUCCESS'
export const FETCH_TIME_COMMITMENT_FAILURE = 'FETCH_TIME_COMMITMENT_FAILURE'

export const fetchTimeCommitmentBegin = () => ({
  type: FETCH_TIME_COMMITMENT_BEGIN,
})

export const fetchTimeCommitmentSuccess = data => ({
  type: FETCH_TIME_COMMITMENT_SUCCESS,
  payload: { data },
})

export const fetchTimeCommitmentFailure = error => ({
  type: FETCH_TIME_COMMITMENT_FAILURE,
  payload: { error },
})

export function fetchTimeCommitment() {
  return dispatch => {
    dispatch(fetchTimeCommitmentBegin())

    api.commitment.get().then(data => {
      dispatch(fetchTimeCommitmentSuccess(data))
    })
  }
}
