import React from 'react'
import styled from 'styled-components'
import HeroBackgroundImage from '../../../../assets/hero_background.svg'

const HeroWrapper = styled.div`
  padding: 30px 4vw 50px 4vw;
  text-align: center;

  @media only screen and (min-width: 768px) {
    padding: 96px 4vw 100px 4vw;
  }
`

const HeroHeader1 = styled.div`
  text-align: center;
  max-width: 700px;
  margin-bottom: 32px;
`

const HeroHeader2 = styled.div`
  text-align: center;
  max-width: 500px;
  margin-bottom: 64px;
`

const HeroH1 = styled.h1`
  font-weight: 600;
  line-height: 120%;
  font-size: 50px;
`

const HeroH2 = styled.h2`
  line-height: 120%;
  font-weight: normal;
  font-size: 25px;
`

export default function Hero() {
  return (
    <div style={{ flexGrow: '1' }}>
      <img
        style={{
          position: 'absolute',
          top: 0,
          zIndex: -1,
          objectFit: 'contain',
        }}
        src={HeroBackgroundImage}
        alt="hero-background"
      />
      <HeroWrapper>
        <div
          style={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
          }}
        >
          <HeroHeader1>
            <HeroH1>
              Make sure to quit this browser when you are finished with your
              session
            </HeroH1>
          </HeroHeader1>
          <HeroHeader2>
            <HeroH2>
              If you do not, anyone subsequently using your computer will be
              able to use the browser to go to Web Authenticated sites, and the
              web sites will treat that person as you.
            </HeroH2>
          </HeroHeader2>
        </div>
      </HeroWrapper>
    </div>
  )
}
