import React, { Component } from 'react'
import styled from 'styled-components'
import { Accordion, Card } from 'react-bootstrap'
import GlanceCard from './GlanceCard'
import EvalCard from './EvalCard'
import IntensityCard from './IntensityCard'
import MinimalSeqCard from './MinimalSeqCard'
import PerformanceCard from './PerformanceCard'
import ReviewsCard from './ReviewsCard'
import CoursesCard from './CoursesCard'
import SeasonalCoursesCard from './SeasonalCoursesCard'
import { CARD_TYPES } from '../../assets/constant_strings'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons'

const PanelWrapper = styled.div`
  & .card-title {
    margin: 0;
    cursor: pointer;
  }

  & .card-body {
    padding: 0.5rem;
  }

  & hr {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
`

class GeneralCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: true,
    }

    this.handleClick = this.handleClick.bind(this)
  }

  renderCard() {
    const { cardType } = this.props
    switch (cardType) {
      case CARD_TYPES.GLANCE:
        return <GlanceCard />
      case CARD_TYPES.SEQUENCING:
        return <MinimalSeqCard introLine={'Academic years of past students'} />
      case CARD_TYPES.EVALUATIONS:
        return (
          <EvalCard
            teachers={'Zelenski, Julie; Gregg, Chris; Cain, Jerry'}
            quarter={'2017/2018, Spring'}
          />
        )
      case CARD_TYPES.INTENSITY:
        return (
          <IntensityCard
            introLine={'Hours per week'}
            secondTitleLine={'Enrollment outcomes'}
          />
        )
      case CARD_TYPES.PERFORMANCE:
        return (
          <PerformanceCard
            introLine={'Letter Grades'}
            secondTitleLine={'Credit/No Credit Grades'}
            hasCreditNoCredit={true}
          />
        )
      case CARD_TYPES.COURSE_REVIEWS:
        return (
          <ReviewsCard
            introLine={
              '"What would you like to say about this course to a student who is considering taking it in the future?"'
            }
            prompt={'Enter keywords to filter: '}
            courseInfo={this.props.fullCourseInfo}
          />
        )
      case CARD_TYPES.COURSES_LIST:
        return <CoursesCard coursesInfo={this.props.courses} />
      case CARD_TYPES.SEASONAL_COURSES:
        return <SeasonalCoursesCard courses={this.props.courses} />
      case CARD_TYPES.HTML:
        return this.props.children
      default:
        return null
    }
  }

  handleClick() {
    this.setState((state) => ({
      isOpen: !state.isOpen,
    }))
  }

  render() {
    const { cardTitle } = this.props

    return (
      <PanelWrapper>
        <Accordion defaultActiveKey="0">
          <Card>
            <Card.Header>
              <Accordion.Toggle
                as={Card.Title}
                eventKey="0"
                onClick={this.handleClick}
              >
                <span>
                  {this.state.isOpen ? (
                    <FontAwesomeIcon icon={faCaretDown} />
                  ) : (
                    <FontAwesomeIcon icon={faCaretRight} />
                  )}{' '}
                  <b>{cardTitle}</b>
                </span>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>{this.renderCard()}</Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </PanelWrapper>
    )
  }
}

export default GeneralCard
