import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Container, Row, Col, Alert } from 'react-bootstrap'
import BarGraph from '../../Tools/BarGraph'
import { PERFORMANCE_COLOR } from '../../assets/constant_colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'

const PerformanceWrapper = styled.div`
  && .container {
    padding: 0;
    margin: 0;
  }

  & .col-lg-12 {
    padding: 0px;
  }
`

const GraphWrapper = styled.div`
  height: 350px;
  padding-bottom: 20px;
`

class PerformanceCard extends Component {
  renderGrades() {
    const { introLine, performance } = this.props

    const letterGradeLabels = [
      'A+',
      'A',
      'A-',
      'B+',
      'B',
      'B-',
      'C+',
      'C',
      'C-',
      'D+',
      'D',
      'D-',
      'NP',
    ]

    const data = []

    if (performance && performance.values) {
      const valueTotal = performance.values
        .filter(
          (_, i) => letterGradeLabels.indexOf(performance.columns[i]) !== -1
        )
        .reduce((a, b) => a + b, 0)

      // if no letterGrade stats available
      if (valueTotal === 0)
        return (
          <p>
            <b>{introLine}:</b> Not available for this course
          </p>
        )

      letterGradeLabels.forEach(function (item, index) {
        var i = performance.columns.indexOf(item)
        if (i === -1) return
        data.push({
          grade: item,
          percent: Math.round((performance.values[i] / valueTotal) * 100),
        })
      })
      return (
        <div>
          <GraphWrapper>
            <b>
              {introLine} ({valueTotal} students){' '}
              <FontAwesomeIcon icon={faQuestionCircle} color="#aaa" />
            </b>
            <BarGraph
              data={data}
              barColor={PERFORMANCE_COLOR}
              layout="horizontal"
              xType="category"
              yType="number"
              xDataKey={'grade'}
              yDataKey={'percent'}
              barKey={'percent'}
              vLine={false}
              widthY={20}
            />
          </GraphWrapper>
        </div>
      )
    }
    return (
      <p>
        <b>{introLine}:</b> Not available for this course
      </p>
    )
  }

  renderCredit() {
    //TODO: generate eval graphs
    const { secondTitleLine, performance } = this.props

    const creditGradeLabels = ['CR', 'S', 'NC']
    const tempData = {}
    const data = []
    if (performance && performance.values) {
      const valueTotal = performance.values
        .filter(
          (a, i) => creditGradeLabels.indexOf(performance.columns[i]) !== -1
        )
        .reduce((a, b) => a + b, 0)

      // if no C/NC stats available
      if (valueTotal === 0)
        return (
          <p>
            <b>{secondTitleLine}:</b> Not available for this course
          </p>
        )

      creditGradeLabels.forEach(function (item, index) {
        var i = performance.columns.indexOf(item)
        if (i === -1) return
        tempData[item] = Math.round((performance.values[i] / valueTotal) * 100)
      })
      data.push({
        credit: 'CR/S',
        percent: tempData['CR'] + tempData['S'],
      })
      data.push({
        credit: 'NC',
        percent: tempData['NC'],
      })
      return (
        <GraphWrapper style={{ height: '200px', paddingBottom: '10px' }}>
          <p>
            <b>
              {secondTitleLine} ({valueTotal} students)
            </b>{' '}
            <FontAwesomeIcon icon={faQuestionCircle} color="#aaa" />{' '}
          </p>
          <BarGraph
            data={data}
            barColor={PERFORMANCE_COLOR}
            layout="vertical"
            xType="number"
            yType="category"
            xDataKey={'percent'}
            yDataKey={'credit'}
            barKey={'percent'}
            hLine={false}
            widthY={35}
          />
        </GraphWrapper>
      )
    }
    return (
      <p>
        <b>{secondTitleLine}:</b> Not available for this course
      </p>
    )
  }

  render() {
    let showGrades = false

    if (!showGrades) {
      return (
        <PerformanceWrapper>
          <Container>
            <Row>
              <Col lg={12}>
                <Alert variant="primary">
                  The Carta V2 team has been asked by the Stanford
                  Administration to remove the grade distribution feature from
                  the Carta platform. The V2 team is currently appealing this
                  decision and re-requesting access to the data.{' '}
                </Alert>
              </Col>
            </Row>
          </Container>
        </PerformanceWrapper>
      )
    }
    return (
      <PerformanceWrapper>
        <Container>
          <Row>
            <Col lg={12}>{this.renderGrades()}</Col>
          </Row>
          <hr></hr>
          <Row>
            <Col lg={12}>{this.renderCredit()}</Col>
          </Row>
        </Container>
      </PerformanceWrapper>
    )
  }
}

PerformanceCard.propTypes = {
  introLine: PropTypes.string,
  secondTitleLine: PropTypes.string,
}

function mapStateToProps(state) {
  return {
    performance: state.graphs.currentGraphs.performance,
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceCard)
