import React from 'react'
import api from '../api'
import { Button, Dropdown, ButtonGroup } from 'react-bootstrap'
import LoadingDots from '../Components/Miscellaneous/LoadingDots.js'

export function courseUnitDisplay(min_units, max_units) {
  // return unit display string
  // if min == max, just return a single number, otherwise retun min-max (3 - 4)
  return min_units === max_units ? min_units : min_units + '-' + max_units
}

// Since start and end times are strings from ExploreCourses, we have no
// guarantee of the format. This function parses a decent fraction
// of possible time formates into a Date object.
export function parseTimeToHHMM(t) {
  var d = new Date()
  var time = t.match(/(\d+)(?::(\d\d))?(?::\d\d)?\s*([pP]?)/)
  const addTimeConverter =
    time[3] === '' || (time[3] === 'P' && parseInt(time[1]) === 12) ? 0 : 12
  d.setHours(parseInt(time[1]) + addTimeConverter)
  d.setMinutes(parseInt(time[2]) || 0)
  return d.toLocaleTimeString('en-US', {
    hour: 'numeric',
    hour12: true,
    minute: 'numeric',
  })
}

// Get the season from a stanford term id.
export function getSeason(stanford_term_id) {
  switch (String(stanford_term_id)[3]) {
    case '2':
      return 'Autumn'
    case '4':
      return 'Winter'
    case '6':
      return 'Spring'
    case '8':
      return 'Summer'
    default:
      return 'Unknown'
  }
}

// Get the start year from a stanford term id.
// Assumes 21st century.
export function getStartYear(stanford_term_id) {
  let startYear = parseInt(String(stanford_term_id).substring(1, 3)) - 1
  return '20' + startYear.toString()
}

// Get end year from a stanford term id.
// Assumes 21st century.
export function getEndYear(stanford_term_id) {
  return '20' + String(stanford_term_id).substring(1, 3)
}

// Used to create the pinning menu that is used by CourseInfoPage
// and CourseResultCard.
export function createPinOptions(
  obj,
  availableSeasons,
  currentYearTerms,
  pinnedCourses,
  currTerm,
  course
) {
  let pinnedTermIds = []
  let enrolledTermIds = []

  for (var i = 0; i < pinnedCourses.length; ++i) {
    if (course.id === pinnedCourses[i].course.id) {
      if (pinnedCourses[i].type === 'enroll') {
        enrolledTermIds.push(pinnedCourses[i].term.id)
      }
      pinnedTermIds.push(pinnedCourses[i].term.id)
    }
  }

  if (!currentYearTerms || !currentYearTerms.size) {
    return (
      <div style={{ margin: '10px 0' }}>
        <Button variant="danger" disabled>
          Loading
          <LoadingDots />
        </Button>
      </div>
    )
  }

  let courseCode = course.code

  if (enrolledTermIds.indexOf(currTerm.id) !== -1) {
    return (
      <div style={{ margin: '10px 0' }}>
        <Button
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            border: 'rgba(0, 0, 0, 0.3)',
            color: '#000000',
          }}
          disabled
        >
          Enrolled
        </Button>
      </div>
    )
  }

  if (currTerm.end_year < currentYearTerms.entries().next().value[1].end_year) {
    return (
      <div style={{ margin: '10px 0' }}>
        <Button
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            border: 'rgba(0, 0, 0, 0.3)',
            color: '#000000',
          }}
          disabled
        >
          Cannot pin to {currTerm.start_year}&ndash;{currTerm.end_year}.
        </Button>
      </div>
    )
  }
  if (currTerm.end_year > currentYearTerms.entries().next().value[1].end_year) {
    if (pinnedTermIds.indexOf(currTerm.id) !== -1) {
      return (
        <div style={{ margin: '10px 0' }}>
          <Button
            variant="danger"
            style={{ width: '9em' }}
            key={courseCode}
            id={availableSeasons[0]}
            onClick={() => {
              let shortTermId = currTerm.stanford_term_id
              api.term.getByShortId(shortTermId).then((term) => {
                obj.unpinCourseClick(term)
              })
            }}
          >
            Unpin from {currTerm.season} {currTerm.start_year % 100}&mdash;
            {currTerm.end_year % 100}
          </Button>
        </div>
      )
    } else {
      return (
        <div style={{ margin: '10px 0' }}>
          <Button
            variant="danger"
            style={{ width: '9em' }}
            key={courseCode}
            id={availableSeasons[0]}
            onClick={() => {
              let shortTermId = currTerm.stanford_term_id
              api.term.getByShortId(shortTermId).then((term) => {
                obj.pinCourseClick(term)
              })
            }}
          >
            Plan for {currTerm.season} {currTerm.start_year % 100}&mdash;
            {currTerm.end_year % 100}
          </Button>
        </div>
      )
    }
  }
  if (!availableSeasons || availableSeasons.length === 0) {
    return (
      <div style={{ margin: '10px 0' }}>
        <Button
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            border: 'rgba(0, 0, 0, 0.3)',
            color: '#000000',
          }}
          disabled
        >
          Not offered
        </Button>
      </div>
    )
  }
  availableSeasons = availableSeasons.map((x) => x.toLowerCase())

  // reorder so the current term is first, if it exists
  if (availableSeasons.length > 1) {
    let currentSeasonIndex = availableSeasons.indexOf(
      currTerm.season.toLowerCase()
    )
    if (currentSeasonIndex !== -1) {
      let current = new Array(currTerm.season)
      let before = availableSeasons.slice(0, currentSeasonIndex)
      let after = availableSeasons.slice(currentSeasonIndex + 1)
      availableSeasons = current.concat(before).concat(after)
    }
  }

  // clone seasons Array and Sentence Case it for Button Display
  let availableSeasonsCased = availableSeasons.map((term, index, seasons) => {
    term = term.charAt(0).toUpperCase() + term.slice(1)
    return term
  })

  return (
    <div style={{ marginLeft: '10px' }}>
      {(() => {
        return (
          <Dropdown as={ButtonGroup}>
            {(() => {
              let term = currentYearTerms.get(availableSeasons[0].toLowerCase())
              if (pinnedTermIds.indexOf(term.id) !== -1) {
                return (
                  <Button
                    variant="danger"
                    style={{ width: '11em' }}
                    key={courseCode}
                    id={availableSeasons[0]}
                    onClick={() =>
                      obj.unpinCourseClick(
                        currentYearTerms.get(availableSeasons[0].toLowerCase())
                      )
                    }
                  >
                    Unpin from {availableSeasonsCased[0]}
                  </Button>
                )
              } else {
                return (
                  <Button
                    variant="danger"
                    style={{ width: '9em' }}
                    key={courseCode}
                    id={availableSeasons[0]}
                    onClick={() =>
                      obj.pinCourseClick(
                        currentYearTerms.get(availableSeasons[0].toLowerCase())
                      )
                    }
                  >
                    Pin to {availableSeasonsCased[0]}
                  </Button>
                )
              }
            })()}
            <>
              {(() => {
                // create dropdown menu only if more than term option exists
                if (availableSeasons.length > 1) {
                  return (
                    <>
                      <Dropdown.Toggle
                        split
                        variant="danger"
                        id="dropdown-split-basic"
                      />
                      <Dropdown.Menu alignRight>
                        {(() => {
                          let dropdowns = []
                          // start index from 1 because index 0 is already the primary button
                          for (let i = 1; i < availableSeasons.length; i++) {
                            let term = currentYearTerms.get(
                              availableSeasons[i].toLowerCase()
                            )
                            if (pinnedTermIds.indexOf(term.id) !== -1) {
                              dropdowns.push(
                                <Dropdown.Item
                                  key={i}
                                  id={availableSeasons[i]}
                                  disabled={
                                    enrolledTermIds.indexOf(term.id) !== -1
                                  }
                                  onClick={() => obj.unpinCourseClick(term)}
                                >
                                  {enrolledTermIds.indexOf(term.id) === -1
                                    ? `Unpin from ${availableSeasonsCased[i]}`
                                    : `Enrolled in ${availableSeasonsCased[i]}`}
                                </Dropdown.Item>
                              )
                            } else {
                              dropdowns.push(
                                <Dropdown.Item
                                  key={i}
                                  id={availableSeasons[i]}
                                  onClick={() => obj.pinCourseClick(term)}
                                >
                                  Pin to {availableSeasonsCased[i]}
                                </Dropdown.Item>
                              )
                            }
                          }
                          return dropdowns
                        })()}
                      </Dropdown.Menu>
                    </>
                  )
                }
              })()}
            </>
          </Dropdown>
        )
      })()}
    </div>
  )
}

export default {
  courseUnitDisplay,
  parseTimeToHHMM,
  createPinOptions,
  getSeason,
  getStartYear,
  getEndYear,
}
