import React, { Component } from 'react'
import styled from 'styled-components'
import Hero from '../LogoutPage/Hero/Hero'
import LogoutNavbar from '../LogoutPage/LogoutNavbar/LogoutNavbar'
import Footer from '../LandingPage/Footer/Footer'

const LogoutPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  img {
    max-width: 100%;
  }
`

/**
 * LogoutPage Formal.
 * Its components are essentially the same as LandingPage with some minor edits
 */
export default class LogoutPage extends Component {
  render() {
    return (
      <LogoutPageWrapper>
        <LogoutNavbar />
        <Hero />
        <Footer />
      </LogoutPageWrapper>
    )
  }
}
