import React from 'react'
import styled from 'styled-components'
import HeroImage from '../../../../assets/carta2.gif'
import HeroBackgroundImage from '../../../../assets/hero_background.svg'

const HeroWrapper = styled.div`
  padding: 30px 4vw 0px 4vw;
  text-align: center;

  @media only screen and (min-width: 768px) {
    padding: 96px 4vw 0px 4vw;
  }
`

const HeroFooter = styled.div`
  background-color: #212121;
  padding: 96px 4vw 101px 4vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`

const FooterComponent = styled.div`
  text-align: center;
  color: #fafafa;
  max-width: 270px;

  a {
    text-decoration: underline;
    color: #fafafa;
  }

  a:hover {
    text-decoration: none;
    color: #fafafa;
  }

  @media only screen and (min-width: 768px) {
    margin-right: 24px;
  }
`

const HeroHeader1 = styled.div`
  text-align: center;
  max-width: 700px;
  margin-bottom: 32px;
`

const HeroHeader2 = styled.div`
  text-align: center;
  max-width: 500px;
  margin-bottom: 64px;
`

const HeroH1 = styled.h1`
  font-weight: 600;
  line-height: 120%;
  font-size: 50px;
`

const HeroH2 = styled.h2`
  line-height: 120%;
  font-weight: normal;
  font-size: 25px;
`

const HeroImageWrapper = styled.img`
  width: 70vw;

  @media only screen and (max-width: 768px) {
    width: 90vw;
  }
`

export default function Hero() {
  return (
    <>
      <img style={{position: "absolute", top: 0, zIndex: -1, width: "100%"}}
        src={HeroBackgroundImage}
        alt="hero-background"
      />
      <HeroWrapper>
        <div
          style={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
          }}
        >
          <HeroHeader1>
            <HeroH1>Make the most of your Stanford Experience.</HeroH1>
          </HeroHeader1>
          <HeroHeader2>
            <HeroH2>
              Carta helps students make data-driven decisions to craft their
              educational paths.
            </HeroH2>
          </HeroHeader2>
          {/* <img src={HeroImage} alt="hero-img" style={{ minWidth: '300px' }} /> */}
          <HeroImageWrapper src={HeroImage} alt="hero-gif"/>
        </div>
      </HeroWrapper>
      <HeroFooter>
        <FooterComponent>
          <h1 style={{ fontSize: '50px', fontWeight: 600 }}>2016</h1>
          <p>
            Launched as a research project by the{' '}
            <a
              href="https://cartalab.stanford.edu/"
              target="_blank"
              rel="noopener noreferrer"
            >
              CartaLab
            </a>
          </p>
        </FooterComponent>
        <FooterComponent>
          <h1 style={{ fontSize: '50px', fontWeight: 600 }}>6</h1>
          <p>research papers by the CartaLab enabled by this platform</p>
        </FooterComponent>
        <FooterComponent>
          <h1 style={{ fontSize: '50px', fontWeight: 600 }}>30+</h1>
          <p>undergraduate engineers and designers over the past 4 years</p>
        </FooterComponent>
        <FooterComponent>
          <h1 style={{ fontSize: '50px', fontWeight: 600 }}>95%</h1>
          <p>of undergraduate students use Carta regularly</p>
        </FooterComponent>
      </HeroFooter>
    </>
  )
}
