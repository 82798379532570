export default {
  getPrimaryInstructors(course, currentTerm) {
    let seasons_offered = course.course.seasons_offered
    let offerings = course.course.offerings
    if (
      !seasons_offered ||
      seasons_offered.length === 0 ||
      !offerings ||
      offerings.length === 0
    ) {
      return
    }
    let combinedTerms = []
    let mapTermIdtoIndex = {}
    let countTerms = 0

    // combines multiple offerrings that are in the same term
    for (let i = 0; i < offerings.length; i++) {
      let curScheduleTerm = offerings[i].term.id
      if (curScheduleTerm in mapTermIdtoIndex) {
        // extend existing term schedules
        let index = mapTermIdtoIndex[curScheduleTerm]
        combinedTerms[index]['schedules'].push(...offerings[i].schedules)
      } else {
        // add new term schedules
        mapTermIdtoIndex[curScheduleTerm] = countTerms
        countTerms += 1
        let offering = {
          schedules: offerings[i].schedules,
          term: offerings[i].term,
        }
        combinedTerms.push(offering)
      }
    }

    let termInstructors = []
    combinedTerms.forEach((term) => {
      let primary_instructors = []
      term.schedules.forEach((schedule) => {
        if (
          schedule.primary_instructors &&
          schedule.primary_instructors.length > 0
        ) {
          for (let i = 0; i < schedule.primary_instructors.length; i++) {
            // check if instructor isn't present already
            if (
              !primary_instructors.includes(schedule.primary_instructors[i])
            ) {
              primary_instructors.push(schedule.primary_instructors[i])
            }
          }
        }
      })
      termInstructors.push({
        ...term.term,
        primary_instructors,
      })
    })
    return termInstructors
  },
}
