import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link, Redirect } from 'react-router-dom'
import SearchBar from './SearchBar.js'
import {
  Navbar,
  Form,
  Button,
  OverlayTrigger,
  Popover,
  Dropdown,
  DropdownButton,
} from 'react-bootstrap'
import carta_logo from '../../assets/stanford_carta_logo_white.svg'
import { NAVBAR_BKGRND_COLOR } from '../../assets/constant_colors'
import {
  FONT_WEIGHT,
  TABLET_THRESHOLD,
  MOBILE_THRESHOLD,
} from '../../assets/constant_sizes'
import { MIN_NUM_SEARCH_LETTERS } from '../../assets/constant_sizes.js'
import { ADVISING_URL } from '../../assets/constant_strings'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faBars, faList } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import { toggleSidePanel } from '../../assets/actions/pinningActions'
import { search } from '../../assets/actions/searchActions'
import api from '../../api'
import CatalogDropdown from './CatalogDropdown.js'

const NavWrapper = styled.div`
  & .navbar.navbar-expand.navbar-light {
    background: ${NAVBAR_BKGRND_COLOR};
    padding: 0 30px 0 30px;
    height: 60px;
  }

  & .navbar-light .navbar-text {
    color: white;
    font-weight: ${FONT_WEIGHT};
    font-size: 22px;
  }

  & .btn.btn-primary {
    margin: 0.5rem;
    width: 100%;
    text-align: left;
  }

  & .btn.btn-success {
    margin: 0.5rem;
  }

  & .dropright #basic-nav-dropdown:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }
`

const SearchWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 450px;

  & a:hover {
    text-decoration: none;
  }

  & input {
    height: 36px;
    width: 100%;
    margin-bottom: 0;
    padding: 1rem 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  & .downshift-dropdown {
    margin: 0 auto;
    border: 1px solid #ccc;
    border-bottom: none;
    border-radius: 4px;
    background-color: white;
    position: absolute;
  }

  & .dropdown-item {
    padding: 3px, 20px;
    cursor: pointer;
    border-bottom: 1px solid whitesmoke;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    text-align: left;
  }

  & .dropdown-button {
    padding: 0.6rem;
    border-radius: 3px;
    background: white;
    cursor: pointer;
  }

  & .popper-div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
  }

  & .popper-item {
    padding: 0.5rem;
    border-bottom: 1px solid whitesmoke;
  }

  & .dropdown {
    color: black !important;
  }
`

const CatalogWrapper = styled.div`
  & .btn.btn-primary {
    margin: 0;
  }

  & .btn-primary {
    background-color: white;
    color: black;
    border-color: white;
  }

  & .btn-primary:disabled {
    background-color: white;
    color: black;
    border-color: white;
    opacity: 1;
    cursor: pointer;
  }

  & .show > .btn-primary.dropdown-toggle {
    background-color: grey;
    color: black;
    border-color: white;
  }

  & .dropright:hover button {
    border-color: ${NAVBAR_BKGRND_COLOR};
    background-color: ${NAVBAR_BKGRND_COLOR};
    color: white;
  }

  & .dropright:hover .dropdown-menu {
    margin: -1px;
    display: block !important;
    max-height: 500px;
    overflow-y: auto;
  }

  & .dropright:hover .dropdown-menu .dropdown-item:hover {
    border-color: ${NAVBAR_BKGRND_COLOR};
    background-color: ${NAVBAR_BKGRND_COLOR};
    color: white;
  }
`

class NavBar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      searchEntry: '',
      toSearchResults: false,
      catalog: {},
      searchDropDown: false,
    }
    this.updateSearchEntry = this.updateSearchEntry.bind(this)
    this.goToSearchResults = this.goToSearchResults.bind(this)
  }

  updateSearchEntry(newInput) {
    this.setState({
      searchEntry: newInput,
    })
    if (newInput.length < MIN_NUM_SEARCH_LETTERS) return
  }

  goToSearchResults() {
    this.setState({
      toSearchResults: true,
    })
  }

  redir() {
    if (this.state.toSearchResults === true) {
      this.setState({
        toSearchResults: false,
      })
      return (
        <Redirect
          to={'/results/' + encodeURIComponent(this.state.searchEntry)}
          push
        />
      )
    }
  }

  componentDidMount() {
    api.catalog.get().then((data) => {
      this.setState({ catalog: data })
    })
  }

  renderCatalog() {
    const { catalog } = this.state
    if (catalog.length > 0) {
      return catalog.map((value, index) => (
        <CatalogDropdown
          key={index}
          title={value.description}
          index={index}
          children={value}
        />
      ))
    }
  }

  renderDesktopMenu() {
    const { loginID, windowWidth } = this.props
    return (
      <Navbar fixed="top">
        {this.redir()}
        <Navbar.Brand style={{ marginRight: '100px' }}>
          <Link to={'/'}>
            <img
              alt="Stanford Carta"
              src={carta_logo}
              height="30"
              className="d-inline-block align-top"
              style={{ margin: '0 8px' }}
            />
          </Link>
        </Navbar.Brand>

        <DropdownButton
          variant="outline-light"
          title={
            <span>
              <FontAwesomeIcon icon={faList} /> Catalog
            </span>
          }
          id="basic-nav-dropdown-id"
          style={{ marginRight: '20px' }}
        >
          <CatalogWrapper>{this.renderCatalog()}</CatalogWrapper>
        </DropdownButton>

        <Form inline style={{ width: '100%', flexFlow: 'row nowrap' }}>
          <SearchWrapper windowWidth={windowWidth}>
            <SearchBar
              updateEntryCallback={this.updateSearchEntry}
              alertParentEnterPressed={this.goToSearchResults}
              searchEntry={this.state.searchEntry}
            />
          </SearchWrapper>
          {(() => {
            if (this.state.searchEntry === '') {
              return (
                <Button style={{ marginLeft: '5px' }} variant="danger">
                  <FontAwesomeIcon icon={faSearch} />
                </Button>
              )
            } else {
              return (
                <Link
                  to={'/results/' + encodeURIComponent(this.state.searchEntry)}
                >
                  <Button style={{ marginLeft: '5px' }} variant="danger">
                    <FontAwesomeIcon icon={faSearch} />
                  </Button>
                </Link>
              )
            }
          })()}
          <Link to={'/planner'}>
            <Button
              variant="outline-light"
              onClick={this.props.removeSidePanel}
              style={{ marginLeft: '20px' }}
            >
              Planner
            </Button>
          </Link>
          <Button
            style={{ marginLeft: '20px' }}
            variant="outline-light"
            href={ADVISING_URL}
            target="_blank"
          >
            Advising
          </Button>
        </Form>

        <Navbar.Collapse
          className="justify-content-end"
          style={{ marginLeft: '20px' }}
        >
          <Navbar.Text>{loginID}</Navbar.Text>
          <Button
            style={{ marginLeft: '20px' }}
            variant="outline-light"
            onClick={this.props.logout}
            target="_blank"
          >
            Logout
          </Button>
        </Navbar.Collapse>
      </Navbar>
    )
  }

  renderMobileMenu() {
    const { windowWidth } = this.props
    return (
      <Navbar fixed="top">
        {this.redir()}
        {/* TODO: link to redux store and connect to show sidebar*/}

        <Button
          onClick={this.props.toggleSidePanel}
          style={{ marginRight: '10px' }}
          variant="danger"
        >
          <FontAwesomeIcon icon={faBars} />
        </Button>
        <Navbar.Brand>
          <Link to={'/'}>
            <img
              alt="Carta"
              src={carta_logo}
              onClick={this.props.addSidePanel}
              height="30"
              className="d-inline-block align-top"
            />
          </Link>
        </Navbar.Brand>

        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
          }}
        >
          {/* needs to be fixed. temporary solution thats a bit buggy */}
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            overlay={
              <Popover style={{ width: '100%', minWidth: '200px' }}>
                <Popover.Content>
                  <Form
                    inline
                    style={{
                      width: '100%',
                      flexFlow: 'row nowrap',
                    }}
                  >
                    <SearchWrapper windowWidth={windowWidth}>
                      <SearchBar
                        updateEntryCallback={this.updateSearchEntry}
                        alertParentEnterPressed={this.goToSearchResults}
                        searchEntry={this.state.searchEntry}
                      />
                    </SearchWrapper>
                    {(() => {
                      if (this.state.searchEntry === '') {
                        return (
                          <Button
                            style={{ marginLeft: '5px' }}
                            variant="danger"
                          >
                            <FontAwesomeIcon icon={faSearch} />
                          </Button>
                        )
                      } else {
                        return (
                          <Link
                            to={
                              '/results/' +
                              encodeURIComponent(this.state.searchEntry)
                            }
                          >
                            <Button
                              style={{ marginLeft: '5px' }}
                              variant="danger"
                            >
                              <FontAwesomeIcon icon={faSearch} />
                            </Button>
                          </Link>
                        )
                      }
                    })()}
                  </Form>
                </Popover.Content>
              </Popover>
            }
          >
            <Button variant="danger">
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </OverlayTrigger>
        </div>
      </Navbar>
    )
  }
  renderTabletMenu() {
    const { windowWidth } = this.props
    return (
      <Navbar fixed="top">
        {this.redir()}
        <Button
          onClick={this.props.toggleSidePanel}
          style={{ marginRight: '10px' }}
          variant="danger"
        >
          <FontAwesomeIcon icon={faBars} />
        </Button>
        <Navbar.Brand style={{ marginRight: '100px' }}>
          <Link to={'/'}>
            <img
              alt="Stanford Carta"
              src={carta_logo}
              height="30"
              className="d-inline-block align-top"
              style={{ margin: '0 8px' }}
            />
          </Link>
        </Navbar.Brand>

        <Form inline style={{ width: '100%', flexFlow: 'row nowrap' }}>
          <SearchWrapper windowWidth={windowWidth}>
            <SearchBar
              updateEntryCallback={this.updateSearchEntry}
              alertParentEnterPressed={this.goToSearchResults}
              searchEntry={this.state.searchEntry}
            />
          </SearchWrapper>
          {(() => {
            if (this.state.searchEntry === '') {
              return (
                <Button style={{ marginLeft: '5px' }} variant="danger">
                  <FontAwesomeIcon icon={faSearch} />
                </Button>
              )
            } else {
              return (
                <Link
                  to={'/results/' + encodeURIComponent(this.state.searchEntry)}
                >
                  <Button style={{ marginLeft: '5px' }} variant="danger">
                    <FontAwesomeIcon icon={faSearch} />
                  </Button>
                </Link>
              )
            }
          })()}
        </Form>
      </Navbar>
    )
  }
  render() {
    const { windowWidth } = this.props
    return (
      <NavWrapper>
        {windowWidth < TABLET_THRESHOLD
          ? windowWidth > MOBILE_THRESHOLD
            ? this.renderTabletMenu()
            : this.renderMobileMenu()
          : this.renderDesktopMenu()}
      </NavWrapper>
    )
  }
}

NavBar.propTypes = {
  loginID: PropTypes.string,
  handleSearch: PropTypes.func,
}

function mapStateToProps(state) {
  return {}
}

const mapDispatchToProps = {
  toggleSidePanel,
  search,
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)
