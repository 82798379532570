import api from '../../api'

export const FETCH_SUNETID_BEGIN = 'FETCH_SUNETID_BEGIN'
export const FETCH_SUNETID_SUCCESS = 'FETCH_SUNETID_SUCCESS'
export const FETCH_SUNETID_FAILURE = 'FETCH_SUNETID_FAILURE'

export const fetchSunetIdBegin = () => ({
  type: FETCH_SUNETID_BEGIN,
})

export const fetchSunetIdSuccess = data => ({
  type: FETCH_SUNETID_SUCCESS,
  payload: { data },
})

export const fetchSunetIdFailure = error => ({
  type: FETCH_SUNETID_FAILURE,
  payload: { error },
})

export function fetchSunetId() {
  return dispatch => {
    dispatch(fetchSunetIdBegin())

    api.user.get().then(id => {
      dispatch(fetchSunetIdSuccess(id))
    })
  }
}
