import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import GlanceSched from '../../Tools/GlanceSched'
import LoadingDots from '../Miscellaneous/LoadingDots'
import ReadMoreAndLess from 'react-read-more-less'
import { createPinOptions, courseUnitDisplay } from '../../Tools/utils.js'
import { pinCourse, unpinCourse } from '../../assets/actions/pinningActions'
import { connect } from 'react-redux'

const ResultsCardWrapper = styled.div`
  margin-left: 15px;
  margin-right: 15px;

  & .course_title {
    font-size: 20px;
    letter-spacing: 0.43px;
    font-weight: 500;
  }

  & button {
    float: right;
  }

  & .pseudo_link {
    color: #007bff;
    cursor: pointer;
  }

  & .pseudo_link:hover {
    text-decoration: underline;
  }

  & .heavy_font {
    font-weight: 500;
    font-size: 15px;
  }

  & .col-lg-9,
  .col-lg-7 {
    padding: 0;
  }
`

const CourseTitle = styled.div`
  font-size: 20px;
  letter-spacing: 0.43px;
  font-weight: 500;
`

const CourseData = styled.div`
  & p {
    margin: 0;
  }
`

class CourseResultCard extends Component {
  constructor(props) {
    super(props)
    if (!this.props.course.course_code && this.props.course.codes) {
      this.props.course.course_code = this.props.course.codes
    } else if (!this.props.course.course_code) {
      this.props.course.course_code = '' // TODO: Handle courses with null course codes
    }

    this.state = {
      openSchedule: false,
      course: this.props.course,
    }
    this.toggleSchedule = this.toggleSchedule.bind(this)
    this.pinCourseClick = this.pinCourseClick.bind(this)
  }

  toggleSchedule() {
    this.setState((state) => ({
      openSchedule: !state.openSchedule,
    }))
  }

  componentDidUpdate() {
    if (this.props.course !== this.state.course) {
      this.setState({
        course: this.props.course,
      })
    }

    if (!this.props.course.course_code && this.props.course.codes) {
      this.props.course.course_code = this.props.course.codes //I am confused by what is going on here
    } else if (!this.props.course.course_codes) {
      this.props.course.course_codes = ['UNKNOWN']
    }
  }

  // changeSeasons(){
  //     let newCourse = this.props.course;
  //     let seasons = newCourse.seasons_offered;
  //     const converter = {
  //         "winter": "WIN",
  //         "summer": "SUM",
  //         "spring": "SPR",
  //         "autumn": "AUT"
  //     };

  //     for (var i = 0; i < seasons.length; i++){
  //         seasons[i] = converter[seasons[i]];
  //     }

  //     newCourse.seasons_offered = seasons;

  //     this.setState({
  //         course: newCourse,
  //     });
  // }

  componentDidMount() {
    //this.changeSeasons();
  }

  getTerms() {
    const raw_seasons = this.state.course.seasons_offered
    let shortenedSeason = {
      autumn: 'AUT',
      winter: 'WIN',
      spring: 'SPR',
      summer: 'SUM',
    }
    if (!raw_seasons || raw_seasons.length === 0) {
      return <span>Not Available</span>
    }
    // Map autumn => AUT etc.
    let seasons = raw_seasons.map((season) => shortenedSeason[season])

    // create custom ordering map based on sequence of quarters (non-alphabetical)
    let ordering = {}
    let sortOrder = ['AUT', 'WIN', 'SPR', 'SUM']
    for (let i = 0; i < sortOrder.length; i++) {
      ordering[sortOrder[i]] = i
    }
    seasons.sort(function (season1, season2) {
      return ordering[season1] - ordering[season2]
    })

    let terms = seasons.join(', ')
    return <span>{terms}</span>
  }

  getInstructorList() {
    // Extract at most 3 instructors from all schedules
    let instructorSet = new Set()
    let result = []

    let offerings = this.state.course.offerings
    if (offerings) {
      for (let i = 0; i < offerings.length; i++) {
        let schedules = offerings[i].schedules
        if (schedules) {
          for (let j = 0; j < schedules.length; j++) {
            let instructors = schedules[j].instructors
            if (instructors !== undefined) {
              // instructors promised to exist in model
              instructors.forEach((instructor) => {
                // "last name, first letter of first name"
                if (instructorSet.has(instructor.name)) {
                  return
                }
                instructorSet.add(instructor.name)
                let full_name = instructor.name.split(' ')
                result.push(full_name[1] + ', ' + full_name[0].charAt(0) + '.')
              })
              // early break, only display at most 3 instructors
              if (instructorSet.length >= 3) return result.join(', ')
            }
          }
        }
      }
    }

    return (
      <ReadMoreAndLess
        className="read-more-content"
        charLimit={200}
        readMoreText={'Read more'}
        readLessText={'Read less'}
      >
        {result.join(', ')}
      </ReadMoreAndLess>
    )
  }

  getData() {
    let display_unit = courseUnitDisplay(
      this.state.course.min_units,
      this.state.course.max_units
    )
    let allCourseInstructors = this.getInstructorList()
    return (
      <CourseData>
        <div>
          <strong>Term:</strong> {this.getTerms()}
        </div>
        <div>
          <strong>Units:</strong> {display_unit}
        </div>
        <div>
          <strong>Grading:</strong> {this.state.course.grading}
        </div>
        <div>
          <strong>Instructors:</strong> {allCourseInstructors}{' '}
        </div>
      </CourseData>
    )
  }

  getPinOptions() {
    const { currentYearTerms, term, pinnedCourses } = this.props
    const { course } = this.state
    var availableSeasons = course.seasons_offered
    return createPinOptions(
      this,
      availableSeasons,
      currentYearTerms,
      pinnedCourses,
      term,
      course
    )
  }

  getDefaultCourseCode() {
    return this.state.course.course_codes[0]
  }

  getAlternateCourseCodes() {
    let alternateCourseCodes = []
    this.state.course.course_codes.map((course_code, index) => {
      if (index > 0) {
        alternateCourseCodes.push(course_code)
      }
      return null
    })
    return alternateCourseCodes
  }

  pinCourseClick(term) {
    const { course } = this.props
    const data = {
      course_id: course.id,
      term_id: term.id,
      type: 'pin',
    }
    this.props.pinCourse(data)
  }

  unpinCourseClick(term) {
    const { pinnedCourses } = this.props
    const { course } = this.state
    let course_idx = -1
    for (let i = 0; i < pinnedCourses.length; ++i) {
      if (
        pinnedCourses[i].course.id === course.id &&
        pinnedCourses[i].term.id === term.id
      ) {
        course_idx = i
        break
      }
    }

    if (course_idx !== -1) {
      this.props.unpinCourse(course_idx)
    }
  }

  renderSchedule() {
    const { openSchedule } = this.state
    if (openSchedule) {
      return <GlanceSched course={this.state.course} />
    } else if (openSchedule) {
      return (
        <i>
          Loading schedule information
          <LoadingDots />
        </i>
      )
    }
  }

  render() {
    return (
      <ResultsCardWrapper className="search-result-card">
        <Row
          className="align-items-center"
          style={{ justifyContent: 'space-between', flexWrap: 'nowrap' }}
        >
          <div>
            <CourseTitle>
              {(() => {
                // compose link url and course title, including any alternate course codes
                let link = `/course/${this.getDefaultCourseCode()}/${
                  this.props.term.stanford_term_id
                }`
                let courseTitle = `${this.getDefaultCourseCode()}: ${
                  this.state.course.title
                }`
                let alternateCourseCodes = this.getAlternateCourseCodes()
                if (alternateCourseCodes.length > 0) {
                  courseTitle += ` (${alternateCourseCodes.join(', ')})`
                }
                return (
                  <Link to={link} style={{ color: '#3e8acc' }}>
                    {courseTitle}
                  </Link>
                )
              })()}
            </CourseTitle>
          </div>
          <div>{this.getPinOptions()}</div>
        </Row>
        <Row>
          <Col
            lg={7}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <ReadMoreAndLess
              className="read-more-content"
              charLimit={200}
              readMoreText={'Read more'}
              readLessText={'Read less'}
            >
              {this.state.course.description}
            </ReadMoreAndLess>
            <div className="pseudo_link">
              <p onClick={this.toggleSchedule} style={{ color: '#3e8acc' }}>
                {this.state.openSchedule ? 'Hide Schedule' : 'Show Schedule'}
              </p>
            </div>
          </Col>
          <Col lg={5}>{this.getData()}</Col>
        </Row>
        {
          <Row>
            <Col lg={12} style={{ padding: 0 }}>
              {this.renderSchedule()}
            </Col>
          </Row>
        }
        <hr />
      </ResultsCardWrapper>
    )
  }
}

function mapStateToProps(state) {
  return {
    pinnedCourses: state.pinnedCourses.pinnedCourses,
    term: state.term.term,
    currentYearTerms: state.term.currentYearTerms,
  }
}

const mapDispatchToProps = {
  pinCourse,
  unpinCourse,
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseResultCard)
