import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import ReadMoreAndLess from 'react-read-more-less'
import Image from 'react-bootstrap/Image'
import instructorPhotoPlaceHolder from '../../assets/instructorPhoto.png'

const ResultsCardWrapper = styled.div`
  margin-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
`

const InstructorName = styled.div`
  font-size: 20px;
  letter-spacing: 0.43px;
  font-weight: 500;
  cursor: pointer;
  color: #3e8acc;
`

const JobTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  padding-vertical: 5px;
`

const ImageBox = styled.div`
  margin: 0;
  padding-horizontal: 10;
`

const Bio = styled.div`
  padding: 5px 0px;
  font-size: 14px;
  color: #606060;
`

class InstructorResultCard extends Component {
  render() {
    const instructor = this.props.instructor
    //placeholder if no url provided
    let profile_picture_url =
      instructor.profile_picture_url.length > 0
        ? instructor.profile_picture_url
        : instructorPhotoPlaceHolder

    return (
      <ResultsCardWrapper>
        <Row>
          <Col xs={2}>
            <ImageBox>
              <Image
                src={profile_picture_url}
                roundedCircle
                style={{ width: '100%', height: '100%' }}
              />
            </ImageBox>
          </Col>
          <Col xs={10}>
            <Row>
              <Link to={'/instructor/' + instructor.username}>
                <InstructorName>{instructor.name}</InstructorName>
              </Link>
            </Row>
            <Row>
              <JobTitle>{instructor.job_title}</JobTitle>
            </Row>
            <Row>
              <Bio>
                <ReadMoreAndLess
                  className="read-more-content"
                  charLimit={200}
                  readMoreText={'Read more'}
                  readLessText={'Read less'}
                >
                  {instructor.bio}
                </ReadMoreAndLess>
              </Bio>
            </Row>
          </Col>
        </Row>
      </ResultsCardWrapper>
    )
  }
}

export default InstructorResultCard
