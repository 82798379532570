import React, { Component } from 'react'
import styled from 'styled-components'
import CourseInfoPage from '../Pages/CourseInfoPage'
import HomePage from '../Pages/HomePage'
import AboutPage from './AboutPage/AboutPage'
import DataDefinitionPage from '../Pages/DataDefinitionPage'
import { SIDE_PANEL_WIDTH, THRESHOLD } from '../../assets/constant_sizes.js'
import SidePanel from './SidePanel'
import { Route, Switch } from 'react-router-dom'
import SearchResultsPage from './SearchResultsPage/SearchResultsPage'
import InstructorInfoPage from './InstructorInfoPage'
import PlannerPage from './planner/PlannerPage'
import Feedback from './Feedback'
import ErrorPage from './ErrorPage/ErrorPage'
import FAQ from './FAQPage'

const MainWrapper = styled.div`
  & .container {
    margin-left: ${(props) =>
      props.threshold > THRESHOLD && props.showMe ? SIDE_PANEL_WIDTH : '0px'};
    padding: 0px 20px 20px 20px;
    width: auto;
  }

  padding-top: 60px;
`

class MainPanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showSidePanel: this.props.showSidePanel,
    }
  }
  componentDidUpdate() {
    if (this.props.showSidePanel !== this.state.showSidePanel) {
      this.setState({
        showSidePanel: this.props.showSidePanel,
      })
    }
  }

  render() {
    const { windowWidth } = this.props
    return (
      <MainWrapper threshold={windowWidth}>
        <Switch>
          <Route
            path="/course/:courseCode/:termId"
            render={(props) => (
              <SidePanel
                {...props}
                windowWidth={windowWidth}
                showMe={this.state.showSidePanel}
              />
            )}
          />
          <Route
            path="/course/:courseCode"
            render={(props) => (
              <SidePanel
                {...props}
                windowWidth={windowWidth}
                showMe={this.state.showSidePanel}
              />
            )}
          />
          <Route
            path="/landing/:termId"
            render={(props) => (
              <SidePanel
                {...props}
                windowWidth={windowWidth}
                showMe={this.state.showSidePanel}
              />
            )}
          />
          <Route
            render={() => (
              <SidePanel
                windowWidth={windowWidth}
                showMe={this.state.showSidePanel}
              />
            )}
          />
        </Switch>
        <Feedback />
        <Switch>
          <Route
            path="/course/:courseCode"
            render={(props) => (
              <CourseInfoPage
                {...props}
                threshold={windowWidth}
                addSidePanel={this.props.addSidePanel}
              />
            )}
          />
          <Route
            path="/results/:query"
            render={(props) => (
              <SearchResultsPage
                {...props}
                threshold={windowWidth}
                addSidePanel={this.props.addSidePanel}
              />
            )}
          />
          <Route
            path="/instructor/:instructorId"
            render={(props) => (
              <InstructorInfoPage
                {...props}
                threshold={windowWidth}
                addSidePanel={this.props.addSidePanel}
              />
            )}
          />
          <Route
            path="/planner"
            render={(props) => (
              <PlannerPage
                {...props}
                removeSidePanel={this.props.removeSidePanel}
              />
            )}
          />
          <Route
            path="/landing/:termId"
            render={(props) => (
              <HomePage
                {...props}
                threshold={windowWidth}
                addSidePanel={this.props.addSidePanel}
              />
            )}
          />
          <Route
            exact
            path="/about"
            render={() => (
              <AboutPage
                threshold={windowWidth}
                addSidePanel={this.props.addSidePanel}
              />
            )}
          />
          <Route
            exact
            path="/data_definition"
            render={() => (
              <DataDefinitionPage
                threshold={windowWidth}
                addSidePanel={this.props.addSidePanel}
              />
            )}
          />
          <Route
            exact
            path="/FAQPage"
            render={() => (
              <FAQ
                threshold={windowWidth}
                addSidePanel={this.props.addSidePanel}
              />
            )}
          />
          <Route
            exact
            path="/"
            render={() => (
              <HomePage
                threshold={windowWidth}
                addSidePanel={this.props.addSidePanel}
              />
            )}
          />
          <Route
            render={() => (
              <ErrorPage
                isLoggedIn={true}
                removeSidePanel={this.props.removeSidePanel}
              />
            )}
          />
        </Switch>
      </MainWrapper>
    )
  }
}

export default MainPanel
