import {
  FETCH_PINNED_COURSES_BEGIN,
  FETCH_PINNED_COURSES_SUCCESS,
  FETCH_PINNED_COURSES_FAILURE,
  PIN_COURSE_BEGIN,
  PIN_COURSE_SUCCESS,
  PIN_COURSE_FAILURE,
  UNPIN_COURSE_BEGIN,
  UNPIN_COURSE_SUCCESS,
  UNPIN_COURSE_FAILURE,
  TOGGLE_SIDEPANEL,
  UPDATE_PIN_FAILURE,
  UPDATE_PIN_SUCCESS,
  UPDATE_PIN_BEGIN,
} from '../actions/pinningActions'

const initialState = {
  pinnedCourses: [],
  SUNetID: '',
  loading: false,
  error: null,
  showSidePanel: false,
}

export default function pinningReducer(state = initialState, action) {
  let newPinnedCourses = state.pinnedCourses.slice()

  switch (action.type) {
    case UNPIN_COURSE_BEGIN:
    case PIN_COURSE_BEGIN:
    case FETCH_PINNED_COURSES_BEGIN:
    case UPDATE_PIN_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case FETCH_PINNED_COURSES_SUCCESS:
      return {
        ...state,
        loading: false,
        pinnedCourses: action.payload.data,
      }

    case PIN_COURSE_SUCCESS:
      newPinnedCourses.push(action.payload.data)
      return {
        ...state,
        loading: false,
        pinnedCourses: newPinnedCourses,
      }

    case UNPIN_COURSE_SUCCESS:
      newPinnedCourses.splice(action.payload.data, 1)
      return {
        ...state,
        loading: false,
        pinnedCourses: newPinnedCourses,
      }

    case UPDATE_PIN_SUCCESS:
      newPinnedCourses = newPinnedCourses.map(pin => {
        if (pin.id === action.payload.data.id) {
          return action.payload.data
        }
        return pin
      })
      return {
        ...state,
        loading: false,
        pinnedCourses: newPinnedCourses,
      }

    case UNPIN_COURSE_FAILURE:
    case PIN_COURSE_FAILURE:
    case FETCH_PINNED_COURSES_FAILURE:
    case UPDATE_PIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    case TOGGLE_SIDEPANEL: // move to general
      return {
        ...state,
        showSidePanel: !state.showSidePanel,
      }

    default:
      return state
  }
}
