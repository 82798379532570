import React, { Component } from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'
import api from '../../api'
import GeneralCard from '../Cards/GeneralCard'
import Image from 'react-bootstrap/Image'
import { CARD_TYPES } from '../../assets/constant_strings'
import { SIDE_PANEL_WIDTH, THRESHOLD } from '../../assets/constant_sizes.js'

const InstructorInfoWrapper = styled.div`
  margin-left: ${props =>
    props.threshold > THRESHOLD ? SIDE_PANEL_WIDTH : '0px'};
  & .btn.btn-primary {
    margin-left: 10px;
  }

  & .page-header {
    margin: 0 15px 0 0;
    padding: 0;
  }

  & .col-lg-6.col-md-12.col-xs-12 {
    padding-left: 0;
  }

  & .col-lg-12.col-md-12.col-xs-12 {
    padding-left: 0;
  }

  h1 {
    margin-bottom: 20px;
  }

  .boldMe {
    font-weight: bold;
  }

  #instructor_info {
    padding: 10px 0;
    margin-top: 1rem;
  }

  .name {
    font-size: 20px;
    color: rgb(0, 0, 0);
    display: block;
  }

  .title {
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 500;
    font-size: 14px;
    color: rgb(0, 0, 0);
    letter-spacing: 0.02px;
  }

  .profile {
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 500;
    font-size: 14px;
    color: rgb(0, 0, 0);
    letter-spacing: 0.02px;
  }

  .profile_picture {
    border-radius: 50%;
    padding-bottom: 5%;
  }
`

const CardWrapper = styled.div`
  padding-top: 30px;
`

const ImageBox = styled.div`
  margin: 0;
  padding-horizontal: 10;
`

class InstructorInfoPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currInstructorId: -1,
      data: {
        instructor: undefined,
        courses: undefined,
      },
      showFullDescription: false,
    }
  }

  updateInstructorInfo() {
    const routedInstructor = this.props.match.params.instructorId
    if (this.state.currInstructorId !== routedInstructor) {
      api.instructor.get(routedInstructor).then(instructor => {
        this.setState({
          data: {
            ...this.state.data,
            instructor: instructor,
          },
          currInstructorId: routedInstructor,
        })
      })
      api.instructor.getCourses(routedInstructor).then(courses => {
        this.setState({
          data: {
            ...this.state.data,
            courses: courses,
          },
        })
      })
    }
  }

  componentDidMount() {
    this.updateInstructorInfo()
    this.props.addSidePanel()
  }

  componentDidUpdate() {
    if (
      this.state.currInstructorId !== this.props.match.params.instructorId &&
      this.state.currInstructorId !== -1
    ) {
      this.updateInstructorInfo()
    }
  }

  render() {
    const { threshold } = this.props
    const { instructor, courses } = this.state.data
    let years = []
    if (courses) {
      const endYears = Array.from(new Set(
        courses.map(course => course.terms.map(term => term.end_year)).flat()
      )).sort().reverse()

      years = []
      for (let i = 0; i < endYears.length; i++) {
        let endYear = endYears[i]
        years.push({
          title: `${endYear - 1}-${endYear} Courses`,
          // Deep copy necessary since we will modify terms.
          courses: JSON.parse(JSON.stringify(
            courses.filter(course =>
            course.terms.some(term => term.end_year === endYear))
          )),
        })
        // Update terms in courses assigned to each year. Otherwise, courses from
        // previous years may show up in the incorrect year.
        let courses_len = years[i].courses.length
        for (let j = 0; j < courses_len; j++) {
          years[i].courses[j].terms = years[i].courses[j].terms.filter(
            term => term.end_year === endYear
          )
        }
      }
    }
    return (
      <InstructorInfoWrapper threshold={threshold}>
        <Container>
          <Row id="instructor_info">
            <Col md={4}>
              <ImageBox>
                <Image
                  src={instructor ? instructor.profile_picture_url : ''}
                  roundedCircle
                  style={{ width: '100%', height: '100%' }}
                />
              </ImageBox>
            </Col>
            <Col md={8}>
              <div className="name">
                {instructor ? instructor.name : 'Loading...'}
              </div>
              <div className="title">
                {instructor ? instructor.job_title : 'Loading...'}
              </div>
              <div className="profile">
                <a
                  href={instructor ? instructor.profile_url : ''}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  INSTRUCTOR PROFILE
                </a>
              </div>
              <div className="description">
                {instructor ? instructor.bio : 'Loading...'}
              </div>
            </Col>
          </Row>
          <CardWrapper>
            <Row>
              <Col xs={12} md={12} lg={12}>
                {years.map(year => {
                  return (
                    <GeneralCard
                      cardType={CARD_TYPES.COURSES_LIST}
                      cardTitle={year.title}
                      courses={year && year.courses}
                    />
                  )
                })}
              </Col>
            </Row>
          </CardWrapper>
        </Container>
      </InstructorInfoWrapper>
    )
  }
}

export default InstructorInfoPage
