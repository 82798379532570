import React from 'react'
import styled from 'styled-components'
import Review from './Review'

const ReviewsWrapper = styled.div`
  background-color: #b71c1c;
  color: #fafafa;
  ${'' /* padding-top: 96px; */}
  padding: 96px 10px 0 10px;
  display: flex;
  flex-flow: column;
  align-items: center;
`

const ReviewHeader = styled.div`
  text-align: center;
  max-width: 564px;
  margin: auto;
  margin-bottom: 96px;
`

const ReviewBody = styled.div`
  padding: 0 20px 0 40px;
  max-width: 1500px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 105.5px;

  ${'' /* TODO: Change the padding when mobile */}
  @media only screen and (min-width: 768px) {
    padding: 0 90px;
  }
`

export default function Reviews() {
  return (
    <ReviewsWrapper>
      <ReviewHeader>
        <h1 style={{ fontWeight: 600, fontSize: '50px' }}>
          Students love what we're doing.
        </h1>
      </ReviewHeader>
      <ReviewBody>
        <Review
          text={[
            "\"My [older] sister showed me Carta. It broke down the amount of work that [each class] would be, and then I was like, 'okay this is manageable. '",
            <strong key={1}>I think I can plan classes on my own.</strong>,
            '"',
          ]}
          width={678}
        />
        <Review
          text={[
            '"[Carta] is a very ',
            <strong key={2}>vital part to my life decisions</strong>,
            '. Every time I don\'t plan out a quarter fully [with Carta] I regret it after."',
          ]}
          width={482}
        />
        <Review
          text={[
            '"I’m actually using Carta to narrow in on the courses that I think are going to be ',
            <strong key={3}>most valuable.</strong>,
            '"',
          ]}
          width={482}
        />
        <Review
          text={[
            '"Explore Courses tells you what the class is about, but I feel like Carta gives you a more accurate reflection of what the ',
            <strong key={4}>students are saying.</strong>,
            '"',
          ]}
          width={678}
        />
      </ReviewBody>
    </ReviewsWrapper>
  )
}
