import React from 'react'
import styled, { keyframes } from 'styled-components'

export const blinkKeyframe = keyframes`
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
`

const DotsWrapper = styled.span`
  & span {
    animation: ${blinkKeyframe};
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }

  & span:nth-child(2) {
    animation-delay: 0.2s;
  }

  & span:nth-child(3) {
    animation-delay: 0.4s;
  }
`

const LoadingDots = () => {
  return (
    <DotsWrapper>
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </DotsWrapper>
  )
}

export default LoadingDots
