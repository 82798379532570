import {
  SEARCH_BEGIN,
  SEARCH_SUCCESS,
  SEARCH_FAILURE,
  SEARCH_INDEX_BEGIN,
  SEARCH_INDEX_SUCCESS,
  SEARCH_INDEX_FAILURE,
} from '../actions/searchActions'

const initialState = {
  query: null,
  searchResults: {
    courses: [],
    instructors: [],
  },
  searchIndex: { courses: [], instructors: [] },
  loading: false,
  error: null,
}

export default function searchReducer(state = initialState, action) {
  switch (action.type) {
    case SEARCH_BEGIN:
      return {
        ...state,
        query: action.payload.data,
        loading: true,
        error: null,
      }

    case SEARCH_SUCCESS:
      if (state.query === action.payload.data.query) {
        return {
          ...state,
          loading: false,
          searchResults: action.payload.data,
        }
      } else {
        return state
      }

    case SEARCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        searchResults: initialState.searchResults,
      }

    case SEARCH_INDEX_BEGIN:
      return {
        ...state,
        loading: true,
      }

    case SEARCH_INDEX_SUCCESS:
      return {
        ...state,
        loading: false,
        searchIndex: action.payload.data,
      }

    case SEARCH_INDEX_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        searchIndex: initialState.searchIndex,
      }

    default:
      return state
  }
}
