import api from '../../api'

export const FETCH_PINNED_COURSES_BEGIN = 'FETCH_PINNED_COURSES_BEGIN'
export const FETCH_PINNED_COURSES_SUCCESS = 'FETCH_PINNED_COURSES_SUCCESS'
export const FETCH_PINNED_COURSES_INFO_SUCCESS =
  'FETCH_PINNED_COURSES_INFO_SUCCESS'
export const FETCH_PINNED_COURSES_FAILURE = 'FETCH_PINNED_COURSES_FAILURE'

export const PIN_COURSE_BEGIN = 'PIN_COURSE_BEGIN'
export const PIN_COURSE_SUCCESS = 'PIN_COURSE_SUCCESS'
export const PIN_COURSE_INFO_SUCCESS = 'PIN_COURSE_INFO_SUCCESS'
export const PIN_COURSE_FAILURE = 'PIN_COURSE_FAILURE'

export const UNPIN_COURSE_BEGIN = 'UNPIN_COURSE_BEGIN'
export const UNPIN_COURSE_SUCCESS = 'UNPIN_COURSE_SUCCESS'
export const UNPIN_COURSE_FAILURE = 'UNPIN_COURSE_FAILURE'

export const UPDATE_PIN_BEGIN = 'UPDATE_PIN_BEGIN'
export const UPDATE_PIN_SUCCESS = 'UPDATE_PIN_SUCCESS'
export const UPDATE_PIN_FAILURE = 'UPDATE_PIN_FAILURE'

export const TOGGLE_SIDEPANEL = 'TOGGLE_SIDEPANEL' // move to general

export const fetchPinnedCoursesBegin = () => ({
  type: FETCH_PINNED_COURSES_BEGIN,
})

export const fetchPinnedCoursesSuccess = data => ({
  type: FETCH_PINNED_COURSES_SUCCESS,
  payload: { data },
})

export const fetchPinnedCoursesFailure = error => ({
  type: FETCH_PINNED_COURSES_SUCCESS,
  payload: { error },
})

export const pinCourseBegin = () => ({
  type: PIN_COURSE_BEGIN,
})

export const pinCourseSuccess = data => ({
  type: PIN_COURSE_SUCCESS,
  payload: { data },
})

export const pinCourseFailure = error => ({
  type: PIN_COURSE_FAILURE,
  payload: { error },
})

export const unpinCourseBegin = () => ({
  type: UNPIN_COURSE_BEGIN,
})

export const unpinCourseSuccess = data => ({
  type: UNPIN_COURSE_SUCCESS,
  payload: { data },
})

export const unpinCourseFailure = error => ({
  type: UNPIN_COURSE_FAILURE,
  payload: { error },
})

export const updatePinBegin = () => ({
  type: UPDATE_PIN_BEGIN,
})

export const updatePinSuccess = data => ({
  type: UPDATE_PIN_SUCCESS,
  payload: { data },
})

export const updatePinFailure = error => ({
  type: UPDATE_PIN_FAILURE,
  payload: { error },
})

export function updatePin(pinnedCourse) {
  return dispatch => {
    dispatch(updatePinBegin())

    api.pins
      .update(pinnedCourse)
      .then(pin => {
        dispatch(updatePinSuccess(pin))
      })
      .catch(error => {
        dispatch(updatePinFailure(error))
      })
  }
}

export function fetchPinnedCourses() {
  return dispatch => {
    dispatch(fetchPinnedCoursesBegin())

    api.pins.get().then(pins => {
      dispatch(fetchPinnedCoursesSuccess(pins))
    })
  }
}

/*
 * info = {
 *   course_id: uuid,
 *   term_id: uuid,
 *   type: 'pin',
 * }
 */
export function pinCourse(info) {
  return dispatch => {
    dispatch(pinCourseBegin())
    api.pins.create(info).then(pin => {
      dispatch(pinCourseSuccess(pin))
    })
  }
}

export function unpinCourse(course_idx) {
  return (dispatch, getState) => {
    dispatch(unpinCourseBegin())
    const pin_id = getState().pinnedCourses.pinnedCourses[course_idx].id
    return api.pins.destroy(pin_id).then(() => {
      dispatch(unpinCourseSuccess(course_idx))
      return pin_id
    })
  }
}

export const toggleSidePanel = () => ({
  // move to general
  type: TOGGLE_SIDEPANEL,
})
