import {
  FETCH_SUNETID_BEGIN,
  FETCH_SUNETID_SUCCESS,
  FETCH_SUNETID_FAILURE,
} from '../actions/sunetidActions'

const initialState = {
  SUNetID: {},
  loading: false,
  error: null,
}

export default function sunetidReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SUNETID_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case FETCH_SUNETID_SUCCESS:
      return {
        ...state,
        loading: false,
        SUNetID: action.payload.data,
      }

    case FETCH_SUNETID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        SUNetID: {},
      }

    default:
      return state
  }
}
