import React, { Component } from 'react'
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'

const LoadingWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`

class Loading extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <LoadingWrapper>
        <div className="container">
          <Spinner animation="border" variant="danger" size="lg" />
          <h2>Loading</h2>
        </div>
      </LoadingWrapper>
    )
  }
}

export default Loading
