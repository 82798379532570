import React from 'react'
import styled from 'styled-components'
import api from '../../../../../api'

const LoginButtonWrapper = styled.div``

const Button = styled.button`
  background-color: #b71c1c;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  height: 38px;
  width: 75px;
  font-size: 18px;
  font-weight: 600;
`
export default function LoginButton() {
  const login = () => {
    api.authentication.redirect(window.location.pathname)
  }

  return (
    <LoginButtonWrapper>
      <Button onClick={login}>Login</Button>
    </LoginButtonWrapper>
  )
}
