import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'react-bootstrap'
import GlanceSched from '../../Tools/GlanceSched'
import CoursePopup from '../../Tools/CoursePopup'
import { courseUnitDisplay } from '../../Tools/utils.js'
import { NAVBAR_BKGRND_COLOR } from '../../assets/constant_colors'
import { connect } from 'react-redux'
import courseUtils from './../../Tools/courseUtils'
import { Link } from 'react-router-dom'

const GlanceWrapper = styled.div`
  && .container {
    padding: 0;
    margin: 0;
  }

  & .col-lg-12,
  .col-lg-6,
  .col-lg-4 {
    padding-left: 0;
  }

  & .pseudo_link {
    color: #007bff;
    cursor: pointer;
  }

  & .pseudo_link:hover {
    text-decoration: underline;
  }

  & .card {
    font-size: 12px;
  }
  .card > .header {
    background: #f2f4f7;
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 14px;
    text-align: center;
  }
`

const SmallBox = styled.div`
  background-color: ${NAVBAR_BKGRND_COLOR};
  width: 12px;
  height: 12px;
  display: inline-block;
  margin-left: 5px;
`

class GlanceCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openSchedule: false,
    }

    this.toggleSchedule = this.toggleSchedule.bind(this)
  }

  /*
   * General function to return a list of Links that when clicked switch to course info page of linked class
   * and when hovered over, display a popup version of the course info for that class.
   *
   * Takes an object that maps course names to course id's which are used to route to appropriate page.
   * For example:
   *
   *      var courses = {
   *      "first_course_name": 'CS 247',
   *      "second_course_name": 'CS 155',
   *      }
   */
  getInteractiveCourseList(coursesObject) {
    //one problem also is, this way always pops up on top, need log to tell it where to pop up, annoying...
    //see other directions here: https://react-popup.elazizi.com/use-case---tooltip/
    return Object.keys(coursesObject).map((classNameKey, index) => (
      <span key={index}>
        <CoursePopup
          className={classNameKey}
          courseCode={coursesObject[classNameKey]}
        />
      </span>
    ))
  }

  getSimilarClasses() {
    //TODO: Should eventually fetch list of courses from an API, each with name and id
    var simCourses = {
      first_course_name: 'CS 247',
      second_course_name: 'CS 155',
    }

    return <div>{this.getInteractiveCourseList(simCourses)}</div>
  }

  // TODO: Write a function that generates the Instructors section
  renderUnits() {
    // var course = this.props.courseInfo.course;
    const { course } = this.props
    if (!course) return
    return (
      <p>
        {courseUnitDisplay(course.course.min_units, course.course.max_units)}
      </p>
    )
  }

  toggleSchedule() {
    this.setState(state => ({
      openSchedule: !state.openSchedule,
    }))
  }

  renderSchedule() {
    const { course } = this.props
    const { openSchedule } = this.state

    // TO DO: only generate quarters that exist
    if (openSchedule) {
      return <GlanceSched course={course.course} />
    }
  }

  getTerms() {
    const { course } = this.props
    if (!course) return null
    const seasons = course.course.seasons_offered
    seasons.map((term, index, seasons) => {
      term = term.charAt(0).toUpperCase() + term.slice(1)
      seasons[index] = term
      return null
    })
    return <div>{seasons.join(', ')}</div>
  }

  renderReqs() {
    const { course } = this.props
    if (!course) return
    return <p>{course.course.general_requirements}</p>
  }

  render() {
    const { course, currentTerm } = this.props
    const courseId = course?.course?.course_id
    const exploreCoursesUrl = `https://explorecourses.stanford.edu/search?view=catalog&filter-coursestatus-Active=on&page=0&catalog=&academicYear=&q=${courseId}`
    if (Object.keys(course).length === 0) {
      return null
    }

    let termInstructors =
      courseUtils.getPrimaryInstructors(course, currentTerm) || []
    return (
      <GlanceWrapper >
        <Container>
          <Row>
            <Col lg={12}>
              {course.course ? course.course.description : null}
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <b>
                <a href={exploreCoursesUrl}>Go to Explore Courses</a>
              </b>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <b>Terms</b>
              {this.getTerms()}
            </Col>
            <Col lg={6}>
              <b>Units</b>
              {this.renderUnits()}
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <b>Grading</b>
              <p>{course.course ? course.course.grading : null}</p>
            </Col>
            <Col lg={6}>
              <b>UG REQS</b>
              {this.renderReqs()}
            </Col>
          </Row>
          <Row>
            <Col lg={3} style={{ padding: 0 }}>
              <div className="pseudo_link">
                <p onClick={this.toggleSchedule} style={{ color: '#3e8acc' }}>
                  {this.state.openSchedule ? 'Hide Schedule' : 'Show Schedule'}
                </p>
              </div>
            </Col>
          </Row>
          {
            <Row style={{ margin: 0 }}>
              <Col lg={12}>{this.renderSchedule()}</Col>
            </Row>
          }
          <Row>
            <Col lg={12}>
              <p>
                <b>
                  Instructors ({currentTerm.start_year} - {currentTerm.end_year}
                  )
                </b>
              </p>
            </Col>
            {termInstructors.map((term, index) => {
              return (
                <Col key={index} lg={12}>
                  <Row>
                    <Col lg={4}>
                      <div>
                        {term.season}:
                        {term.id === currentTerm.id && <SmallBox />}
                      </div>
                    </Col>
                    <Col lg={8}>
                      {term.primary_instructors.length > 0 ? (
                        term.primary_instructors.map(
                          (instructor, index, allInstructors) => {
                            return (
                              <span key={index}>
                                <Link to={`/instructor/${instructor.username}`}>
                                  {instructor.name}
                                </Link>
                                {index < allInstructors.length - 1 ? (
                                  (() => {
                                    return <span>, </span>
                                  })()
                                ) : (
                                  <span></span>
                                )}
                              </span>
                            )
                          }
                        )
                      ) : (
                        <p>Instructors Not Available</p>
                      )}
                    </Col>
                  </Row>
                </Col>
              )
            })}
          </Row>

          {/* <Row>
            <Col lg={12}>
              <b>Class with similar descriptions <FontAwesomeIcon icon={faQuestionCircle} color="#aaa" /></b>
              {this.getSimilarClasses()}
            </Col>
          </Row> */}
        </Container>
      </GlanceWrapper>
    )
  }
}

GlanceCard.propTypes = {
  cardTitle: PropTypes.string,
  description: PropTypes.string,
  course_url: PropTypes.string,
  terms: PropTypes.string,
  minUnits: PropTypes.number,
  maxUnits: PropTypes.number,
  grading: PropTypes.string,
  instructors: PropTypes.string,
}

function mapStateToProps(state) {
  return {
    course: state.course.currentCourse,
    currentTerm: state.term.term,
  }
}

export default connect(mapStateToProps)(GlanceCard)
