import React from 'react'
import styled from 'styled-components'

const ReviewCard = styled.div`
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.16);
  background: #fff;
  border-radius: 10px;
  padding: 24px;
  margin-bottom: 23.5px;
  margin-right: 24px;
  color: #212121;
`

export default function Review(props) {
  return (
    <ReviewCard style={{ maxWidth: props.width + 'px' }}>
      <p>{props.text}</p>
    </ReviewCard>
  )
}
