import api from '../../api'

export const FETCH_WAITLIST_STATUS_BEGIN = 'FETCH_WAITLIST_STATUS_BEGIN'
export const FETCH_WAITLIST_STATUS_SUCCESS = 'FETCH_WAITLIST_STATUS_SUCCESS'
export const FETCH_WAITLIST_STATUS_FAILURE = 'FETCH_WAITLIST_STATUS_FAILURE'

export const FETCH_WAITLISTCOUNT_BEGIN = 'FETCH_WAITLISTCOUNT_BEGIN'
export const FETCH_WAITLISTCOUNT_SUCCESS = 'FETCH_WAITLISTCOUNT_SUCCESS'
export const FETCH_WAITLISTCOUNT_FAILURE = 'FETCH_WAITLISTCOUNT_FAILURE'

export const JOIN_WAITLIST_BEGIN = 'JOIN_WAITLIST_BEGIN'
export const JOIN_WAITLIST_SUCCESS = 'JOIN_WAITLIST_SUCCESS'
export const JOIN_WAITLIST_FAILURE = 'JOIN_WAITLIST_FAILURE'

export const fetchWaitlistCountBegin = () => ({
  type: FETCH_WAITLISTCOUNT_BEGIN,
})

export const fetchWaitlistCountSuccess = data => ({
  type: FETCH_WAITLISTCOUNT_SUCCESS,
  payload: { data },
})

export const fetchWaitlistCountFailure = error => ({
  type: FETCH_WAITLISTCOUNT_FAILURE,
  payload: { error },
})

export function fetchWaitlistCount() {
  return dispatch => {
    dispatch(fetchWaitlistCountBegin())

    api.waitlist.count().then(count => {
      dispatch(fetchWaitlistCountSuccess(count))
    })
  }
}

export const fetchWaitlistStatusBegin = () => ({
  type: FETCH_WAITLIST_STATUS_BEGIN,
})

export const fetchWaitlistStatusSuccess = data => ({
  type: FETCH_WAITLIST_STATUS_SUCCESS,
  payload: { data },
})

export const fetchWaitlistStatusFailure = error => ({
  type: FETCH_WAITLIST_STATUS_FAILURE,
  payload: { error },
})

export function fetchWaitlistStatus() {
  return dispatch => {
    dispatch(fetchWaitlistStatusBegin())

    api.waitlist.get().then(waitlist => {
      dispatch(fetchWaitlistStatusSuccess(waitlist))
    })
  }
}

export const joinWaitlistBegin = () => ({
  type: JOIN_WAITLIST_BEGIN,
})

export const joinWaitlistSuccess = data => ({
  type: JOIN_WAITLIST_SUCCESS,
  payload: { data },
})

export const joinWaitlistFailure = error => ({
  type: JOIN_WAITLIST_FAILURE,
  payload: { error },
})

export function joinWaitlist() {
  return dispatch => {
    dispatch(joinWaitlistBegin())
    api.waitlist
      .join()
      .then(success => {
        dispatch(joinWaitlistSuccess)
      })
      .catch(error => {
        dispatch(joinWaitlistFailure(error.response.status))
      })
  }
}
