import React, { useState } from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const CatalogDropdown = ({ index, title, children }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <DropdownButton
      id={`catalog-dropdown-${index}`}
      title={title}
      drop="right"
      show={isOpen}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      {children.options.map((option) => (
        <Dropdown.Item
          key={option.code}
          as={Link}
          to={'/results/' + option.code}
        >
          {option.description + ' (' + option.code + ')'}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  )
}

export default CatalogDropdown
