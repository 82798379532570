import api from '../../api'
import { fetchGraph } from './graphActions'

export const FETCH_COURSE_BEGIN = 'FETCH_COURSE_BEGIN'
export const FETCH_COURSE_SUCCESS = 'FETCH_COURSE_SUCCESS'
export const FETCH_COURSE_FAILURE = 'FETCH_COURSE_FAILURE'

export const FETCH_REVIEWS_BEGIN = 'FETCH_REVIEWS_BEGIN'
export const FETCH_REVIEWS_SUCCESS = 'FETCH_REVIEWS_SUCCESS'
export const FETCH_REVIEWS_FAILURE = 'FETCH_REVIEWS_FAILURE'

export const fetchCourseBegin = () => ({
  type: FETCH_COURSE_BEGIN,
})

export const fetchCourseSuccess = data => ({
  type: FETCH_COURSE_SUCCESS,
  payload: { data },
})

export const fetchCourseFailure = error => ({
  type: FETCH_COURSE_FAILURE,
  payload: { error },
})

export const fetchReviewsBegin = () => ({
  type: FETCH_REVIEWS_BEGIN,
})

export const fetchReviewsSuccess = data => ({
  type: FETCH_REVIEWS_SUCCESS,
  payload: { data },
})

export const fetchReviewsFailure = error => ({
  type: FETCH_REVIEWS_FAILURE,
  payload: { error },
})

export function fetchCourse(courseCode) {
  return dispatch => {
    dispatch(fetchCourseBegin())

    api.course.getByCode(courseCode).then(course => {
      dispatch(fetchCourseSuccess(course))

      dispatch(fetchGraph(course.course.id))
      dispatch(fetchReviewsBegin())
      api.course
        .getReviews(course.course.id)
        .then(reviews => {
          dispatch(fetchReviewsSuccess(reviews))
        })
        .catch(error => {
          console.log(error)
          dispatch(fetchReviewsFailure(error))
        })

      return course
    })
  }
}
