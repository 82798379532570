import React, { Component } from 'react'
import styled from 'styled-components'
import LoginButton from './LoginButton/LoginButton'
import Logo from "../../../../assets/logo.svg"

const LandingNavbarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`

export default class LandingNavbar extends Component {
  render() {
    return (
      <LandingNavbarWrapper>
        <img src={Logo} alt="stanford-carta-logo"/>
        <LoginButton />
      </LandingNavbarWrapper>
    )
  }
}
