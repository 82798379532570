import React from 'react'
import { Spinner } from 'react-bootstrap'
import styled from 'styled-components'

const Backdrop = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.075);
`

const Loader = styled.div`
  position: fixed;
  z-index: 500;
  width: 30%;
  padding: 16px;
  left: 35%;
  top: 30%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
`

export default function BackdropSpinner() {
  return (
    <Backdrop>
      <Loader>
        <Spinner
          animation="border"
          size="xl"
          variant="danger"
          role="status"
        ></Spinner>
        <p className="mt-1">Loading...</p>
      </Loader>
    </Backdrop>
  )
}
