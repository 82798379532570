// from https://scotch.io/tutorials/5-most-common-dropdown-use-cases-solved-with-react-downshift#toc-downshift-with-axios
import React, { Component } from 'react'
import Downshift from 'downshift'
import { DROPDOWN_HIGHLIGHT_COLOR } from '../../assets/constant_colors.js'
import { Link } from 'react-router-dom'
import {
  MIN_NUM_SEARCH_LETTERS,
  MAX_NUM_SEARCH_SUGGESTIONS,
} from '../../assets/constant_sizes.js'
import { fetchCourse } from '../../assets/actions/courseActions'
import { search } from '../../assets/actions/searchActions'
import { connect } from 'react-redux'

class SearchBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      results: {
        courses: [],
        instructors: [],
      },
    }
    this.inputOnChange = this.inputOnChange.bind(this)
    this.enterPressed = this.enterPressed.bind(this)
    this.fetchCourse = this.fetchCourse.bind(this)
  }

  fetchCourse(courseCode) {
    this.props.fetchCourse(courseCode)
  }

  enterPressed(e) {
    const { searchEntry } = this.props
    if (e.key !== 'Enter') {
      return
    }
    e.preventDefault()
    e.target.blur()
    if (searchEntry.length < MIN_NUM_SEARCH_LETTERS) {
      return
    }
    const { alertParentEnterPressed } = this.props
    this.setState({
      results: {
        courses: [],
        instructors: [],
      },
    })
    alertParentEnterPressed()
  }

  // onChange method for the input field
  inputOnChange(event) {
    const { updateEntryCallback } = this.props

    // Limit the minimum length of the search query input to trigger a fetch
    if (
      !event.target.value ||
      event.target.value.length < MIN_NUM_SEARCH_LETTERS
    ) {
      this.setState({
        results: {
          courses: [],
          instructors: [],
        },
      })
      return
    }

    // Set input in navbar
    updateEntryCallback(event.target.value)
  }
  // input field for the <Downshift /> component:
  // downshiftOnChange(selectedClass) {}

  render() {
    const { index } = this.props

    return (
      <Downshift itemToString={(item) => (item ? item.name : '')}>
        {/* // pass the downshift props into a callback */}
        {({
          selectedItem,
          getInputProps,
          getItemProps,
          highlightedIndex,
          isOpen,
          inputValue,
          getLabelProps,
        }) => {
          const results = localSearchLite(index, inputValue)

          const num_instructors = Math.min(
            results.instructors.length,
            MAX_NUM_SEARCH_SUGGESTIONS
          )
          const num_courses = MAX_NUM_SEARCH_SUGGESTIONS - num_instructors

          return (
            <div>
              {/* // add a label tag and pass our label text to the getLabelProps function */}
              {/* // add a input tag and pass our placeholder text to the getInputProps function. We also have an onChange eventlistener on the input field */}
              <input
                {...getInputProps({
                  placeholder:
                    'Search by department, course number or description',
                  onChange: this.inputOnChange,
                  onKeyDown: this.enterPressed,
                })}
              />
              {/* // if the input element is open, render the div else render nothing */}
              {(() => {
                if (!isOpen || inputValue === '') return null
                if (
                  inputValue.length > 0 &&
                  inputValue.length < MIN_NUM_SEARCH_LETTERS
                ) {
                  return (
                    <div className="downshift-dropdown">
                      <div
                        className="dropdown-item"
                        style={{
                          fontFamily: 'Roboto, sans-serif',
                          backgroundColor:
                            highlightedIndex === 0
                              ? DROPDOWN_HIGHLIGHT_COLOR
                              : 'white',
                          color: highlightedIndex === 0 ? 'white' : 'black',
                        }}
                      >
                        Continue typing to search...
                      </div>
                    </div>
                  )
                }
                return (
                  <div className="downshift-dropdown">
                    <Link
                      key={0}
                      to={'/results/' + encodeURIComponent(inputValue)}
                    >
                      <div
                        className="dropdown-item"
                        {...getItemProps({ key: 0, item: inputValue })}
                        style={{
                          fontFamily: 'Roboto, sans-serif',
                          backgroundColor:
                            highlightedIndex === 0
                              ? DROPDOWN_HIGHLIGHT_COLOR
                              : 'white',
                          color: highlightedIndex === 0 ? 'white' : 'black',
                        }}
                      >
                        Search for: {inputValue}
                      </div>
                    </Link>
                    {results.instructors
                      .slice(0, num_instructors)
                      .map((item, index) => {
                        let real_index = index + 1
                        return (
                          <Link
                            key={real_index}
                            to={`/instructor/${item.username}`}
                          >
                            <div
                              className="dropdown-item"
                              {...getItemProps({
                                key: real_index,
                                real_index,
                                item,
                              })}
                              style={{
                                fontFamily: 'Roboto, sans-serif',
                                backgroundColor:
                                  highlightedIndex === real_index
                                    ? DROPDOWN_HIGHLIGHT_COLOR
                                    : 'white',
                                color:
                                  highlightedIndex === real_index
                                    ? 'white'
                                    : 'black',
                              }}
                            >
                              {item.name
                                .toLowerCase()
                                .indexOf(inputValue.toLowerCase()) === -1 ? (
                                item.name
                              ) : (
                                <span>
                                  {
                                    item.name.substring(
                                      0,
                                      item.name
                                        .toLowerCase()
                                        .indexOf(inputValue.toLowerCase())
                                    ) /*First part of matching string, before matching part entered by user*/
                                  }
                                  <strong>
                                    {
                                      item.name.substring(
                                        item.name
                                          .toLowerCase()
                                          .indexOf(inputValue.toLowerCase()),
                                        item.name
                                          .toLowerCase()
                                          .indexOf(inputValue.toLowerCase()) +
                                          inputValue.length
                                      ) /*Matching part entered by user, bold*/
                                    }
                                  </strong>
                                  {
                                    item.name.substring(
                                      item.name
                                        .toLowerCase()
                                        .indexOf(inputValue.toLowerCase()) +
                                        inputValue.length
                                    ) /*End of matching string after matching part entered by user */
                                  }
                                </span>
                              )}
                            </div>
                          </Link>
                        )
                      })}
                    {
                      // filter the courses in the state
                      results.courses
                        .filter((item) => item.code)
                        .slice(0, num_courses) // return just the first ten. Helps improve performance
                        // map the filtered courses and display their title
                        .map((item, index) => {
                          let real_index = num_instructors + index + 1
                          return (
                            <Link
                              key={real_index}
                              to={`/course/${removeSpacesFromCourse(
                                item.code
                              )}/${this.props.term.stanford_term_id}`}
                              onClick={() => this.fetchCourse(item.code)}
                            >
                              <div
                                className="dropdown-item"
                                {...getItemProps({
                                  key: real_index,
                                  real_index,
                                  item,
                                })}
                                style={{
                                  fontFamily: 'Roboto, sans-serif',
                                  backgroundColor:
                                    highlightedIndex === real_index
                                      ? DROPDOWN_HIGHLIGHT_COLOR
                                      : 'white',
                                  color:
                                    highlightedIndex === real_index
                                      ? 'white'
                                      : 'black',
                                }}
                              >
                                {
                                  // Only attempt to bold search query substring in course code if it is present (the search query is not necessarily a substring of the title)
                                  item.code
                                    .toLowerCase()
                                    .indexOf(inputValue.toLowerCase()) ===
                                  -1 ? (
                                    item.code
                                  ) : (
                                    <span>
                                      {
                                        item.code.substring(
                                          0,
                                          item.code
                                            .toLowerCase()
                                            .indexOf(inputValue.toLowerCase())
                                        ) /*First part of matching string, before matching part entered by user*/
                                      }
                                      <strong>
                                        {
                                          item.code.substring(
                                            item.code
                                              .toLowerCase()
                                              .indexOf(
                                                inputValue.toLowerCase()
                                              ),
                                            item.code
                                              .toLowerCase()
                                              .indexOf(
                                                inputValue.toLowerCase()
                                              ) + inputValue.length
                                          ) /*Matching part entered by user, bold*/
                                        }
                                      </strong>
                                      {
                                        item.code.substring(
                                          item.code
                                            .toLowerCase()
                                            .indexOf(inputValue.toLowerCase()) +
                                            inputValue.length
                                        ) /*End of matching string after matching part entered by user */
                                      }
                                    </span>
                                  )
                                }
                                {': '}
                                {
                                  // Only attempt to bold search query substring if it is present (the search query is not necessarily a substring of the title)
                                  item.title
                                    .toLowerCase()
                                    .indexOf(inputValue.toLowerCase()) ===
                                  -1 ? (
                                    item.title
                                  ) : (
                                    <span>
                                      {
                                        item.title.substring(
                                          0,
                                          item.title
                                            .toLowerCase()
                                            .indexOf(inputValue.toLowerCase())
                                        ) /*First part of matching string, before matching part entered by user*/
                                      }
                                      <strong>
                                        {
                                          item.title.substring(
                                            item.title
                                              .toLowerCase()
                                              .indexOf(
                                                inputValue.toLowerCase()
                                              ),
                                            item.title
                                              .toLowerCase()
                                              .indexOf(
                                                inputValue.toLowerCase()
                                              ) + inputValue.length
                                          ) /*Matching part entered by user, bold*/
                                        }
                                      </strong>
                                      {
                                        item.title.substring(
                                          item.title
                                            .toLowerCase()
                                            .indexOf(inputValue.toLowerCase()) +
                                            inputValue.length
                                        ) /*End of matching string after matching part entered by user */
                                      }
                                    </span>
                                  )
                                }
                              </div>
                            </Link>
                          )
                        })
                    }
                  </div>
                )
              })()}
            </div>
          )
        }}
      </Downshift>
    )
  }
}

export function localSearchLite(index, query) {
  let { courses, instructors } = index
  let resultCourses = []
  let resultInstructors = []
  if (query && query.length > MIN_NUM_SEARCH_LETTERS) {
    let lowerQuery = query.toLowerCase()
    resultCourses = courses.filter((obj) => {
      if (!obj.code) return null
      let title = `${obj.code}: ${obj.title}`
      let titleNoSpace = `${removeSpacesFromCourse(obj.code)}: ${obj.title}`
      if (title.toLowerCase().indexOf(lowerQuery) !== -1) {
        return true
      } else if (titleNoSpace.toLowerCase().indexOf(lowerQuery) !== -1) {
        return true
      }
      return false
    })
    resultInstructors = instructors.filter((obj) => {
      if (obj.name.toLowerCase().indexOf(lowerQuery) !== -1) {
        return true
      }
      if (obj.username === lowerQuery) {
        return true
      }
      return false
    })
  }
  return { courses: resultCourses, instructors: resultInstructors }
}

function removeSpacesFromCourse(courseCode) {
  return courseCode.split(' ').join('')
}

function mapStateToProps(state) {
  return {
    index: state.search.searchIndex,
    term: state.term.term,
  }
}

const mapDispatchToProps = {
  fetchCourse,
  search,
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar)
