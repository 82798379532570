import React, { Component } from 'react'
import styled from 'styled-components'
import { Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { PLANNER_START_YEAR } from '../../../assets/constant_sizes.js'
import Column from './column'
import { DragDropContext } from 'react-beautiful-dnd'
import PlannerSearchBar from './PlannerSearchBar.js'
import {
  PINNED_COURSE,
  ENROLLED_COURSE,
  PINNED_WARNING,
  SEARCHED_COURSE,
} from '../../../assets/constant_colors.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowUp,
  faArrowDown,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import {
  pinCourse,
  unpinCourse,
  updatePin,
} from '../../../assets/actions/pinningActions'
import Loading from '../Loading'
import api from '../../../api'
import { Button } from 'react-bootstrap'

function plannerTermIdToShortTermId(plannerTermId) {
  let season = plannerTermId.substr(0, 3)
  let seasonCode = {
    aut: 2,
    win: 4,
    spr: 6,
    sum: 8,
  }[season]
  let startYear = parseInt(plannerTermId.substr(4))
  let yearCode = startYear + 1 - 1900
  let shortTermId = yearCode * 10 + seasonCode
  return shortTermId
}

const Arrow = styled.span`
  font-size: 16pt;
`

const LabelKey = styled.div`
  font-size: 12pt;
  float: right;
  border: 1px dashed lightgrey;
  padding: 9px;
  padding-top: 10px;
  margin-top: 10px;
`

const LabelBox = styled.span`
  background: ${(props) => props.backgroundColor};
  margin-left: 20px;
  padding: 4px;
  border: 1px solid lightgrey;
  color: ${(props) => props.color};
`

const PlannerHeader = styled.h1``

const Note = styled.span`
  color: ${(props) => props.backgroundColor};
  cursor: help;
  z-index: 1000;
  margin-left: 7px;
  text-align: center;
  vertical-align: middle;
`

const TooltipText = styled.p`
  text-align: left;
  margin-bottom: 5px;
  font-size: 12pt;
  line-height: 1.3;
`

const getPlannerYear = () => {
  const date = new Date()
  if (date.getMonth() >= 8) {
    // September or later, add 1 for next academic year
    return date.getYear() + 1900 + 1
  } else {
    return date.getYear() + 1900
  }
}

class PlannerPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      startYear: getPlannerYear(),
      initialData: {
        courses: {},
        columns: {
          'search-results': {
            id: 'search-results',
            title: 'search-results',
            courseIds: [],
          },
        },
        columnOrder: [],
      },
      currentYears: {},
      quarterCourses: {},
      quarterColumns: {},
      quarterColumnOrder: [],
      hasData: false,
    }

    this.advanceYear = this.advanceYear.bind(this)
    this.decreaseYear = this.decreaseYear.bind(this)
    this.generateCurrentYears = this.generateCurrentYears.bind(this)
    this.generateQuarterCourses = this.generateQuarterCourses.bind(this)
    this.removeCourseFromColumn = this.removeCourseFromColumn.bind(this)
    this.changeDNDData = this.changeDNDData.bind(this)
  }

  componentDidMount() {
    this.generateCurrentYears(this.generateQuarterCourses)
    this.props.removeSidePanel()
  }

  componentDidUpdate(prevProps) {
    if (this.props.pinnedCourses !== prevProps.pinnedCourses) {
      this.generateCurrentYears(this.generateQuarterCourses)
    }
  }

  onDragEnd = (result) => {
    const { destination, source, draggableId } = result

    if (!destination) return

    //if dragged to same position
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return
    const start = this.state.quarterColumns[source.droppableId]
    const finish = this.state.quarterColumns[destination.droppableId]
    //movement within same column:
    if (start === finish) {
      //avoids mutating, make a copy
      const newCourseIds = Array.from(start.courseIds)

      //move task id from old to new index in array:
      newCourseIds.splice(source.index, 1)
      newCourseIds.splice(destination.index, 0, draggableId)

      const newColumn = {
        ...start,
        courseIds: newCourseIds,
      }
      this.setState({
        quarterColumns: {
          ...this.state.quarterColumns,
          [newColumn.id]: newColumn,
        },
      })
      // the backend stores these unordered, so we can't really do anything
      // here...
      return
    }
    //else: movement between columns:
    if (finish.id === 'search-results') {
      // drag to search results---not allowed
      return
    }
    if (start.id === 'search-results') {
      // search results to planner
      const finishCourseIds = Array.from(finish.courseIds)
      let newId = draggableId + '-temp-' + new Date().getTime()
      finishCourseIds.splice(destination.index, 0, newId)
      const newFinish = {
        ...finish,
        courseIds: finishCourseIds,
      }
      let courseData = this.state.initialData.courses[draggableId]
      if (
        finish.courseIds.some((otherDraggableId) => {
          return (
            this.state.quarterCourses[otherDraggableId].course_id ===
            courseData.course_id
          )
        })
      ) {
        // The course is already pinned to the target quarter, so we'll get a
        // 400 if we try to do anything.
        return
      }
      this.setState({
        quarterColumns: {
          ...this.state.quarterColumns,
          [newFinish.id]: newFinish,
        },
        quarterCourses: {
          ...this.state.quarterCourses,
          [newId]: {
            ...courseData,
            id: newId,
          },
        },
      })
      let shortTermId = plannerTermIdToShortTermId(finish.id)
      api.term.getByShortId(shortTermId).then((term) => {
        let pinInfo = {
          course_id: courseData.course_id,
          term_id: term.id,
          type: 'pin',
        }
        this.props.pinCourse(pinInfo)
      })
    } else {
      const startCourseIds = Array.from(start.courseIds)
      startCourseIds.splice(source.index, 1)
      const newStart = {
        ...start,
        courseIds: startCourseIds,
      }
      const finishCourseIds = Array.from(finish.courseIds)
      let courseData = this.state.quarterCourses[draggableId]
      if (
        finish.courseIds.some((otherDraggableId) => {
          return (
            this.state.quarterCourses[otherDraggableId].course_id ===
            courseData.course_id
          )
        })
      ) {
        // The course is already pinned to the target quarter, so we'll get a
        // 400 if we try to do anything.
        return
      }
      finishCourseIds.splice(destination.index, 0, draggableId)
      const newFinish = {
        ...finish,
        courseIds: finishCourseIds,
      }
      this.setState({
        quarterColumns: {
          ...this.state.quarterColumns,
          [newStart.id]: newStart,
          [newFinish.id]: newFinish,
        },
      })
      let shortTermId = plannerTermIdToShortTermId(finish.id)
      api.term.getByShortId(shortTermId).then((term) => {
        let pinInfo = {
          id: courseData.pin_id,
          course: { id: courseData.course_id },
          term: term,
          type: 'pin',
        }
        this.props.updatePin(pinInfo)
      })
    }
  }

  getHeader() {
    return (
      <Row>
        <Col md={1} lg={1}></Col>
        <Col md={11} lg={11} style={{ marginTop: '25px' }}>
          <PlannerHeader>
            Planner: {this.state.startYear}&ndash;{this.state.startYear + 4}
          </PlannerHeader>
          <LabelKey>
            Labels:
            {[
              [
                'Registered',
                ENROLLED_COURSE,
                "You've already taken this class at Stanford (or you're currently taking it).",
                'gray',
              ],
              [
                'Pinned',
                PINNED_COURSE,
                'You can pin classes by searching for them and dragging them into the planner, or by clicking "Pin" from the course info or search pages.',
                'white',
              ],
              [
                'Warning',
                PINNED_WARNING,
                "You've pinned this course to your Four-Year Plan, but we're not sure if it'll be offered in the quarter you pinned it.  Please confirm with the instructor(s) that the course will actually be offered this quarter.  Stanford usually publishes official schedules about a month before fall quarter enrollment starts.",
                'black',
              ],
              [
                'Not Pinned',
                SEARCHED_COURSE,
                'You just searched for this course.  Drag this course onto the planner to pin it!',
                'white',
              ],
            ].map((label, i) => (
              <LabelBox
                backgroundColor={label[1]}
                key={i}
                color={label.length > 3 ? label[3] : 'auto'}
              >
                {label[0]}
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip>
                      <TooltipText>{label[2]}</TooltipText>
                    </Tooltip>
                  }
                >
                  <Note
                    color={label[1]}
                    backgroundColor={
                      label.length > 3 ? label[3] : 'rgb(170, 170, 170)'
                    }
                  >
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </Note>
                </OverlayTrigger>
              </LabelBox>
            ))}
          </LabelKey>
          <br />
          <Button variant="danger">
            <Arrow onClick={this.advanceYear}>
              <FontAwesomeIcon icon={faArrowDown} />
              &nbsp; &apos;{(this.state.startYear + 1) % 100}&ndash;&apos;
              {(this.state.startYear + 5) % 100}
            </Arrow>
          </Button>
          {/* this is definitely how we should be doing layouts in 2020: */}
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          <Button variant="danger">
            <Arrow onClick={this.decreaseYear}>
              <FontAwesomeIcon icon={faArrowUp} />
              &nbsp; &apos;{(this.state.startYear - 1) % 100}&ndash;&apos;
              {(this.state.startYear + 3) % 100}
            </Arrow>
          </Button>
          <br />
          <br />
        </Col>
      </Row>
    )
  }

  advanceYear() {
    const newStartYear = this.state.startYear + 1
    this.setState(
      {
        startYear: newStartYear,
        quarterColumns: {},
        quarterColumnOrder: [],
        currentYears: {},
      },
      () => {
        this.generateCurrentYears(this.generateQuarterCourses)
      }
    )
  }

  decreaseYear() {
    const newStartYear = this.state.startYear - 1
    this.setState(
      {
        startYear: newStartYear,
        quarterColumnOrder: [],
        quarterColumns: {},
        currentYears: {},
      },
      () => {
        this.generateCurrentYears(this.generateQuarterCourses)
      }
    )
  }

  generateCurrentYears(callback) {
    // TODO: this recursion is a hack because setState takes a callback but
    // this code was written with async/await
    let mainQuarterColumns = {}
    let mainCurrentYears = {}
    let mainQuarterColumnOrder = []
    for (let i = 0; i < 4; i++) {
      let yearLabel = `${this.state.startYear + i}-${
        this.state.startYear + i + 1
      }`
      let year = this.state.startYear + i
      let quarters = [
        `aut-${year}`,
        `win-${year}`,
        `spr-${year}`,
        `sum-${year}`,
      ]
      let quarterColumnOrder = [
        ...mainQuarterColumnOrder,
        quarters[0],
        quarters[1],
        quarters[2],
        quarters[3],
      ]
      let quarterColumns = {}
      quarters.forEach((quarter) => {
        quarterColumns = {
          ...quarterColumns,
          [quarter]: {
            id: quarter,
            title: quarter,
            courseIds: [],
          },
        }
      }, this)

      mainQuarterColumns = {
        ...mainQuarterColumns,
        ...quarterColumns,
        'search-results': {
          id: 'search-results',
          title: 'search-results',
          courseIds: [],
        },
      }

      mainCurrentYears = {
        ...mainCurrentYears,
        [yearLabel]: quarterColumns,
      }

      mainQuarterColumnOrder = [...quarterColumnOrder, 'search-results']
    }
    this.setState(
      {
        quarterColumns: mainQuarterColumns,
        currentYears: mainCurrentYears,
        quarterColumnOrder: mainQuarterColumnOrder,
      },
      callback
    )
  }

  generateQuarterCourses() {
    const { pinnedCourses } = this.props
    let quarterColumns = this.state.quarterColumns
    let quarterCourses = {}

    pinnedCourses.forEach((pin, i) => {
      let label = 'enrolled'
      if (pin.type === 'pin') {
        label = 'pinned'
        if (pin.invalid_quarter_warning) {
          label = 'warning'
        }
      }
      if (!pin?.course?.course_codes?.[0]) {
        return
      }

      const courseData = {
        id: pin.id,
        title: `${pin.course.course_codes[0]}: ${pin.course.title}`,
        maxUnits: pin.course.max_units,
        minUnits: pin.course.min_units,
        label: label,
        course_code: pin.course.course_codes[0],
        course_id: pin.course.id,
        pin_id: pin.id,
        pin_idx: i,
        warn_no_offerings: !pin.offering_ids,
      }
      const quarter_name = `${pin.term.season.substr(0, 3).toLowerCase()}-${
        pin.term.start_year
      }`
      let quarter = quarterColumns[quarter_name]
      if (quarter) {
        quarter.courseIds.push(courseData.id)
        quarterColumns = {
          ...quarterColumns,
          [quarter_name]: quarter,
        }
      }
      quarterCourses = {
        ...quarterCourses,
        [courseData.id]: courseData,
      }
    })
    this.setState({ quarterColumns, quarterCourses, hasData: true })
  }

  //returns columns based on particularly formatted drag and drop data based on state given
  //start and end index of array. Necessary because we need several rows for the planner but
  //the way the data is formatted is all the columns are kept as an array. This way, we just
  //break up the array into pieces and use each piece as a row.
  getColumnsInRange(year, isLastRow = false) {
    return Object.keys(year).map((quarter, i) => {
      const column = this.state.quarterColumns[quarter]
      if (!column) return undefined
      const courses = column.courseIds.map(
        (courseId) => this.state.quarterCourses[courseId]
      )
      return (
        <Col
          style={{
            borderBottom: isLastRow ? 'none' : '1px solid rgb(226,226,226)',
            padding: 0,
          }}
          xs={12}
          md={12}
          lg={3}
          key={quarter}
        >
          <Column
            courses={courses}
            column={column}
            blank={false}
            removeCourseFromColumn={this.removeCourseFromColumn}
            lastColumn={i === 3}
          />
        </Col>
      )
    })
  }

  removeCourseFromColumn(courseId, columnName) {
    var newCourses = this.state.quarterCourses
    let courseData = newCourses[courseId]
    delete newCourses.courseId

    const newCourseIds = Array.from(
      this.state.quarterColumns[columnName].courseIds
    )
    newCourseIds.splice(newCourseIds.indexOf(courseId), 1)

    const newColumn = {
      ...this.state.quarterColumns[columnName],
      courseIds: newCourseIds,
    }

    this.setState({
      quarterCourses: newCourses,
      quarterColumns: {
        ...this.state.quarterColumns,
        [newColumn.id]: newColumn,
      },
    })

    // TODO: handle errors
    this.props.unpinCourse(courseData.pin_idx)
    return
  }

  changeDNDData(newData) {
    this.setState({
      initialData: newData,
    })
  }

  getYearRows() {
    return (
      <Row>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Col
            xs={1}
            md={1}
            lg={1}
            style={{ zIndex: '1000', padding: 0 }}
          ></Col>
          <Col xs={10} md={7} lg={9} style={{ zIndex: '1000', padding: 0 }}>
            {Object.keys(this.state.currentYears).map((year, i) => (
              <Row key={i}>
                {this.getColumnsInRange(this.state.currentYears[year], i === 3)}
              </Row>
            ))}
          </Col>
          <Col
            xs={10}
            md={4}
            lg={2}
            style={{
              padding: 0,
              height: '801px',
            }}
          >
            <PlannerSearchBar
              dragNDropData={this.state.initialData}
              removeCourseFromColumn={this.removeCourseFromColumn}
              changeDNDData={this.changeDNDData}
            />
          </Col>
        </DragDropContext>
      </Row>
    )
  }

  getSeasonHeader() {
    return (
      <Row>
        <Col lg={2} md={2} style={{ textAlign: 'center', padding: 0 }}>
          <Arrow onClick={this.advanceYear}>
            <FontAwesomeIcon icon={faArrowUp} />
          </Arrow>
        </Col>
        {['Autumn', 'Winter', 'Spring', 'Summer'].map((season, i) => (
          <Col
            lg={2}
            md={2}
            style={{
              padding: 0,
              textAlign: 'center',
              lineHeight: '50px',
              fontSize: '18px',
            }}
            key={i}
          >
            {season}
          </Col>
        ))}
      </Row>
    )
  }

  getPlannerRows() {
    return (
      <div>
        {this.getHeader()}
        {this.getYearRows()}
      </div>
    )
  }

  render() {
    return (
      <Container fluid>
        {this.state.hasData ? (
          this.getPlannerRows()
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: window.innerHeight,
              width: window.innerWidth,
              alignItems: 'center',
            }}
          >
            <Loading />
          </div>
        )}
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    pinnedCourses: state.pinnedCourses.pinnedCourses,
  }
}

const mapDispatchToProps = {
  pinCourse,
  unpinCourse,
  updatePin,
}

export default connect(mapStateToProps, mapDispatchToProps)(PlannerPage)
