import React from 'react'
import styled from 'styled-components'
const ImageCropper = styled.div`
  width: 150px;
  height: 150px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  border: 3px solid #b1040ecc;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);
  margin-bottom: 15px;
`

const Image = styled.img`
  height: 150px;
  width: auto;
`

const AboutProfileWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 15px;
`

const ProfileDetails = styled.div`
  text-align: center;
`

export default function AboutProfile(props) {
  return (
    <AboutProfileWrapper>
      <ImageCropper>
        {props.image !== '' ? (
          <Image src={props.image} alt="profile-image" />
        ) : null}
      </ImageCropper>
      <ProfileDetails>
        <p className="mb-0" style={{ fontWeight: 700 }}>
          {props.name}
        </p>
        <p style={{ color: '#555' }}>{props.occupation}</p>
      </ProfileDetails>
    </AboutProfileWrapper>
  )
}
