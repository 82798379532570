import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { SIDE_COLOR, CALENDAR_COLORS } from '../../assets/constant_colors'
import { SIDE_PANEL_WIDTH, THRESHOLD } from '../../assets/constant_sizes'
import ClassCalendar from '../../Tools/ClassCalendar'
import {
  Container,
  Row,
  Form,
  Tooltip,
  OverlayTrigger,
  Button,
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowLeft,
  faArrowRight,
  faThumbtack,
  faQuestionCircle,
  faTimes,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import { unpinCourse, updatePin } from '../../assets/actions/pinningActions'
import { fetchTerm } from '../../assets/actions/termActions'
import { Link } from 'react-router-dom'
import BackdropSpinner from '../Miscellaneous/BackdropSpinner'
import { ADVISING_URL } from '../../assets/constant_strings'

const SideWrapper = styled.div`
  width: ${SIDE_PANEL_WIDTH};
  height: calc(100vh - 50px);
  background-color: ${SIDE_COLOR};
  position: fixed;
  overflow-y: scroll;
  left: 0;
  transform: ${(props) =>
    props.threshold < THRESHOLD && !props.showSidePanel
      ? 'translateX(-100%)'
      : 'translateX(0%)'};
  transition: all 600ms ease;
  padding: 10px;
  z-index: 1;

  h3 {
    text-align: center;
    width: -webkit-fill-available;
  }

  h4 {
    color: #5d5d5d;
    font-weight: 500;
    font-size: 15px;
  }

  p {
    margin: 0;
  }

  && .container {
    padding: 0;
  }

  & .col-lg-6,
  .col-lg-12 {
    padding: 0;
  }

  & .row {
    margin: 0;
  }

  hr {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
`

const Pinned = styled.div`
  width: 100%;
  margin-bottom: 5px;
  margin-top: 5px;
  opacity: 0.8;
  box-shadow: lightgrey 0px 1px 1px 0px;
  padding: 0.5rem;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);
  border-image: initial;
  background: rgb(255, 255, 255);
`

const Enrolled = styled.div`
  width: 100%;
  margin-bottom: 5px;
  margin-top: 5px;
  opacity: 0.8;
  box-shadow: lightgrey 0px 1px 1px 0px;
  padding: 0.5rem;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);
  border-image: initial;
  background: rgba(200, 200, 200, 0.6);
`

const Arrow = styled.span`
  margin: 0 15px;
  cursor: pointer;
`

const PinIconContainer = styled.span`
  width: 24px;
  height: 24px;
  cursor: pointer;
`

const PinnedIcon = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
`

const Unpin = styled.div`
  opacity: 0;
  position: absolute;
  background-color: white;
  width: 24px;
  height: 24px;
  &:hover {
    opacity: 1;
  }
`

const Note = styled.span`
  border-radius: 15px;
  color: black;
  cursor: help;
  z-index: 1000;
  font-weight: bold;
  float: right;
  text-align: center;
  vertical-align: middle;
  position: absolute;
  right: 25px;
`

const TooltipText = styled.p`
  text-align: left;
  margin-bottom: 5px;
  font-size: 12pt;
  line-height: 1.3;
`

class SidePanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      courses: {},
    }

    this.unpinCourse = this.unpinCourse.bind(this)
    this.toggleSectionCheck = this.toggleSectionCheck.bind(this)
  }

  componentDidMount() {
    if (this.props.term.stanford_term_id) {
      this.handleTermUpdate(this.props.term.stanford_term_id)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.term.stanford_term_id) {
      this.handleTermUpdate(prevProps.term.stanford_term_id)
    }
  }

  handleTermUpdate(oldTermId) {
    if (this.props.match && this.props.match.params.termId !== undefined) {
      const termId = this.props.match.params.termId
      if (parseInt(termId) !== oldTermId) {
        this.props.fetchTerm(termId)
      }
    }
  }

  unpinCourse(id) {
    this.props.unpinCourse(id)
  }

  isOfferingSelected(pin, offering) {
    return pin.offering_ids.indexOf(offering.id) !== -1
  }

  toggleSectionCheck(pin, offering) {
    if (this.isOfferingSelected(pin, offering)) {
      let x = pin.offering_ids.indexOf(offering.id)
      pin.offering_ids.splice(x, 1)
    } else {
      pin.offering_ids.push(offering.id)
    }
    this.props.updatePin(pin)
  }

  getAlternateCourseCodes(courseCodes) {
    let alternateCourseCodes = []
    // if alternate course codes present, add all of them to the array
    courseCodes.map((course_code, index) => {
      if (index > 0) {
        alternateCourseCodes.push(course_code)
      }
      return null
    })
    return alternateCourseCodes
  }

  renderClasses() {
    const { pinnedCourses, term } = this.props
    let filteredCourses = pinnedCourses.filter(
      (pinnedCourse) => pinnedCourse.term.id === term.id
    )
    return (
      <Form>
        {this.props.loading ? <BackdropSpinner /> : null}
        {filteredCourses.map((pinnedCourse, courseId) => {
          var course = pinnedCourse.course
          let delta = 0

          if (!course?.course_codes?.[0]) {
            return null
          }

          return course.offerings.map((offering, sectionId) => {
            if (offering.term.id === term.id) {
              return (
                <Form.Check
                  onChange={() =>
                    this.toggleSectionCheck(pinnedCourse, offering)
                  }
                  disabled={pinnedCourse.type === 'enroll'}
                  checked={this.isOfferingSelected(pinnedCourse, offering)}
                  key={course.course_codes[0] + sectionId}
                  label={`${course.course_codes[0]} ${offering.component} ${
                    sectionId - delta + 1
                  }`}
                  style={{
                    color:
                      sectionId % 2
                        ? CALENDAR_COLORS[courseId % 6].light
                        : CALENDAR_COLORS[courseId % 6].dark,
                  }}
                />
              )
            } else {
              delta++
              return null
            }
          })
        })}
      </Form>
    )
  }

  renderPinned() {
    const { pinnedCourses, term } = this.props
    let filteredCourses = pinnedCourses.filter(
      (pinnedCourse) =>
        pinnedCourse.term.id === term.id && pinnedCourse.type === 'pin'
    )
    return (
      <>
        <hr></hr>
        <Row>
          <h4>PINNED CLASSES</h4>
          <div style={{ width: '100%' }}>
            {filteredCourses.length === 0
              ? 'Pin a course to add it to your calendar'
              : filteredCourses.map((course) => {
                  if (!course?.course?.course_codes?.[0]) {
                    return null
                  }

                  return (
                    <Pinned key={course.course.course_codes[0]}>
                      <PinIconContainer
                        onClick={() =>
                          this.unpinCourse(pinnedCourses.indexOf(course))
                        }
                      >
                        <PinnedIcon>
                          <FontAwesomeIcon icon={faThumbtack} color="#aaa" />
                        </PinnedIcon>
                        <Unpin>
                          <FontAwesomeIcon icon={faTimes} color="#007bff" />
                        </Unpin>
                      </PinIconContainer>
                      {(() => {
                        // construct url component, including course code with spaces removed
                        let urlComponent =
                          '/course' +
                          '/' +
                          course.course.course_codes[0].replace(/\s/g, '') +
                          '/' +
                          course.term.stanford_term_id
                        let alternateCourseCodes = []
                        course.course.course_codes.map((course_code, index) => {
                          if (index > 0) {
                            alternateCourseCodes.push(course_code)
                          }
                          return null
                        })
                        let courseTitle = course.course.title
                        if (alternateCourseCodes.length > 0) {
                          courseTitle += ` (${alternateCourseCodes.join(', ')})`
                        }
                        return (
                          <Link
                            to={urlComponent}
                            style={{ marginLeft: '24px' }}
                          >
                            {course.course.course_codes[0] + ': ' + courseTitle}
                          </Link>
                        )
                      })()}
                      {course.invalid_quarter_warning ? (
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip>
                              <TooltipText>
                                This course might not be offered in this
                                quarter.
                              </TooltipText>
                            </Tooltip>
                          }
                        >
                          <Note>
                            <FontAwesomeIcon icon={faExclamationTriangle} />
                          </Note>
                        </OverlayTrigger>
                      ) : (
                        <></>
                      )}
                    </Pinned>
                  )
                })}
          </div>
        </Row>
      </>
    )
  }

  renderEnrolled() {
    const { pinnedCourses, term } = this.props
    let filteredCourses = pinnedCourses.filter(
      (pinnedCourse) =>
        pinnedCourse.term.id === term.id && pinnedCourse.type === 'enroll'
    )
    if (filteredCourses.length === 0) {
      return null
    }
    return (
      <>
        <hr></hr>
        <Row>
          <h4>ENROLLED CLASSES</h4>
          <div style={{ width: '100%' }}>
            {filteredCourses.map((course) => (
              <Enrolled key={course.course.course_codes[0]}>
                {(() => {
                  // construct url component, including course code with spaces removed
                  let urlComponent =
                    '/course' +
                    '/' +
                    course.course.course_codes[0].replace(/\s/g, '') +
                    '/' +
                    course.term.stanford_term_id
                  return (
                    <Link to={urlComponent}>
                      {course.course.course_codes[0] +
                        ': ' +
                        course.course.title}
                    </Link>
                  )
                })()}
              </Enrolled>
            ))}
          </div>
        </Row>
      </>
    )
  }

  // take in type of stat i.e. units or hours and
  // compute total stat values for currently pinned courses
  renderStats(statType) {
    const { pinnedCourses, term } = this.props
    let filteredCourses = pinnedCourses.filter(
      (pinnedCourse) => pinnedCourse.term.id === term.id
    )
    let totalStats = 0
    filteredCourses.map((course) => {
      let property = 0
      if (statType === 'units') {
        property = course.course.max_units
      } else {
        property = course.course.average_hours_spent
      }
      if (property !== null) {
        totalStats += parseFloat(property)
      }
      return null
    })
    totalStats = Math.round(totalStats)
    return <span>{totalStats + ' ' + statType}</span>
  }

  render() {
    const renderTooltip = (props) => {
      const { pid, ...rest } = props
      return (
        <Tooltip id="button-tooltip" {...rest}>
          Estimate of total weekly time commitment for this quarter (excluding
          class time) for your pinned courses using student evaluation data from
          the last two years.
        </Tooltip>
      )
    }

    const { windowWidth, showSidePanel, showMe, term } = this.props
    if (!showMe) return null

    return (
      <SideWrapper threshold={windowWidth} showSidePanel={showSidePanel}>
        <Container>
          {windowWidth < THRESHOLD && (
            <Row>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-around',
                }}
              >
                <Link to={'/planner'}>
                  <Button
                    // size="sm"
                    variant="danger"
                    onClick={this.props.removeSidePanel}
                    style={{ margin: '8px', marginRight: '25px' }}
                  >
                    Planner
                  </Button>
                </Link>
                <Button
                  style={{ margin: '8px', marginLeft: '25px' }}
                  // size="sm"
                  variant="danger"
                  href={ADVISING_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Advising
                </Button>
              </div>
            </Row>
          )}
          <Row>
            <h3>
              {term.previous_term ? (
                <Link
                  style={{ color: 'black' }}
                  to={
                    (this.props.match && this.props.match.params.courseCode
                      ? `/course/${this.props.match.params.courseCode}/`
                      : '/landing/') + term.previous_term
                  }
                >
                  <Arrow>
                    <FontAwesomeIcon icon={faArrowLeft} size="xs" />
                  </Arrow>
                </Link>
              ) : (
                <Arrow style={{ color: 'grey', cursor: 'default' }}>
                  <FontAwesomeIcon icon={faArrowLeft} size="xs" />
                </Arrow>
              )}
              {term.season &&
                term.season.toUpperCase() +
                  ' ' +
                  term.start_year.toString().substr(2, 2) +
                  '-' +
                  term.end_year.toString().substr(2, 2)}
              {term.next_term ? (
                <Link
                  style={{ color: 'black' }}
                  to={
                    (this.props.match && this.props.match.params.courseCode
                      ? `/course/${this.props.match.params.courseCode}/`
                      : '/landing/') + +term.next_term
                  }
                >
                  <Arrow>
                    <FontAwesomeIcon icon={faArrowRight} size="xs" />
                  </Arrow>
                </Link>
              ) : (
                <Arrow style={{ color: 'grey', cursor: 'default' }}>
                  <FontAwesomeIcon icon={faArrowRight} size="xs" />
                </Arrow>
              )}
            </h3>
          </Row>

          {this.renderEnrolled()}

          {this.renderPinned()}

          <hr></hr>
          <Row>
            <h4>QUARTER OVERVIEW</h4>
            <ClassCalendar />
            {this.renderClasses()}
          </Row>

          <Row>
            <p>
              <b>Pinned Units:</b> {this.renderStats('units')}
            </p>
            <p>
              <b>Weekly Time Commitment:</b> {this.renderStats('hours')}{' '}
              <OverlayTrigger
                placement="auto"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <span>
                  <FontAwesomeIcon icon={faQuestionCircle} color="#aaa" />
                </span>
              </OverlayTrigger>
            </p>
          </Row>

          <hr></hr>
        </Container>
      </SideWrapper>
    )
  }
}

SidePanel.propTypes = {
  windowWidth: PropTypes.number,
}

function mapStateToProps(state) {
  return {
    pinnedCourses: state.pinnedCourses.pinnedCourses,
    showSidePanel: state.pinnedCourses.showSidePanel,
    loading: state.pinnedCourses.loading,
    term: state.term.term,
  }
}

const mapDispatchToProps = {
  unpinCourse,
  updatePin,
  fetchTerm,
}

export default connect(mapStateToProps, mapDispatchToProps)(SidePanel)
