import api from '../../api'

export const SEARCH_BEGIN = 'SEARCH_BEGIN'
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS'
export const SEARCH_FAILURE = 'SEARCH_FAILURE'

export const SEARCH_INDEX_BEGIN = 'SEARCH_INDEX_BEGIN'
export const SEARCH_INDEX_SUCCESS = 'SEARCH_INDEX_SUCCESS'
export const SEARCH_INDEX_FAILURE = 'SEARCH_INDEX_FAILURE'

export const searchBegin = data => ({
  type: SEARCH_BEGIN,
  payload: { data },
})

export const searchSuccess = data => ({
  type: SEARCH_SUCCESS,
  payload: { data },
})

export const searchFailure = error => ({
  type: SEARCH_FAILURE,
  payload: { error },
})

export const searchIndexBegin = () => ({
  type: SEARCH_INDEX_BEGIN,
})

export const searchIndexSuccess = data => ({
  type: SEARCH_INDEX_SUCCESS,
  payload: { data },
})

export const searchIndexFailure = error => ({
  type: SEARCH_INDEX_FAILURE,
  payload: { error },
})

export function search(searchQuery) {
  return dispatch => {
    dispatch(searchBegin(searchQuery))
    console.trace()

    api.search
      .search(searchQuery)
      .then(results => {
        dispatch(searchSuccess(results))
      })
      .catch(error => {
        dispatch(searchFailure(error))
      })
  }
}

export function fetchSearchIndex() {
  return dispatch => {
    dispatch(searchIndexBegin())
    api.search
      .index()
      .then(results => {
        dispatch(searchIndexSuccess(results))
      })
      .catch(error => {
        dispatch(searchIndexFailure(error))
      })
  }
}
