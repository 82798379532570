import React, { Component } from 'react'
import Popup from 'reactjs-popup'
import { Link } from 'react-router-dom'
import api from '../api'

const Card = ({
  title,
  description,
  minUnits,
  maxUnits,
  offerings,
  grading,
  genReq,
  instructors,
}) => (
  <div className="card">
    <div className="header boldMe">{title} </div>
    <div className="content">{description}</div>
    <br />
    <div>
      <span className="boldMe">Quarters Offered:</span> <span>{offerings}</span>
    </div>
    <div>
      <span className="boldMe">Units: </span>
      <span>
        {minUnits} - {maxUnits}
      </span>
    </div>
    <div>
      <span className="boldMe">Grading Basis: </span>
      <span>{grading}</span>
    </div>
    <div>
      <span className="boldMe">UG REQS: </span>
      <span>{genReq}</span>
    </div>
    <div>
      <span className="boldMe">Instructors: </span>
      <span>{instructors}</span>
    </div>
  </div>
)

class CoursePopup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openSchedule: false,
      popupDescription: '',
      popupMinUnits: 0,
      popupMaxUnits: 0,
      popupQuarters: '',
      popupGrading: '',
      popupGreq: '',
      popupInstructors: [],
      popupTitle: '',
    }
  }

  getInstructors() {
    const instructors = this.state.popupInstructors.map((instructor, index) => (
      //to={'/instructor/' + instructor}, here 'instructor' should be replaced by whatever id is used for that instructor's page
      <span key={index}>
        <Link to={'/instructor/' + instructor}>{instructor}</Link>,{' '}
      </span>
    ))
    return <div>{instructors}</div>
  }

  fetchPopupCourseData(courseCode) {
    api.course.getByCode(courseCode).then(course => {
      var instructors = []
      var ratings = course.ratings
      for (var i = 0; i < ratings.length; i++) {
        instructors.push(ratings[i].instructor_name)
      }
      this.setState({
        popupDescription: course.description,
        popupMaxUnits: course.max_units,
        popupMinUnits: course.min_units,
        popupQuarters: course.seasons_offered.join(', '),
        popupGrading: course.grading,
        popupGreq: course.general_requirements,
        popupInstructors: instructors,
        popupTitle: course.title,
      })
    }, false)
  }

  render() {
    const { className, courseCode } = this.props
    return (
      <span>
        <Popup
          trigger={
            <Link
              to={'/pioneer/course/' + courseCode}
              onMouseOver={() => {
                this.fetchPopupCourseData(courseCode)
              }}
            >
              {className}
            </Link>
          }
          position="top center"
          on="hover"
        >
          <Card
            title={this.state.popupTitle}
            description={this.state.popupDescription}
            minUnits={this.state.popupMinUnits}
            maxUnits={this.state.popupMaxUnits}
            offerings={this.state.popupQuarters}
            grading={this.state.grading}
            genReq={this.state.popupGreq}
            instructors={this.getInstructors()}
          />
        </Popup>
        {', '}
      </span>
    )
  }
}

export default CoursePopup
