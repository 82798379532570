import { combineReducers } from 'redux'
import pinningReducer from './pinningReducer'
import termReducer from './termReducer'
import sunetidReducer from './sunetidReducer'
import courseReducer from './courseReducer'
import graphReducer from './graphReducer'
import searchReducer from './searchReducer'
import commitmentReducer from './commitmentReducer'
import waitlistReducer from './waitlistReducer'

export default combineReducers({
  pinnedCourses: pinningReducer,
  term: termReducer,
  sunetId: sunetidReducer,
  course: courseReducer,
  graphs: graphReducer,
  search: searchReducer,
  timeCommitment: commitmentReducer,
  waitlist: waitlistReducer,
})
