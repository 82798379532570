import React, { Component } from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'
import SeasonalCoursesCard from './SeasonalCoursesCard'

const CoursesWrapper = styled.div`
  && .container {
    padding: 0;
    margin: 0;
  }

  & .col-lg-12,
  .col-lg-6,
  .col-lg-3 {
    padding-left: 10;
  }

  & .card {
    font-size: 12px;
  }
  .card > .header {
    background: #f2f4f7;
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 14px;
    text-align: center;
  }
`

function filterCoursesBySeason(courses, season) {
  return courses.filter(course =>
    course.terms.some(term => term.season === season)
  )
}

class CoursesCard extends Component {
  render() {
    let courses = this.props.coursesInfo
    let seasonalCourses = {
      autumn: courses ? filterCoursesBySeason(courses, 'autumn') : [],
      winter: courses ? filterCoursesBySeason(courses, 'winter') : [],
      spring: courses ? filterCoursesBySeason(courses, 'spring') : [],
      summer: courses ? filterCoursesBySeason(courses, 'summer') : [],
    }
    return (
      <CoursesWrapper>
        <Container>
          <Row>
            <Col lg={3}>
              <SeasonalCoursesCard
                season="Autumn"
                courses={seasonalCourses.autumn}
              />
            </Col>
            <Col lg={3}>
              <SeasonalCoursesCard
                season="Winter"
                courses={seasonalCourses.winter}
              />
            </Col>
            <Col lg={3}>
              <SeasonalCoursesCard
                season="Spring"
                courses={seasonalCourses.spring}
              />
            </Col>
            <Col lg={3}>
              <SeasonalCoursesCard
                season="Summer"
                courses={seasonalCourses.summer}
              />
            </Col>
          </Row>
        </Container>
      </CoursesWrapper>
    )
  }
}

CoursesCard.propTypes = {}

export default CoursesCard
