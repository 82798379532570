import React from 'react'
import styled from 'styled-components'

const CollaborationWrapper = styled.div`
  padding: 134.5px 20px;
  text-align: center;
  color: #fff;
  background-color: #212121;
`

export default function Collaborate() {
  return (
    <CollaborationWrapper>
      <p>Work with us</p>
      <h2 style={{ fontWeight: 'bold' }}>We've got big plans on the way.</h2>
      <p style={{ maxWidth: '564px', margin: 'auto' }}>
        Interested in changing the way students craft their educations? Curious
        what we’re up to as a student-run team? Shoot us an email, chat with our
        designers, or apply to join as a student designer or engineer!
      </p>
    </CollaborationWrapper>
  )
}
