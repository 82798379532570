// NavBar.js
export const NAVBAR_BKGRND_COLOR = '#B1040E'
export const EVAL_COLOR = '#B1040E'
export const SIDE_COLOR = '#F5F5F5'
export const SEQ_COLOR = '#B9CCB1'
export const DROPDOWN_HIGHLIGHT_COLOR = '#428bca'
export const INTENSITY_COLOR = '#B1040E'
export const PERFORMANCE_COLOR = '#ddd471'
export const REASON_COLOR = '#cc417b'
export const REVIEW_DATE_COLOR = '#9b9b9bb3'
export const QUESTION = '#aaa'
export const ENROLLED = '#969696'
export const PINNED_COURSE = '#B1040E'
export const PINNED_WARNING = '#EAAB00'
export const SEARCHED_COURSE = '#175E54'
export const ENROLLED_COURSE = '#F4F4F4'
export const CALENDAR_COLORS = [
  { light: '#ff3c3c', dark: '#a20000' },
  { light: '#3182bd', dark: '#215880' },
  { light: '#f5834b', dark: '#b6430a' },
  { light: '#a49dcb', dark: '#514888' },
  { light: '#3bc466', dark: '#1f6836' },
  { light: '#5da3d5', dark: '#215880' },
]
