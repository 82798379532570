import React, { Component } from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const SeasonalCoursesWrapper = styled.div`
  .courses-list {
    box-shadow: rgba(205, 205, 205, 0.5) 1px 2px 3px 0px;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(230, 230, 230);
    border-image: initial;
    margin: 0px 1%;
    padding: 10px 20px;
  }

  .season {
    font-weight: 500;
    font-family: Roboto, sans-serif;
    color: rgb(52, 52, 52);
    letter-spacing: 0.02px;
    padding-bottom: 10px;
    font-size: 16px;
  }

  .course-container {
    line-height: 22px;
    padding: 10px 0px;
    border-top: 1px solid rgb(217, 217, 217);
  }

  & .course-title {
    font-weight: 500;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    color: rgb(66, 139, 202);
    letter-spacing: 0px;
  }
`

function courseLink(course) {
  if (!course || !course.codes || course.codes.length === 0) return ''
  let link = course.codes[0]
  return `/course/${link}`
}

function courseTitle(course) {
  if (!course) return ''
  if (!course.codes || course.codes.length === 0) return course.title
  if (course.codes.length === 1) {
    return `${course.codes[0]}: ${course.title}`
  }
  return `${course.codes[0]}: ${course.title} (${course.codes
    .slice(1)
    .join(', ')})`
}

class SeasonalCoursesCard extends Component {
  renderCourses(courses) {
    return courses.map(course => {
      return (
        <div className={'course-container'}>
          <Row>
            <Col lg={12}>
              <Link className={'course-title'} to={courseLink(course)}>
                {courseTitle(course)}
              </Link>
            </Col>
          </Row>
        </div>
      )
    })
  }

  render() {
    let courses = this.props.courses
    return (
      <SeasonalCoursesWrapper>
        <div className={'courses-list'}>
          <Container>
            <Row>
              <Col lg={12}>
                <div className={'season'}>{this.props.season}</div>
              </Col>
            </Row>
            {courses ? this.renderCourses(courses) : null}
          </Container>
        </div>
      </SeasonalCoursesWrapper>
    )
  }
}

SeasonalCoursesCard.propTypes = {}

export default SeasonalCoursesCard
