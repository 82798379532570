import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'react-bootstrap'
import BarGraph from '../../Tools/BarGraph'
import { EVAL_COLOR } from '../../assets/constant_colors'
import { EVAL_HEIGHT } from '../../assets/constant_sizes'
import { connect } from 'react-redux'
import {
  DEFAULT_INITAL_NUM_RATINGS_SHOWN,
  DEFAULT_NUM_RATINGS_INCREMENT,
} from '../../assets/constant_sizes.js'

const EvalWrapper = styled.div`
  && .container {
    padding: 0;
    margin: 0;
  }
  & #pseudo_link {
    color: #428bca;
  }
  & #pseudo_link:hover {
    color: #3d65a5;
    text-decoration-line: underline;
    cursor: pointer;
  }
`

const GraphWrapper = styled.div`
  height: ${EVAL_HEIGHT};
  padding-bottom: 10px;
`

class EvalCard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      numRatingsToShow: DEFAULT_INITAL_NUM_RATINGS_SHOWN,
    }

    this.showMore = this.showMore.bind(this)
  }

  showMore() {
    const { numRatingsToShow } = this.state
    const { course } = this.props
    let currRatings = course.course.ratings
    let numRatingsLeft = currRatings.length - numRatingsToShow
    let increment =
      numRatingsLeft >= DEFAULT_NUM_RATINGS_INCREMENT
        ? DEFAULT_NUM_RATINGS_INCREMENT
        : numRatingsLeft
    let incremented = numRatingsToShow + increment
    if (increment > 0) this.setState({ numRatingsToShow: incremented })
  }

  generateEvals() {
    const { course } = this.props
    const { numRatingsToShow } = this.state
    if (!course.course || !course.course.ratings) return null
    let today = new Date()
    let threeYearsAgo = today.getFullYear() - 3 // TODO: change this back to 2!!
    let seasons = ['autumn', 'winter', 'spring', 'summer']
    let currentQuarter = this.dateToQuarter(today)

    // if the rating is more than three years old, filter it out
    let recentThreeYears = course.course.ratings.filter((rating) => {
      if (rating.term.end_year < threeYearsAgo) {
        return false
      } else if (rating.term.end_year > threeYearsAgo) {
        return true
      } else {
        if (
          seasons.indexOf(rating.term.season.toLowerCase()) <
          seasons.indexOf(currentQuarter)
        ) {
          return false
        } else {
          return true
        }
      }
    })
    if (recentThreeYears.length === 0) return null
    let ratings = recentThreeYears
      .sort((first, second) => {
        // Sorting in reverse order, so all the return values are backwards.
        if (first.term.end_year < second.term.end_year) {
          return 1 // first < second
        } else if (first.term.end_year > second.term.end_year) {
          return -1 // second < first
        } else {
          let i1 = seasons.indexOf(first.term.season.toLowerCase())
          let i2 = seasons.indexOf(second.term.season.toLowerCase())
          if (i1 < i2) {
            return 1 // first < second
          } else if (i2 < i1) {
            return -1 // second < first
          } else {
            return 0 // give up
          }
        }
      })
      .slice(0, numRatingsToShow)

    return (
      <Container>
        {ratings.map((item, index) => (
          <Row key={index}>
            <b>
              {item.term.start_year}/{item.term.end_year},{' '}
              {item.term.season.charAt(0).toUpperCase() +
                item.term.season.slice(1)}
              :&nbsp;
            </b>
            <p>
              {item.instructor_name} ({item.num_ratings} students)
            </p>
            <Col lg={12}>
              <GraphWrapper>
                <BarGraph
                  data={[{ Eval: item.average_rating }]}
                  barColor={EVAL_COLOR}
                  layout="vertical"
                  xType="number"
                  yType="category"
                  xDataKey={'Eval'}
                  yDataKey={'name'}
                  barKey={'Eval'}
                  domainX={[1, 5]}
                  ticksX={[1, 2, 3, 4, 5]}
                  hLine={false}
                  widthY={0}
                />
              </GraphWrapper>
            </Col>
          </Row>
        ))}
        {recentThreeYears.length > numRatingsToShow && (
          <span id="pseudo_link" onClick={this.showMore}>
            Show More
          </span>
        )}
      </Container>
    )
  }

  /**
   * Similar to function get_season_and_end_year from services.py in the backend.
   * Make sure to change these dates too!
   * */
  dateToQuarter(today) {
    // These dates depend on the Stanford academic calendar.
    let winter_cutoff = new Date(today.getFullYear(), 11, 13) // Monday after Autumn 21-22 ends
    let autumn_cutoff = new Date(today.getFullYear(), 7, 30) // Monday after Summer 20-21 ends
    let summer_cutoff = new Date(today.getFullYear(), 5, 7) // Monday after Spring 20-21 ends
    let spring_cutoff = new Date(today.getFullYear(), 2, 22) // Monday after Winter 20-21 ends

    let season = ''

    if (today >= winter_cutoff) {
      season = 'winter'
    } else if (today >= autumn_cutoff) {
      season = 'autumn'
    } else if (today >= summer_cutoff) {
      season = 'summer'
    } else if (today >= spring_cutoff) {
      season = 'spring'
    } else {
      season = 'winter'
    }
    return season
  }

  render() {
    return (
      <EvalWrapper>
        <Container>{this.generateEvals()}</Container>
      </EvalWrapper>
    )
  }
}

EvalCard.propTypes = {
  data: PropTypes.array,
}

function mapStateToProps(state) {
  return {
    course: state.course.currentCourse,
  }
}

export default connect(mapStateToProps)(EvalCard)
