import React from 'react'
import ReactDOM from 'react-dom'
import App from './Components/App/App'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/browser'
import './index.css'
import 'bootstrap/dist/css/bootstrap.css'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import rootReducer from './assets/reducers/rootReducer'
import 'rc-slider/assets/index.css'

if (process.env.SENTRY_DSN !== undefined) {
  Sentry.init({
    environment: process.env.ENVIRONMENT,
    dsn: process.env.SENTRY_DSN,
  })
}

if (process.env.ENVIRONMENT !== 'development') {
  console.log('%cApply to join Carta!', 'color: #B1040E; font-size: 36pt')
  console.log(
    '%chttps://carta.stanford.edu/apply',
    'color: #0098db; font-size: 24pt'
  )
  console.log(
    '%cYou can get four units of independent study credit every quarter.  Senior project and writing in the major opportunities are also possible.  Alternatives are available for those taking leaves of absence.',
    'color: #544948; font-size: 12pt'
  )
  console.log(
    '%c(Also if you spot any bugs or have ideas for new features, please let us know—this is beta software, and we can only improve it with your help.)',
    'color: #544948; font-size: 11pt'
  )

  console.log(
    'Welcome to\n' +
      '  ____    _    ____ _____  _     __     ______\n' +
      ' / ___|  / \\  |  _ \\_   _|/ \\    \\ \\   / /___ \\\n' +
      '| |     / _ \\ | |_) || | / _ \\    \\ \\ / /  __) |\n' +
      '| |___ / ___ \\|  _ < | |/ ___ \\    \\ V /  / __/\n' +
      ' \\____/_/   \\_\\_| \\_\\|_/_/   \\_\\    \\_/  |_____|\n' +
      '\n' +
      'See https://cartalab.stanford.edu for more info.'
  )
}

const store = createStore(rootReducer, applyMiddleware(thunk))

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
