import React from 'react'
import styled from 'styled-components'

const LandingToolsWrapper = styled.div`
  padding: 227px 7vw 228px 7vw;
`

const LandingToolsText = styled.div`
  max-width: 564px;
`

export default function LandingTools() {
  return (
    <LandingToolsWrapper>
      <LandingToolsText>
        <h2
          style={{
            marginBottom: '24px',
            fontWeight: 'bold',
            lineHeight: '120%',
          }}
        >
          Tools for maximizing knowledge and realizing potential
        </h2>
        <p>
          Students have a limited amount of time to take advantage of the
          classroom opportunities presented at Stanford. We build tools and
          provide information to help them feel content with their curricular
          decisions.
        </p>
      </LandingToolsText>
    </LandingToolsWrapper>
  )
}
