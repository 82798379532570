import React from 'react'
import styled from 'styled-components'
import { Draggable } from 'react-beautiful-dnd'
import { Link } from 'react-router-dom'
import {
  PINNED_COURSE,
  ENROLLED_COURSE,
  PINNED_WARNING,
  SEARCHED_COURSE,
} from '../../../assets/constant_colors'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationTriangle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'

const Container = styled.div`
  border: 1px solid lightgrey;
  border-radius: 2px;
  padding: 8px;
  margin-bottom: 8px;
  background: ${props => props.backgroundColor};
`

const Title = styled.span`
  font-family: Arial, Helvetica, sans-serif;
`

const Close = styled.div`
  position: relative;
  top: -12px;
  right: -12px;
  font-size: 10pt;
  float: right;
  background-color: darkslategray;
  border-radius: 15px;
  color: white;
  cursor: pointer;
  z-index: 1000;
  width: 20px;
  height: 20px;
  text-align: center;
  padding: 1px 5.6px;
`

const Units = styled.div`
  text-align: right;
  color: ${props => props.color};
`

const Note = styled.span`
  border-radius: 15px;
  color: black;
  cursor: help;
  z-index: 1000;
  font-weight: bold;
  float: right;
  text-align: center;
  vertical-align: middle;
  position: absolute;
  right: 25px;
`

const TooltipText = styled.p`
  text-align: left;
  margin-bottom: 5px;
  font-size: 12pt;
  line-height: 1.3;
`

export default class Course extends React.Component {
  constructor(props) {
    super(props)
    this.deleteItem = this.deleteItem.bind(this)
    this.addCloseIfApplicable = this.addCloseIfApplicable.bind(this)
  }

  deleteItem(courseId) {
    this.props.removeCourseFromColumn(courseId)
  }

  getBackgroundColor(label) {
    switch (label) {
      case 'pinned':
        return PINNED_COURSE
      case 'warning':
        return PINNED_WARNING
      case 'searched':
        return SEARCHED_COURSE
      case 'enrolled':
        return ENROLLED_COURSE
      default:
        throw Error('Error: bad label in planner')
    }
  }

  getForegroundColor(label) {
    switch (label) {
      case 'pinned':
        return 'white'
      case 'warning':
        return 'black'
      case 'searched':
        return 'white'
      case 'enrolled':
        return 'grey'
      default:
        throw Error('Error: bad label in planner')
    }
  }

  addWarningIfApplicable(label) {
    if (label !== 'warning') return null
    return (
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip>
            <TooltipText>
              This course might not be offered in this quarter.
            </TooltipText>
          </Tooltip>
        }
      >
        <Note>
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </Note>
      </OverlayTrigger>
    )
  }

  addCloseIfApplicable(course) {
    if (['pinned', 'warning'].includes(course.label)) {
      return (
        <Close onClick={() => this.deleteItem(course.id)}>
          <FontAwesomeIcon icon={faTimes} />
        </Close>
      )
    }
  }

  getIsDragDisabled(label) {
    if (['searched', 'pinned', 'warning'].includes(label)) {
      return false
    } else {
      return true
    }
  }

  render() {
    const backgroundColor = this.getBackgroundColor(this.props.course.label)
    const dragDisabled = this.getIsDragDisabled(this.props.course.label)
    return (
      <Draggable
        draggableId={this.props.course.id}
        index={this.props.index}
        isDragDisabled={dragDisabled}
      >
        {provided => (
          <Container
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            innerRef={provided.innerRef}
            backgroundColor={backgroundColor}
          >
            {this.addCloseIfApplicable(this.props.course)}
            <Link
              style={{
                color: this.getForegroundColor(this.props.course.label),
              }}
              to={`/course/${this.props.course.course_code}/`}
            >
              <Title>{this.props.course.title}</Title>
            </Link>
            {this.addWarningIfApplicable(this.props.course.label)}
            <Units color={this.getForegroundColor(this.props.course.label)}>
              {this.props.course.minUnits === this.props.course.maxUnits ? (
                <>
                  {this.props.course.maxUnits}{' '}
                  {this.props.course.maxUnits === 1 ? 'unit' : 'units'}{' '}
                </>
              ) : (
                <>
                  {' '}
                  {this.props.course.minUnits}&ndash;
                  {this.props.course.maxUnits} units{' '}
                </>
              )}
            </Units>
          </Container>
        )}
      </Draggable>
    )
  }
}
