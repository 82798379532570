import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Container, Row, Col, Tooltip, OverlayTrigger } from 'react-bootstrap'
import BarGraph from '../../Tools/BarGraph'
import { SEQ_COLOR } from '../../assets/constant_colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'

const MinimalSeqWrapper = styled.div`
  && .container {
    padding: 0;
    margin: 0;
  }

  & .col-lg-12 {
    padding: 0px;
  }
`

const GraphWrapper = styled.div`
  height: 400px;
  padding-bottom: 20px;
`

class MinimalSeqCard extends Component {
  renderYears() {
    const { introLine, years } = this.props

    const renderTooltip = (props) => {
      const { pid, ...rest } = props
      return (
        <Tooltip id="button-tooltip" {...rest}>
          Data may be from 2018-19 onward. Undergraduate years based on cohort.
          Coterm status based on billing group.
        </Tooltip>
      )
    }

    // see comments in backend re:accuracy/explanations
    const yearLabels = [
      ['frosh', 'Freshman'],
      ['soph', 'Sophomore'],
      ['junior', 'Junior'],
      ['senior', 'Senior'],
      ['ug 5yr', '5+ Year Undergrad'],
      ['coterm', 'Coterm'],
      ['professional', 'Professional Degree'],
      ['masters allYr', "Master's"],
      ['phd or doctoral', 'PhD/Doctoral'],
      ['nonmatriculated', 'SCPD/Nonmatriculated'],
      ['other', 'Other'],
    ]

    const data = []

    if (years && years.values) {
      const valueTotal = years.values.reduce((a, b) => a + b, 0)

      if (valueTotal === 0)
        return (
          <p>
            <b>{introLine}:</b> Not available for this course
          </p>
        )

      yearLabels.forEach(function (item, index) {
        var [innerName, displayName] = item
        var i = years.columns.indexOf(innerName)
        if (i === -1) return
        data.push({
          year: displayName,
          percent: Math.round((years.values[i] / valueTotal) * 100),
        })
      })
      return (
        <div>
          <GraphWrapper>
            <p>
              <b>
                {introLine} ({valueTotal} students)
              </b>{' '}
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <span>
                  <FontAwesomeIcon icon={faQuestionCircle} color="#aaa" />
                </span>
              </OverlayTrigger>
            </p>
            <BarGraph
              data={data}
              barColor={SEQ_COLOR}
              layout="vertical"
              xType="number"
              yType="category"
              xDataKey={'percent'}
              yDataKey={'year'}
              barKey={'percent'}
              hLine={false}
              widthY={160}
            />
          </GraphWrapper>
        </div>
      )
    }
    return (
      <p>
        <b>{introLine}:</b> Not available for this course
      </p>
    )
  }

  render() {
    return (
      <MinimalSeqWrapper>
        <Container>
          <Row>
            <Col lg={12}>{this.renderYears()}</Col>
          </Row>
        </Container>
      </MinimalSeqWrapper>
    )
  }
}

MinimalSeqCard.propTypes = {
  introLine: PropTypes.string,
  secondTitleLine: PropTypes.string,
}

function mapStateToProps(state) {
  return {
    years: state.graphs.currentGraphs.years,
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(MinimalSeqCard)
