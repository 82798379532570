import React, { Component } from 'react'
import ErrorMessage from '../ErrorPage/ErrorMessage/ErrorMessage'

class ErrorPage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.removeSidePanel && this.props.removeSidePanel()
  }

  render() {
    const { isLoggedIn } = this.props
    return <ErrorMessage isLoggedIn={isLoggedIn} />
  }
}

export default ErrorPage
