import React, { Component } from 'react'
import styled from 'styled-components'
import logo from '../../assets/carta_logo_dark.png'
import { SIDE_PANEL_WIDTH, THRESHOLD } from '../../assets/constant_sizes.js'
import { NAVBAR_BKGRND_COLOR } from '../../assets/constant_colors'
import { Container, Row, Col, Alert } from 'react-bootstrap'
import CatalogDropdown from '../NavBar/CatalogDropdown'

const HomeWrapper = styled.div`
  margin-left: ${(props) =>
    props.threshold > THRESHOLD ? SIDE_PANEL_WIDTH : '0px'};
  padding: 30px 0;
  width: auto;
  background-color: #f9f6ef;
  min-height: calc(100vh - 60px);
  border-left: 3px solid ${NAVBAR_BKGRND_COLOR};

  & .btn.btn-primary {
    height: 60px;
    width: 60px;
  }

  & .form-inline {
    margin-left: 15px;
    align-items: flex-start;
  }

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    padding: 60px 20px;
  }
`

const Logo = styled.img`
  padding: 15px;
  width: 300px;
`

const Subtitle = styled.div`
  font-size: 30px;
  font-weight: 400;
  padding: 15px 15px 0 15px;
`

const Links = styled.div`
  padding: 0 15px;
  font-size: 18px;
  font-weight: 350;
`

const PulseCircle = styled.div`
  visibility: ${(props) =>
    props.threshold > THRESHOLD ? 'visible' : 'hidden'};
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: 2px solid ${NAVBAR_BKGRND_COLOR};
  position: absolute;
  left: 327px;
  z-index: 1;

  animation-name: scale;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

  & :after {
    position: absolute;
    content: '';
    width: 32px;
    height: 32px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: ${NAVBAR_BKGRND_COLOR};
    border-radius: 50%;
  }

  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.93);
    }
    100% {
      transform: scale(1);
    }
  }
`

class HomePage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.addSidePanel()
  }

  render() {
    const { threshold } = this.props

    return (
      <HomeWrapper threshold={threshold}>
        <Container>
          <Row>
            <Col>
              <Logo
                src={logo}
                alt="Carta logo"
                style={{ filter: 'hue-rotate(490deg)' }}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Subtitle>
                <p>
                  Course exploration based on the choices of Stanford students
                </p>
              </Subtitle>
            </Col>
          </Row>

          <PulseCircle threshold={threshold} />

          <Row>
            <Col>
              <Links>
                <p>
                  Carta V2 is a student-run project. It is developed with the
                  help of the{' '}
                  <a href="https://pathwayslab.stanford.edu">
                    Stanford Pathways Lab
                  </a>
                  . <br />
                  <a href="apply">Apply to join our team</a>
                </p>
              </Links>
            </Col>
          </Row>
          <br />
        </Container>
      </HomeWrapper>
    )
  }
}

export default HomePage
