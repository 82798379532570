import React from 'react'
import styled from 'styled-components'
import Course from './course'
import { Droppable } from 'react-beautiful-dnd'

const Container = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  border-right: ${props => (!props.lastColumn ? '1px solid lightgrey' : null)};
  border-radius: 2px;
  height: 100%;
`

const CourseList = styled.div`
  padding 8px;
  min-height: 250px;
`

const Title = styled.h5`
  text-align: center;
`

const Subtitle = styled.div`
  text-align: center;
`

export default class Column extends React.Component {
  constructor(props) {
    super(props)
    this.removeCourseFromColumn = this.removeCourseFromColumn.bind(this)
  }

  removeCourseFromColumn(courseId) {
    this.props.removeCourseFromColumn(courseId, this.props.column.title)
  }

  render() {
    let title = this.props.column.title
    let year = title.substr(4)
    let season = title.substr(0, 3)
    if (season !== 'aut') year++
    season = {
      aut: 'Autumn',
      win: 'Winter',
      spr: 'Spring',
      sum: 'Summer',
    }[season]
    title = `${season} ${year}`
    let units = null
    if (this.props.courses) {
      let minUnits = this.props.courses
        .filter(course => course)
        .reduce((accumulator, current) => accumulator + current.minUnits, 0)
      let maxUnits = this.props.courses
        .filter(course => course)
        .reduce((accumulator, current) => accumulator + current.maxUnits, 0)
      if (minUnits === maxUnits) {
        if (maxUnits === 0) {
        } else if (maxUnits === 1) {
          units = `${maxUnits} unit`
        } else {
          units = `${maxUnits} units`
        }
      } else {
        units = `${minUnits}-${maxUnits} units`
      }
    }
    return (
      <Container
        searchResultsColumn={this.props.column.title !== 'search-results'}
        lastColumn={this.props.lastColumn}
      >
        {this.props.column.title !== 'search-results' ? (
          <>
            <Title>{title}</Title>
            {units && <Subtitle>{units}</Subtitle>}
          </>
        ) : null}
        <Droppable droppableId={this.props.column.title}>
          {provided => (
            <CourseList
              innerRef={provided.innerRef}
              {...provided.droppableProps}
            >
              {this.props.courses &&
                this.props.courses
                  .filter(course => course)
                  .map((course, index) => (
                    <Course
                      key={course.id}
                      removeCourseFromColumn={this.removeCourseFromColumn}
                      course={course}
                      index={index}
                    />
                  ))}
              {provided.placeholder}
            </CourseList>
          )}
        </Droppable>
      </Container>
    )
  }
}
