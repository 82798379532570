import React from 'react'
import ContentLoader from 'react-content-loader'

const SearchResultsLoader = (props) => (
  <ContentLoader
    speed={2}
    viewBox="0 0 476 124"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    {/*Title */}
    <rect x="0" y="8" rx="3" ry="3" width="300" height="12" />
    <rect x="0" y="24" rx="3" ry="3" width="150" height="12" />

    <rect x="400" y="8" rx="3" ry="3" width="75" height="12" />
    <rect x="0" y="56" rx="3" ry="3" width="410" height="12" />
    <rect x="0" y="72" rx="3" ry="3" width="410" height="12" />
    <rect x="0" y="88" rx="3" ry="3" width="410" height="12" />
    <rect x="0" y="104" rx="3" ry="3" width="350" height="12" />
  </ContentLoader>
)

export default SearchResultsLoader
