import {
  FETCH_GRAPHS_BEGIN,
  FETCH_GRAPHS_SUCCESS,
  FETCH_GRAPHS_FAILURE,
} from '../actions/graphActions'

const initialState = {
  currentGraphs: {},
  loading: false,
  error: null,
}

export default function courseReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_GRAPHS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case FETCH_GRAPHS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentGraphs: action.payload.data,
      }

    case FETCH_GRAPHS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        currentGraphs: {},
      }

    default:
      return state
  }
}
