import {
  FETCH_TIME_COMMITMENT_BEGIN,
  FETCH_TIME_COMMITMENT_SUCCESS,
  FETCH_TIME_COMMITMENT_FAILURE,
} from '../actions/commitmentActions'

const initialState = {
  timeCommitmentData: {},
  loading: false,
  error: null,
}

export default function commitmentReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TIME_COMMITMENT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case FETCH_TIME_COMMITMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        timeCommitmentData: action.payload.data,
      }

    case FETCH_TIME_COMMITMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        timeCommitmentData: {},
      }

    default:
      return state
  }
}
