import React, { Component } from 'react'
import Downshift from 'downshift'
import { MIN_NUM_SEARCH_LETTERS } from '../../../assets/constant_sizes.js'
import debounce from 'lodash.debounce'
import { DEBOUNCE_IN_MILIS } from '../../../assets/constant_sizes.js'
import Column from './column'
import { fetchCourse } from '../../../assets/actions/courseActions'
import { search } from '../../../assets/actions/searchActions'
import { localSearchLite } from '../../NavBar/SearchBar'
import { connect } from 'react-redux'

let monotonicallyIncreasingId = 1
function generateId() {
  monotonicallyIncreasingId++
  return `search-${monotonicallyIncreasingId}`
}

class PlannerSearchBar extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.fetchClasses = this.fetchClasses.bind(this)
    this.inputOnChange = this.inputOnChange.bind(this)
    this.fetchClasses = debounce(this.fetchClasses, DEBOUNCE_IN_MILIS)
    this.removeCourseFromColumn = this.props.removeCourseFromColumn.bind(this)
  }

  // onChange method for the input field
  inputOnChange(event) {
    if (event.target.value.length < MIN_NUM_SEARCH_LETTERS) {
      return
    }
    //right now we limit fetching to strings of length 3 so the matches will be less and it will be faster
    //TODO: see comment above, perhaps there is a better way to do this. The issue it's fixing is that when you
    //type in one letter "p" for example, 'courses' in state gets filled with too many courses. Fetching them takes a long time.
    //One idea might be to fetch all courses into saved state as soon as page renders and all inputOnChange does is search though that
    //and filters out matches ??? If we do this we should think about speed on load and security issues of saving course info to browser
    if (!event.target.value || event.target.value.length < 3) {
      return
    }
    this.fetchClasses(event.target.value)
  }

  //get all courses in columns other than search-results, these are the courses that
  //should be maintained when new search is made
  getAllCoursesArray(dragNDropData) {
    var allCourses = []
    const columns = dragNDropData.columns
    const keys = Object.keys(columns)
    for (var i = 0; i < keys.length; i++) {
      var column = columns[keys[i]]
      if (column['id'] !== 'search-results') {
        allCourses = allCourses.concat(column['courseIds'])
      }
    }
    return allCourses
  }

  fetchClasses(course) {
    const { index } = this.props
    let data = localSearchLite(index, course)
    var { dragNDropData } = this.props

    //newDNDCourses will initially contain even the courses in the search-results column which is wrong
    //because that will include previously-searched for courses (necessary to work this way because of how library works)
    //so we must go through and remove any course not in allCoursesArray (which does not include prev. searched-for courses)
    var newDNDCourses = dragNDropData.courses
    const allCoursesArray = this.getAllCoursesArray(dragNDropData)

    var newNewCourses = {}
    const keys = Object.keys(newDNDCourses)
    for (var i = 0; i < keys.length; i++) {
      if (allCoursesArray.includes(keys[i])) {
        newNewCourses[keys[i]] = newDNDCourses[keys[i]]
      }
    }
    //reset courses to filtered version without previously-searched courses
    newDNDCourses = newNewCourses

    //add courses returned from search API to the search-results column
    var newColumns = dragNDropData.columns
    var resultsCourseIds = []
    data.courses.forEach(course => {
      let id = generateId()
      resultsCourseIds.push(id)
      //TODO, deal with labels, have to change when dragged into planner
      newDNDCourses[id] = {
        id: id,
        title: `${course.code}: ${course.title}`,
        maxUnits: course.max_units,
        minUnits: course.min_units,
        label: 'searched',
        course_id: course.id,
      }
    })
    newColumns['search-results'].courseIds = resultsCourseIds

    const newDNDData = {
      courses: newDNDCourses,
      columns: newColumns,
      columnOrder: dragNDropData.columnOrder,
    }
    this.props.changeDNDData(newDNDData)
  }

  render() {
    const currColumn = this.props.dragNDropData.columns['search-results']
    const columnCourses = currColumn.courseIds.map(
      courseId => this.props.dragNDropData.courses[courseId]
    )
    return (
      <Downshift itemToString={item => (item ? item.title : '')}>
        {/* // pass the downshift props into a callback */}
        {({
          getInputProps,
          getItemProps,
          highlightedIndex,
          isOpen,
          inputValue,
        }) => (
          <div>
            {/* // add a label tag and pass our label text to the getLabelProps function */}
            {/* // add a input tag and pass our placeholder text to the getInputProps function. We also have an onChange eventlistener on the input field */}
            <h2
              style={{ margin: '10px 10%', width: '80%', textAlign: 'center' }}
            >
              Quick Search
            </h2>
            <input
              {...getInputProps({
                placeholder: 'Add classes...',
                onChange: this.inputOnChange,
                onKeyDown: this.enterPressed,
              })}
              style={{ margin: '0 10%', width: '80%' }}
            />
            {/* // if the input element is open, render the div else render nothing */}
            {isOpen ? (
              <div className="downshift-dropdown">
                <Column
                  courses={columnCourses}
                  column={currColumn}
                  removeCourseFromColumn={this.removeCourseFromColumn}
                />
              </div>
            ) : null}
          </div>
        )}
      </Downshift>
    )
  }
}

function mapStateToProps(state) {
  return {
    index: state.search.searchIndex,
    term: state.term.term,
  }
}

const mapDispatchToProps = {
  fetchCourse,
  search,
}

export default connect(mapStateToProps, mapDispatchToProps)(PlannerSearchBar)
