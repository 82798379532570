import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { useSWRInfinite } from 'swr'
import Cookies from 'universal-cookie'
import styled, { css } from 'styled-components'
import CourseResultCard from '../../Cards/CourseResultCard'
import InstructorResultCard from '../../Cards/InstructorResultCard'
import { Container, Row, Col, Dropdown } from 'react-bootstrap'
import {
  SIDE_PANEL_WIDTH,
  THRESHOLD,
  SEARCH_LIMIT,
} from '../../../assets/constant_sizes.js'
import api from '../../../api'
import { connect } from 'react-redux'
import { pinCourse } from '../../../assets/actions/pinningActions'
import SearchResultsLoader from './components/SearchResultsLoader'
import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap'
import Slider from 'rc-slider'

const createSliderWithTooltip = Slider.createSliderWithTooltip
const Range = createSliderWithTooltip(Slider.Range)

const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;

  & .dropdown {
    margin-right: 12px;
    margin-bottom: 8px;
  }
`

const RangeWrapper = styled.div`
  padding: 0 16px;
`

const ResultsWrapper = styled.div`
  padding-top: 30px;
  margin-left: ${(props) =>
    props.threshold > THRESHOLD ? SIDE_PANEL_WIDTH : '0px'};

  & .header {
    padding-left: 15px;
  }

  & #pseudo_link {
    color: #9b9b9b;
  }

  & #pseudo_link:hover {
    color: #3d65a5;
    text-decoration-line: underline;
    cursor: pointer;
  }

  & .results {
    color: #848484;
    font-size: 22px;
  }

  & .showing {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333333;
  }

  & .col-lg-12,
  .col-lg-6 {
    padding: 0px;
  }

  & .container {
    max-width: none;
  }
`

const LoadMoreButton = styled.button`
  width: 100%;
  border: 1px solid lightgray;
  padding: 5px 0;
  color: rgb(62, 138, 204);
  background-color: transparent;

  ${(props) =>
    !props.disabled &&
    css`
      & :hover {
        color: '#3d65a5';
        text-decoration: underline;
      }
    `}
`

const getHeaders = () => {
  const cookies = new Cookies()
  return {
    headers: {
      Authorization: `Token ${cookies.get('authToken')}`,
    },
  }
}

// Helper that creates pairs in the form [queryParamKey, value]
const createPairArray = (paramKey, obj) => {
  const res = []
  for (const [key, val] of Object.entries(obj)) {
    if (val) {
      res.push([
        paramKey,
        paramKey === 'way' ? key.toUpperCase() : key.toLowerCase(),
      ])
    }
  }
  return res
}

const SearchResultsPage = (props) => {
  const query = props.match.params.query
  const [daysBoxes, setDaysBoxes] = useState({
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
    Sunday: false,
  })
  const [quartersBoxes, setQuartersBoxes] = useState({
    Spring: false,
    Autumn: false,
    Winter: false,
    Summer: false,
  })
  const [waysBoxes, setWaysBoxes] = useState({
    'A-II': false,
    AQR: false,
    CE: false,
    EDP: false,
    ER: false,
    FR: false,
    SMA: false,
    SI: false,
  })
  const [minMaxUnits, setMinMaxUnits] = useState([1, 6])

  const createFilterParams = () => {
    const paramKeys = ['day', 'season', 'way', 'min_units', 'max_units']
    let params = []
    for (const paramKey of paramKeys) {
      switch (paramKey) {
        case 'day':
          params = [...params, ...createPairArray(paramKey, daysBoxes)]
          break
        case 'season':
          params = [...params, ...createPairArray(paramKey, quartersBoxes)]
          break
        case 'way':
          params = [...params, ...createPairArray(paramKey, waysBoxes)]
          break
        case 'min_units':
          params.push([paramKey, minMaxUnits[0]])
          break
        case 'max_units':
          params.push(
            minMaxUnits[1] === 6
              ? [paramKey, Number.MAX_SAFE_INTEGER]
              : [paramKey, minMaxUnits[1]]
          )
          break
        default:
          break
      }
    }
    return new URLSearchParams(params)
  }

  // Data fetching with SWR
  const fetcher = (url) =>
    axios
      .get(url, getHeaders())
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.log(error)
        if (error.response && error.response.status === 401) {
          api.authentication.redirect(window.location.pathname)
        } else if (error.response && error.response.status === 403) {
          // probably should handle this somehow?
        } else {
          throw error
        }
      })

  const { data, error, size, setSize } = useSWRInfinite(
    (index) =>
      `/api/search?query=${encodeURIComponent(
        query
      )}&limit=${SEARCH_LIMIT}&offset=${
        index * SEARCH_LIMIT
      }&${createFilterParams()}`,
    fetcher
  )

  const results = data ? [].concat(...data) : []
  const isLoadingInitialData = !data && !error
  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && data && typeof data[size - 1] === 'undefined')
  const isEmpty = data?.[0]?.courses?.length === 0
  const isReachingEnd =
    isEmpty || (data && data[data.length - 1]?.courses?.length < SEARCH_LIMIT)

  const renderLoadingSkeletons = () => {
    let skeletons = []
    for (let i = 0; i < 10; i++) {
      skeletons.push(
        <div className="mb-3" style={{ marginLeft: '15px' }}>
          <SearchResultsLoader />
          <hr style={{ marginLeft: '30px' }} />
        </div>
      )
    }
    return skeletons
  }

  useEffect(() => {
    props.addSidePanel()
  }, [])

  if (error) {
    return (
      <ResultsWrapper threshold={props.threshold}>
        <Container>Error loading results</Container>
      </ResultsWrapper>
    )
  }

  return (
    <ResultsWrapper threshold={props.threshold}>
      <Container>
        <Row>
          <Col lg={10}>
            <p className="results">Results for: {query}</p>
            <FiltersWrapper>
              <Dropdown>
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                  Units
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <RangeWrapper>
                    <Range
                      allowCross={false}
                      defaultValue={minMaxUnits}
                      max={6}
                      min={1}
                      tipFormatter={(value) => {
                        if (value < 6) return `${value}`
                        return `>5`
                      }}
                      tipProps={{ visible: true }}
                      onChange={(values) => setMinMaxUnits(values)}
                    />
                  </RangeWrapper>
                </Dropdown.Menu>
              </Dropdown>
              <DropdownMultiselect
                options={[
                  'Monday',
                  'Tuesday',
                  'Wednesday',
                  'Thursday',
                  'Friday',
                  'Saturday',
                  'Sunday',
                ]}
                name="days-filter"
                handleOnChange={(selected) => {
                  let copyDaysBoxes = {}
                  for (const key of Object.keys(daysBoxes)) {
                    if (selected.includes(key)) {
                      copyDaysBoxes[key] = true
                    } else {
                      copyDaysBoxes[key] = false
                    }
                  }
                  setDaysBoxes(copyDaysBoxes)
                }}
                placeholder="Days"
              />
              <DropdownMultiselect
                options={['A-II', 'AQR', 'CE', 'EDP', 'ER', 'FR', 'SMA', 'SI']}
                name="ways-filter"
                handleOnChange={(selected) => {
                  let copyWaysBoxes = {}
                  for (const key of Object.keys(waysBoxes)) {
                    if (selected.includes(key)) {
                      copyWaysBoxes[key] = true
                    } else {
                      copyWaysBoxes[key] = false
                    }
                  }
                  setWaysBoxes(copyWaysBoxes)
                }}
                placeholder="WAYS"
              />
              <DropdownMultiselect
                options={['Autumn', 'Winter', 'Spring', 'Summer']}
                // selected is an array of what is selected
                handleOnChange={(selected) => {
                  let copyQuartersBoxes = {}
                  for (const key of Object.keys(quartersBoxes)) {
                    if (selected.includes(key)) {
                      copyQuartersBoxes[key] = true
                    } else {
                      copyQuartersBoxes[key] = false
                    }
                  }
                  setQuartersBoxes(copyQuartersBoxes)
                }}
                name="quarter-filter"
                placeholder="Quarters"
              />
            </FiltersWrapper>
            {isLoadingMore && renderLoadingSkeletons()}
            {isEmpty ? <p>No results found</p> : null}

            {results.map((result) =>
              result?.courses.map((course, index) => (
                <CourseResultCard key={index} course={course} />
              ))
            )}
            {results.map((result) =>
              result?.instructors.map((instructor, index) => (
                <InstructorResultCard key={index} instructor={instructor} />
              ))
            )}
            <div>
              <LoadMoreButton
                disabled={isLoadingMore || isReachingEnd}
                onClick={() => setSize(size + 1)}
              >
                {isLoadingMore
                  ? 'Loading...'
                  : isReachingEnd
                  ? 'No more results'
                  : 'Load more'}
              </LoadMoreButton>
            </div>
          </Col>
        </Row>
      </Container>
    </ResultsWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    pinnedCourses: state.pinnedCourses.pinnedCourses,
    term: state.term.term,
  }
}

const mapDispatchToProps = {
  pinCourse,
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultsPage)
